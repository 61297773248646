// FirebaseProvider.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// Firebase Config (Replace with your credentials)
const firebaseConfig = {
  apiKey: "AIzaSyBsxWj-5Yp00WCS-hWaiQtUmmOl2PqS2sQ",
  authDomain: "superappmobile-2da9e.firebaseapp.com",
  projectId: "superappmobile-2da9e",
  storageBucket: "superappmobile-2da9e.appspot.com",
  messagingSenderId: "127990175567",
  appId: "1:127990175567:web:73c5df45d5e31ca293573a",
  measurementId: "G-VQRHCS0SC5",
};

// Create Context
const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }: any) => {
  const [app, setApp] = useState(null);
  const [analytics, setAnalytics] = useState(null);

  useEffect(() => {
    if (!app) {
      if (window.location.hostname == "shop.1superapp.com") {
        const firebaseApp: any = initializeApp(firebaseConfig);
        // console.log("Initializing Firebase...",firebaseApp);
        setApp(firebaseApp);

        try {
          const analyticsInstance: any = getAnalytics(firebaseApp);
          setAnalytics(analyticsInstance);
        } catch (error) {
          console.warn("Analytics not available:", error);
        }
      }
    }
  }, [app]);

  // Function to log events
  const logFirebaseEvent = (eventName: any, eventParams = {}) => {
    if (window.location.hostname == "shop.1superapp.com") {
      if (analytics) {
        logEvent(analytics, eventName, eventParams);
        //   console.log(`Logged event: ${eventName}`, eventParams);
      } else {
        console.warn("Analytics is not initialized yet.");
      }
    }
  };

  return (
    <FirebaseContext.Provider value={{ app, analytics, logFirebaseEvent }}>
      {children}
    </FirebaseContext.Provider>
  );
};

// Hook to use Firebase and logging
export const useFirebase = () => useContext(FirebaseContext);
