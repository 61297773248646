import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Link,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import DataContext from "../../../utils/Context/DataContext";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import {
  MaterialIcons,
  Ionicons,
  AntDesign,
  Entypo,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { Dialog, Drawer, Modal, SwipeableDrawer } from "@material-ui/core";
import ImageViewer from "react-simple-image-viewer";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { ProgressBar } from "react-bootstrap";
import ReviewModal from "../../Common/Review/ReviewModal";
import DeliveryType from "../../Common/DeliveryType/DeliveryType";
import Address from "../../Common/Address/Address";
import { makeStyles } from "@material-ui/core/styles";
// import Variants from "../Variants/Variants";
import { api } from "../../../utils/Api";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import Video from "yet-another-react-lightbox/plugins/video";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";

// import Captions from "yet-another-react-lightbox/dist/plugins/captions";
// import Inline from "yet-another-react-lightbox/dist/plugins/Inline";
// import "yet-another-react-lightbox/dist/plugins/captions/captions.css";
// import Video from "yet-another-react-lightbox/dist/plugins/video";
import { Height } from "@material-ui/icons";
import { AppConfig } from "../../../config";
import ImageContainer from "../../../components/ImageContainer/ImageContainer";
import { AppHelper } from "../../../utils/Helper";
import context from "react-bootstrap/esm/AccordionContext";
const Spinner = () => <div className="loader-spinner"></div>;

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  200: { items: 1 },
  350: { items: 2 },
  548: { items: 2 },
  720: { items: 3 },
  1024: { items: 4 },
  1100: { items: 6 },
  1200: { items: 6 },
};

const preview_responsive = {
  0: { items: 1 },
  200: { items: 1 },
  350: { items: 2 },
  548: { items: 3 },
  720: { items: 4 },
  1024: { items: 5 },
  1100: { items: 6 },
  1200: { items: 6 },
};

const video_responsive = {
  0: { items: 1 },
  200: { items: 1 },
  350: { items: 2 },
  548: { items: 3 },
  720: { items: 4 },
  1024: { items: 5 },
};

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 150) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? " ...read more" : "  show less"}
      </span>
    </p>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end", // push the dialog to bottom
    },
  },
  paper: {
    // make the content full width
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      overflow: "scroll",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
    },
    overflow: "scroll",
  },
}));

const ReviewReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text text-white">
      {isReadMore ? text.slice(0, 200) : text}
      <span
        onClick={toggleReadMore}
        className="read-or-hide text-orange fw-bold ps-1"
      >
        {isReadMore ? " ...read more" : "  show less"}
      </span>
    </p>
  );
};

const descrip = () => {
  return (
    <div className="descrip-text">
      <ReviewReadMore>
        GeeksforGeeks: A Computer Science portal for geeks. It contains well
        written, well thought and well explained computer science, programming
        articles and quizzes. It provides a variety of services for you to
        learn, so thrive and also have fun! Free Tutorials, Millions of
        Articles, Live, Online and Classroom Courses ,Frequent Coding
        Competitions, Webinars by Industry Experts, Internship opportunities,
        and Job Opportunities. Knowledge is power! GeeksforGeeks: A Computer
        Science portal for geeks. It contains well written, well thought and
        well explained computer science, programming articles and quizzes. It
        provides a variety of services for you to learn, so thrive and also have
        fun! Free Tutorials, Millions of Articles, Live, Online and Classroom
        Courses ,Frequent Coding Competitions, Webinars by Industry Experts,
        Internship opportunities, and Job Opportunities. Knowledge is power!
      </ReviewReadMore>
    </div>
  );
};

const reviw_title = () => {
  return (
    <div className="">
      <div className="d-flex align-items-center">
        <img
          src={require("../../../assets/img/prof.jpg")}
          className="review-user"
        />
        <div className="ps-2">
          <h6 className="text-white mb-1">User name</h6>
          <p>
            <small className="text-white v-small">Verified</small>
          </p>
        </div>
      </div>
      <div className="flex box mt-2">
        <div>
          <p className="rating_star mb-0">4.0★</p>
        </div>
        <div className="pl-1">
          <h6 className="text-white mb-0">Nature Images</h6>
        </div>
      </div>
    </div>
  );
};

export default function ProductDetails(props: any) {
  const context = useContext(DataContext);
  const classes = useStyles();
  const [outlet_context_data, SetOutletContextData]: any = useOutletContext();

  const [width, SetWidth] = React.useState(innerWidth);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [seller_state, setSellerState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [other_sellers, SetOtherSellers] = useState([
    {
      id: 1,
      price: "250",
      offer_detail: "10% Off for ICICI Credit cards",
      seller: "Seller 1",
      rating: 4,
      delivery_price: 50,
      delivery_status: "Mon, Dec 21",
    },
    {
      id: 2,
      price: "250",
      offer_detail: "10% Off for ICICI Credit cards",
      seller: "Seller 2",
      rating: 4,
      delivery_price: 50,
      delivery_status: "Mon, Dec 21",
    },
    {
      id: 3,
      price: "250",
      offer_detail: "10% Off for ICICI Credit cards",
      seller: "Seller 3",
      rating: 4,
      delivery_price: 50,
      delivery_status: "Mon, Dec 21",
    },
    {
      id: 4,
      price: "250",
      offer_detail: "10% Off for ICICI Credit cards",
      seller: "Seller 4",
      rating: 4,
      delivery_price: 50,
      delivery_status: "Mon, Dec 21",
    },
    {
      id: 5,
      price: "250",
      offer_detail: "10% Off for ICICI Credit cards",
      seller: "Seller 5",
      rating: 4,
      delivery_price: 50,
      delivery_status: "Mon, Dec 21",
    },
    {
      id: 6,
      price: "250",
      offer_detail: "10% Off for ICICI Credit cards",
      seller: "Seller 6",
      rating: 4,
      delivery_price: 50,
      delivery_status: "Mon, Dec 21",
    },
  ]);
  const [product_data, SetProductData] = useState([]);

  const [id_value, SetIdValue] = useState(useParams());

  const [color_active, SetColorActive] = useState(false);
  const [unit_active, SetUnitActive] = useState(false);
  const [rate_product, SetRateProduct] = useState(false);
  const [view_is_open, SetViewIsOpen] = useState(false);
  const [current_image, SetCurrentImage] = useState(0);
  const [select_delivery, SetSelectDelivery] = useState(false);
  const [snack_open, SetSnackOpen] = useState(false);
  const [map_view, SetMapView] = useState(false);
  const [new_address, SetNewAddress] = useState(false);
  const [variant_swipe, SetVariantSwipe] = useState(false);
  const [social_swipe, SetSocialSwipe] = useState(false);
  const [other_Swipe, SetOtherSwipe] = useState(false);
  const shareUrl = "https://1superapp.com/";
  const [copyText, SetCopyText] = useState("");
  const [viewer_open, SetViewerOpen] = useState(false);
  const [user_img, SetUserImg] = useState([
    {
      img_path: require("../../../assets/img/leatherShoe.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/ecommerce.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/mens_wear.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/handbag.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/fill.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/mens_wear.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/handbag.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/fill.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/ecom.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/designSandal.png"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/saree.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/leatherShoe.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/allWear.webp"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/leatherShoe.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/designSandal.png"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/saree.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },
    {
      img_path: require("../../../assets/img/leatherShoe.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },

    {
      img_path: require("../../../assets/img/leatherShoe.jpg"),
      title: "USER IMAGE",
      description: "Image description",
    },
  ]);
  const [user_video, SetUserVideo] = useState([
    {
      link: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
      title: "USER VIDEO",
      description: "Video description",
    },
    {
      link: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
      title: "USER VIDEO",
      description: "Video description",
    },
    {
      link: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
      title: "USER VIDEO",
      description: "Video description",
    },
    {
      link: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4",
      title: "USER VIDEO",
      description: "Video description",
    },
    {
      link: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4",
      title: "USER VIDEO",
      description: "Video description",
    },
    {
      link: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4",
      title: "USER VIDEO",
      description: "Video description",
    },
    {
      link: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
      title: "USER VIDEO",
      description: "Video description",
    },
    {
      link: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4",
      title: "USER VIDEO",
      description: "Video description",
    },
    {
      link: "http://clips.vorwaerts-gmbh.de/VfE_html5.mp4",
      title: "USER VIDEO",
      description: "Video description",
    },
  ]);
  const [finite, SetFinite] = React.useState(true);
  const [open_image, SetOpenImage] = useState(false);
  const [open_video, SetOpenVideo] = useState(false);
  const [disablePictureInPicture, setDisablePictureInPicture] =
    React.useState(true);
  const [disableRemotePlayback, setDisableRemotePlayback] =
    React.useState(true);
  const [controlsList, setControlsList] = React.useState([]);
  const [quick, SetQuick] = useState({
    open: false,
    data: "",
  });
  const [variant_data, SetVariantData] = useState({});
  const [variant_checked, SetVariantChecked] = useState({
    is_check: false,
    value: {},
    index_data: "",
  });
  const [checked_variant, SetCheckedVariant] = useState([]);
  const [selected_product, SetSelectedProduct] = useState({});
  const hasHtmlTags = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    
    
    SetOutletContextData((prevValue: any) => {
      prevValue.have_back = props.have_back;
      // prevValue.have_back_value = true;
      // prevValue.back_value =
      //   "/" + id_value.section_slug + "/" + id_value.seller_slug;
      prevValue.have_back_value = false;
      prevValue.have_footer = true;
      prevValue.header_type = "shop_category";
      // prevValue.link = {
      //   rel: 'canonical',
      //   href: 'http://mysite.com/example',
      // }
      return { ...prevValue };
    });
    context.SetAppData((prevValue) => {
      prevValue.backHaves = props.backHaves;
      return { ...prevValue };
    });

    get_product_details();
  }, []);

  async function get_product_details() {
    let pass_data: any = {
      get: {
        section_slug: id_value.section_slug,
        seller_slug: id_value.seller_slug,
        // product_slug: id_value.product_slug,
        // group_slug: id_value.product_slug,
      },
    };
    if (id_value.type == "p") {
      pass_data.get.product_slug = id_value.product_slug;
    } else {
      pass_data.get.group_slug = id_value.product_slug;
    }
    
    let response = await api("/vendor_products_v2", pass_data);
    
    
    if (response.status_code == 200) {
      SetProductData(response.response.products);
      if (response.response.products[0].type == "product") {
        SetSelectedProduct(response.response.products[0]);
      } else {
        ProcessSelectedProductData(response.response.products);
      }
    }
  }

  function ProcessSelectedProductData(p_data: any) {
    
    let checkedVariantIds = p_data[0].variant_specifications
      .map((spec) =>
        spec.items.filter((item) => item.checked).map((item) => item.id)
      )
      .flat();
    
    const matchedProduct = p_data[0].products.find((product) =>
      checkedVariantIds.every((variantId) =>
        product.variants.includes(String(variantId))
      )
    );
    
    SetSelectedProduct(matchedProduct);
  }

  //   async function get_init() {
  //     let pass_data = {
  //       get: {
  //         section_slug: id_value.sections_slug,
  //         seller_slug: id_value.seller_slug,
  //         product_slug: id_value.product_slug,
  //         type: context.app_data.products.variant_slug,
  //       },
  //     };
  //     let data_res = await api("/product_details", pass_data);
  //     
  //     SetDetails(data_res.response.products);
  //     SetItems((preValue) => {
  //       {
  //         data_res.response.products[0].images.map((img) => {
  //           preValue.push(
  //             <img
  //               src={"https://cdn.1superapp.com/images/320/" + img.url}
  //               onDragStart={handleDragStart}
  //             />
  //           );
  //         });
  //       }
  //       return [...preValue];
  //     });
  //   }

  function variantDrawer(anchor: any, open: any, event: any, value: any) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    SetVariantData(value);
    
  }

  function fix_decimal(data: any) {
    // let value = data;
    // return value.toFixed(1);
    
  }

  function copyToClipboard(e: any) {
    //Not showing any text link it's temporary copy function
    SetCopyText(e.target.baseURI);
    SetSocialSwipe(false);
    alert(`You have copied "${e.target.baseURI}"`);
    
  }

  function colorChecked(index: any, main_index: any) {
    // colorData[index].checked = true;
    SetDetails((pre) => {
      pre[0].variant_specifications[main_index].items.map((element, key) => {
        
        
        
        if (key == index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
      return [...pre];
    });

    SetColorActive(!color_active);
    
  }

  function unitChecked(index: any, main_index: any) {
    SetDetails((pre) => {
      pre[0].variant_specifications[main_index].items.map((element, key) => {
        
        
        
        if (key == index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
      return [...pre];
    });
    SetUnitActive(!unit_active);
    
  }

  function SaveContext(data: any) {
    
    context.app_data.reviews_data = data;
  }

  function likeclick(index: any) {
    let review_data = details;
    if (review_data.review[index].like == false) {
      review_data.review[index].like = true;
      review_data.review[index].likeCount =
        review_data.review[index].likeCount + 1;
      SetDetails(review_data);
    }

    if (review_data.review[index].dislike == true) {
      review_data.review[index].dislike = false;
      review_data.review[index].dislikeCount =
        review_data.review[index].dislikeCount - 1;
      SetDetails(review_data);
    }
    
  }

  function dislikeclick(index: any) {
    let review_data = details;
    if (review_data.review[index].dislike == false) {
      review_data.review[index].dislike = true;
      review_data.review[index].dislikeCount =
        review_data.review[index].dislikeCount + 1;
      SetDetails(review_data);
    }
    if (review_data.review[index].like == true) {
      review_data.review[index].like = false;
      review_data.review[index].likeCount =
        review_data.review[index].likeCount - 1;
      SetDetails(review_data);
    }
  }

  function openImageViewer(index: any) {
    
    SetCurrentImage(index);
    SetViewerOpen(true);
  }

  function closeImageViewer() {
    SetCurrentImage(0);
    SetViewerOpen(false);
  }

  function is_even(value: any) {
    if (value % 2 == 0 && 2 * value + (1 % 2) != 0) {
      return true;
    }
    return false;
  }

  function otherSellerDrawer(anchor: any, open: any, event: any) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSellerState({ ...seller_state, [anchor]: open });
  }

  function get_outlet_available() {
    let return_value = true;
    context?.app_data?.selected_vendor?.outlets?.map((ele: any) => {
      if (ele.slug == id_value.seller_slug) {
        if (ele.available) {
          return_value = true;
        } else {
          return_value = false;
        }
      }
    });

    return return_value;
  }

  function onVariantClick(productData, variantSpecifications) {
    // Step 1: Collect all checked variant IDs
    const checkedVariantIds = variantSpecifications
      .map((spec) =>
        spec.items.filter((item) => item.checked).map((item) => item.id)
      )
      .flat();

    
    SetCheckedVariant(checkedVariantIds);

    // Step 2: Filter the product based on the selected variant IDs
    const matchedProduct = productData.products.find((product) =>
      checkedVariantIds.every((variantId) =>
        product.variants.includes(variantId)
      )
    );

    // Step 3: Display the product name (or a fallback message if no match)
    if (matchedProduct) {
      
      return matchedProduct.name; // Return the product name if needed
    } else {
      
      return "No product matches the selected variants.";
    }
  }

  return (
    <div className="page-main custom-container mb-5 pb-5 px-sm-0 px-md-2" style={{overflowY: "scroll", minHeight: "100vh" }}>
      <div>
        <div className="">
          <div className="prod p-2 bg-fff ">
            <div className="w-100 end">
              <div
                className="cursor share t-60"
                onClick={() => {
                  SetSocialSwipe(true);
                }}
              >
                <Ionicons name="ios-share-social" size={20} color="#000" />
              </div>
            </div>

            <div className="sectorSlide productdots"
                style={selected_product?.images?.length == 0 ? {backgroundColor: "#ffeeee"}: {backgroundColor:""}}>
              <AliceCarousel
                mouseTracking
                touchMoveDefaultEvents={false}
                // items={items}
                infinite
                disableButtonsControls
                
              >
                {selected_product?.images?.length > 0
                  ? selected_product?.images?.map((url: any) => (
                      // <ImageContainer
                      //   src={AppHelper.imageSrc(
                      //     context.app_data.image_base_url.url,
                      //     {
                      //       format: "webp",
                      //       width: 320,
                      //     }
                      //   )}
                      //   thumb={AppHelper.imageSrc(
                      //     context.app_data.image_base_url.url,
                      //     {
                      //       format: "webp",
                      //       width: 320,
                      //       quality: 10,
                      //     }
                      //   )}
                      //   //  class_name={
                      //   //    g_props.data.available && get_outlet_available()
                      //   //      ? "w-100 prod-image "
                      //   //      : "w-100 prod-image-gray "
                      //   //  }
                      //   onIsVisible={() => {
                      //     
                      //   }}
                      //   onDragStart={handleDragStart}
                      // />
                      <img
                        src={AppConfig.CDN_Image_url + url}
                        onDragStart={handleDragStart}
                      />
                    ))
                  : product_data[0]?.images.map((url: any) => (
                      <img
                        src={AppConfig.CDN_Image_url + url}
                        onDragStart={handleDragStart}
                      />
                    ))}
              </AliceCarousel>
            </div>
          </div>

          <div className="px-3 py-2 mt-2 bg-fff ">
            <div className="">
              <div className="prod-name-mob mb-2 pb-0">
                {/* {variant_checked.is_check == false ? (
                  data.name
                ) : (
                  <>
                    {Array.isArray(data.products)
                      ? data.products.map((p_data: any) =>
                          p_data.variants[0] == variant_checked.value.id ||
                          checked_variant == p_data.variants
                            ? p_data.name
                            : null
                        )
                      : null}
                  </>
                )} */}
                {selected_product?.name}
              </div>
            </div>

            <div className="d-flex align-items-center pt-2 w-100">
              <div className="d-flex align-items-center w-75">
                <div
                  className="rating_star center  pop-regular"
                  onClick={() => {
                    
                  }}
                >
                  {/* {data.star_rating.toFixed(1)} */}
                  3★
                </div>
                <div className="pl-1 fs-11px pop-regular">
                  237 Ratings | 12 Reviews
                  {/* {data.rating_members} Ratings | {data.review_members} Reviews */}
                </div>
              </div>
              <div className="w-25 end">
                <div
                  className="cursor share "
                  onClick={() => {
                    SetSelectedProduct((prevState) => ({
                      ...prevState,
                      favourite: !prevState.favourite, // Toggle the favourite value
                    }));
                    
                  }}
                >
                  {selected_product?.favourite ? (
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "eff3dd986a87c9e8224c9547708adcd4.svg"
                      }
                      className="w-20"
                    />
                  ) : (
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "717d8ac4005e4eda5f72bd6b5485d4be.svg"
                      }
                      className="w-20"
                    />
                  )}
                </div>
              </div>
            </div>

            {/* <div className="d-flex align-items-center mt-1">
              <div className=" fs-12px text-black me-2">
                ₹{data.price_without_gst}
              </div>
              <div className="fs-10px text-gray">(Without of taxes)</div>
            </div> */}

            {selected_product.available == false ? (
              <div className="text-gray fs-12px pop-regular mt-3 p-2 border radius-6 text-center">
                Product not available
              </div>
            ) : (
              <div>
                <div className="d-flex align-items-center mt-2">
                  <div className="mb-0  fs-16px text-dark fw-bold">
                    <>₹{selected_product?.price_with_gst}</>
                  </div>
                  {selected_product?.mrp && (
                    <div className="px-2 pop-regular fs-14px text-gray">
                      MRP
                      <strike className="ps-1">₹{selected_product?.mrp}</strike>
                    </div>
                  )}
                  {/* <p className="green pl-1">{data.discount}5 % Off</p> */}
                </div>

                <small className="text-gray mt-0 pt-0 pop-regular">
                  Inclusive of all taxes
                </small>
              </div>
            )}
          </div>

          {Array.isArray(product_data[0]?.variant_specifications) ? (
            <div className="variants py-2 px-3 bg-fff ">
              {/* <div className="fs-13px text-dark my-2 pop-font fw-500">
                  Select Units
                </div> */}

              <h5 className="pb-2">Variants</h5>

              <div>
                {product_data[0]?.variant_specifications.map(
                  (v_data: any, v_index: any) => (
                    <>
                      {/* {v_data.items.map((i_data: any) => ( */}
                      <div className="text-black fs-13px pop-regular pt-2">
                        {v_data.name}
                      </div>

                      <div className="d-flex lign-items-center p-1 flex-wrap gap-1">
                        {v_data.items.map((i_data: any, i_index: any) => (
                          <div
                            key={i_index}
                            className={
                              v_data.name == "Colour"
                                ? v_data.items[i_index].checked
                                  ? "activeCss"
                                  : "color-box"
                                : i_data.checked == true
                                ? "activeunits"
                                : "unit-box"
                            }
                            style={{
                              backgroundColor:
                                v_data.name === "Colour" ? i_data.name : "",
                              pointerEvents:
                                i_data.available === false ? "none" : "auto",
                              border:
                                i_data.available === false &&
                                v_data.name != "Colour"
                                  ? "1px solid #ccc"
                                  : "1px solid #333",
                            }}
                            onClick={() => {
                              SetProductData((preValue) => {
                                // Create a new copy of the data
                                preValue[0].variant_specifications[
                                  v_index
                                ].items.map((i: any, ie_index: any) => {
                                  if (ie_index === i_index) {
                                    i.checked = true;
                                  } else {
                                    i.checked = false;
                                  }
                                });
                                ProcessSelectedProductData(preValue);
                                return [...preValue];
                              });
                            }}
                          >
                            {v_data.name != "Colour" && (
                              <div
                                className="pop-regular fs-13px"
                                style={{
                                  color:
                                    i_data.available === false
                                      ? "#aaa"
                                      : "#333",
                                }}
                              >
                                {i_data.name}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      {/* ))} */}
                      {/* <div className="d-flex">
                          <div className="fs-13px pe-2 text-dark">
                            ₹{p_data.price_without_gst}
                          </div>
                          <div className="pe-2 fs-12px text-gray">
                            MRP
                            <strike className="ps-1">₹{p_data.mrp}</strike>
                          </div>
                        </div> */}
                    </>
                  )
                )}
              </div>
            </div>
          ) : null}

          {/* <div className="variants mt-2 py-2 bg-fff ">
            <h5 className="px-2">Variants</h5>
            {data.variant_specifications.map((v_spec, v_index) => (
              <div className="row pt-3" key={v_index}>
                <div className="col-md-2 col-12 pb-2">
                  <p className="fs-7 text-black">{v_spec.name}</p>
                </div>
                <div className="col-md-10 col-12">
                  {v_spec.name == "Colour" ? (
                    <div className="row">
                      {v_spec.items.map((item, i) => (
                        <div
                          key={i}
                          className={
                            v_spec.items[i].checked
                              ? "activeCss"
                              : "color-box"
                          }
                          onClick={() => {
                            colorChecked(i, v_index);
                          }}
                          style={{ backgroundColor: item.code }}
                        ></div>
                      ))}
                    </div>
                  ) : (
                    <div className="row">
                      {v_spec.items.map((item, i) => (
                        <div
                          key={i}
                          className={
                            v_spec.items[i].checked
                              ? "activeunits"
                              : "unit-box"
                          }
                          onClick={() => {
                            unitChecked(i, v_index);
                          }}
                        >
                          {" "}
                          <p>{item.name}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))} 
          </div>*/}
        </div>

        {/* {data.variant ? (
                          <div className="mt-2 p-3 flex ml-auto cursor shadow align-center" onClick={() => {
                              SetVariantSwipe(true);
                          }}>
                              <MaterialCommunityIcons
                                  name="selection-multiple"
                                  size={20}
                                  color="#bbb"
                              />
                              <p className="pl-1 m-0">Select Multiple Variants</p>
                          </div>
                      ) : (
                          ""
                      )} */}

        {/* <div className="delivery mt-2 p-3  bg-fff ">
          <h3 className="text-brown">
            <b>In Stock</b>
          </h3>
          <div className="d-flex align-items-center p-0">
            <p className="prod-name-mob fw-bolder text-gray mb-0">Seller</p>
            <h6 className="px-1 delivery_time mb-0 ">
              ABC Sellers
            </h6>
          </div>
        </div>

        <div className="delivery mt-2 px-3 pt-1 pb-3 shadow bg-fff ">
          <div className="d-flex align-end">
            <div className="flex align-end">
              <img
                src={require("../../../assets/icon/fast-truck.png")}
                alt=""
              />
              <div className="flex">
                <p className="m-0 pl-1 pr-1">Delivery Near</p>
                <h6 className="mb-0">
                  <b>Erode</b>
                </h6>
              </div>
            </div>

            <div
              className=" ml-2 flex align-end ml-auto cursor"
              onClick={() => {
                SetSelectDelivery(true);
              }}
            >
              <MaterialIcons name="location-on" size={20} color="#007dae" />
              <p className="text-prime1 ">Change</p>
            </div>

            <Modal
              open={select_delivery}
              onClose={() => {
                SetSelectDelivery(false);
              }}
              className="center deliverytype"
              // onClick={() => {
              //   window.scrollTo({
              //     top: document.documentElement.scrollHeight,
              //     behavior: 'auto',
              //   });
              //   
              // }}
            >
              {/* {mapView ? (
                  <div className="row" onClick={selectDeliveryClose}>
                <Maps  />
                  </div>
                ) : ( /}
              {map_view ? (
                // <Maps
                //     className="w-100"
                //     Getdata={(location) => {
                //         
                //     }}
                //     mapvalue={(value) => {
                //         
                //         SetMapView(value);
                //         // addnewOpen();
                //     }}
                // />
                <p>map view</p>
              ) : (
                <div className="scrolling-card card">
                  <div className="row center bg-sm-primary p-3">
                    <div className="col-9">
                      <h5>Delivery Type</h5>
                    </div>
                    <div
                      className="col-3 text-right cursor p-0"
                      onClick={() => {
                        SetSelectDelivery(false);
                      }}
                    >
                      <Ionicons name="ios-close" size={20} color="white" />
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col-md-7 col-sm-12 col-lg-7 col-12 p-0">
                      <div className="bg-gray-300">
                        <DeliveryType />
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-12 col-lg-5 col-12 p-0">
                      <Address
                        onSelect={(data) => {
                          SetMapView(data);
                          
                        }}
                        open={new_address}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* </div> /}
              {/* )}  /}
            </Modal>
          </div>
          <hr />
          <div className="flex">
            <p className="delivery_para">
              Estimated Delivery by{" "}
              <span className="pr-1 text-prime1">30 Minutes</span>
            </p>
            <p className="pl-1 green">₹20</p>
          </div>
        </div>

        <div
          className="mt-2 p-3 flex ml-auto cursor shadow bg-fff "
          onClick={(event) => {
            if (width < 720) {
              otherSellerDrawer("bottom", true, event);
            } else {
              otherSellerDrawer("right", true, event);
            }
          }}
        >
          <MaterialIcons name="verified-user" size={18} color="#bbb" />
          <div className="cursor align-center flex">
            <p className="pl-1 m-0">Other available seller</p>
          </div>
        </div> */}

        {selected_product?.description != null ? (
          <div className="m-0 w-100 p-3 bg-fff ">
            <p>{selected_product?.description}</p>
          </div>
        ) : null}

        <div className="m-0 w-100 px-3 py-2 bg-fff ">
          <div className="desc">
            <h5 className="mb-3">Product Information</h5>

            {product_data[0]?.specifications.map(
              (s_data: any, s_index: any) => (
                <div key={s_index}>
                  {/* <h6>{value.title}</h6> */}
                  {/* {value.items.map((child, Index) => ( */}
                  {s_data.values != "" ? (
                    <div>
                      <div className="">
                        <div className="row border-top equal-height">
                          <div className="col-4 bg-gray d-flex align-items-center p-2">
                            <div className="text-dark fw-500 let_space_03 fs-13px pop-regular">
                              {s_data.name}
                            </div>
                          </div>
                          {Array.isArray(s_data.values) ? (
                            s_data.values.map((v_data: any, v_index: any) => (
                              <div className="col-8 p-2">
                                {hasHtmlTags(v_data.value) ? (
                                  <div
                                    className="text-gray fs-13px pop-regular let_space_03  in_html"
                                    dangerouslySetInnerHTML={{
                                      __html: v_data.value,
                                    }}
                                  />
                                ) : (
                                  <div className="text-gray fs-13px pop-regular let_space_03  in_html">
                                    {v_data.value}
                                  </div>
                                )}
                              </div>
                            ))
                          ) : (
                            <div className="col-8 p-2">
                              {hasHtmlTags(s_data.values) ? (
                                <div
                                  className="text-gray fs-13px pop-regular let_space_03  in_html"
                                  dangerouslySetInnerHTML={{
                                    __html: s_data.values,
                                  }}
                                />
                              ) : (
                                <div className="text-gray fs-13px pop-regular let_space_03  in_html">
                                  {s_data.values}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* ))} */}
                </div>
              )
            )}
          </div>
        </div>

        {/*  <div className="desc pt-3">
          {data.highlight.map((value, valueIndex) => (
                <div>
                  <h5>{value.title}</h5>
                  {/* <h6 className="mb-1">Highlight on products</h6> /}
                  <ul className="mb-0 p-0 mt-2">
                    {value.items.map((child, valueIndex) => (
                      <li
                        className="py-1 d-flex align-items-ceter"
                        key={valueIndex}
                      >
                        <MaterialIcons
                          name="local-offer"
                          size={13}
                          color="#777"
                        />
                        <p className="mb-0 ps-2 text-darkgray">
                          {child.data}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              ))} 
        </div>*/}

        {/* <div className="desc pt-3">
          {data.offer.map((data, offerIndex) => (
                <div key={offerIndex}>
                  <h5>{data.title}</h5>
                  {/* <h6 className="mb-1">Offer on products</h6> /}
                  <ul className="mb-0 p-0 mt-2">
                    {data.value.map((child, index) => (
                      <li
                        className="py-1 d-flex align-items-ceter"
                        key={index}
                      >
                        <MaterialIcons
                          name="local-offer"
                          size={13}
                          color="#777"
                        />
                        <p className="mb-0 ps-2 text-darkgray">
                          {child.data}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              ))} 
        </div>*/}

        {/*<div className="bg-white mt-2">
                      <div className="container">

                          <div className="desc mt-2 p-3 shadow">
                              {details.highlight.map((value, valueIndex) => (
                          <div>
                              <h6>{value.title}</h6>
                              {/* <h6 className="mb-1">Highlight on products</h6> *
                              <ul className="mb-0">
                                  {value.items.map((child, valueIndex) => (
                                      <li className="p-1" key={valueIndex}>
                                          <p>{child.data}</p>
                                      </li>
                                  ))}
                              </ul>
                          </div>
                      ))}
                          </div>

                          <div className="desc mt-2 p-3 shadow">
                              {details.offer.map((data, detailsIndex) => (
                          <div>
                              <h6>{data.title}</h6>
                              {/* <h6 className="mb-1">Offer on products</h6> *
                              <ul className="mb-0">
                                  {data.value.map((child, index) => (
                                      <li className="p-1" key={index}>
                                          <p>{child.data}</p>
                                      </li>
                                  ))}
                              </ul>
                          </div>
                      ))} 
                          </div>
                      </div>
                  </div>*/}

        {/* <div className="bg-fff mt-2 p-2 pt-3">
          <div className="row align-center">
            <div className="col-7">
              <h5>Review & Ratings</h5>
            </div>
            <div className="col-5 text-right">
              <p
                className="mb-0 text-prime1 fw-bold fs-7 cursor"
                onClick={() => {
                  SetRateProduct(true);
                  
                }}
              >
                Rate Product
              </p>
            </div>
          </div>

          <div className="pt-4 align-center">
            <div className="row">
              <div className="col-md-4 col-4 pt-2 pb-3 px-0 text-center">
                {/* <h2>{details.star_rating} ★</h2> /}
                <h2 className="text-dark">
                  {/* {data.star_rating.toFixed(1)}  /}
                  5★
                </h2>

                <p>
                  5 Ratings & <br />5 Reviews
                </p>
              </div>
              <div className="col-md-8 col-8 pt-2 px-0">
                {/* {data.ratingShow.map((data, detailsIndex) => (
                  <div className="row align-items-center" key={detailsIndex}>
                    {data.star == 5 ? (
                      <p className="w-35 end p-0 d-flex">
                        {" "}
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                      </p>
                    ) : data.star == 4 ? (
                      <p className="w-35 end p-0 d-flex">
                        {" "}
                        &#9733; &#9733; &#9733; &#9733;
                      </p>
                    ) : data.star == 3 ? (
                      <p className="w-35 end p-0 d-flex">
                        {" "}
                        &#9733; &#9733; &#9733;{" "}
                      </p>
                    ) : data.star == 2 ? (
                      <p className="w-35 end p-0 d-flex"> &#9733; &#9733; </p>
                    ) : (
                      <p className="w-35 end p-0 d-flex"> &#9733; </p>
                    )}
                    <div className="w-50">
                      <ProgressBar now={data.rating} variant={data.variant} />
                    </div>
                    <small className="w-15per text-left p-0">
                      {data.value}
                    </small>
                  </div>
                ))} /}
              </div>
            </div>
            <div className="mt-2">
              <h6>Reviews with video</h6>
              <AliceCarousel
                mouseTracking
                touchMoveDefaultEvents={false}
                // items={productsRestraunt}
                // paddingLeft={50}
                paddingRight={30}
                disableDotsControls
                responsive={video_responsive}
                disableButtonsControls
                controlsStrategy="alternate"
              >
                {user_video.slice(0, 11).map((data, i) => (
                  <div className="p-0 " key={i} onDragStart={handleDragStart}>
                    <div className="px-1">
                      {i == 10 ? (
                        <Link to={"/review_gallery"}>
                          <div
                            className="p-0 img_over_container"
                            onClick={() => {
                              // openImageUser(6);
                              // SetOpenVideo(!open_video)
                            }}
                          >
                            <div className="img_over_see px-1">
                              <video
                                style={{ width: "100%" }}
                                className="main-video-preview"
                                muted
                              >
                                <source src={data.link} />
                                {/* <source src="http://clips.vorwaerts-gmbh.de/VfE_html5.mp4" /> /}
                              </video>
                            </div>
                            <p className="fs-7 centered text-white">
                              See All
                            </p>
                          </div>
                        </Link>
                      ) : (
                        <div
                          onClick={() => {
                            SetOpenVideo(!open_video);
                          }}
                        >
                          <div className="">
                            <video
                              style={{ width: "100%" }}
                              className="main-video-preview"
                              muted
                            >
                              <source src={data.link} />
                              {/* <source src="http://clips.vorwaerts-gmbh.de/VfE_html5.mp4" /> /}
                            </video>
                          </div>
                          <div className="video_over_content pt-2">
                            <div className="d-flex align-items-center">
                              <div className="d-flex align-items-center">
                                <div className="pe-1">
                                  <MaterialIcons
                                    name="star"
                                    size={10}
                                    color="orange"
                                  />
                                </div>
                                <div className="pe-1">
                                  <MaterialIcons
                                    name="star"
                                    size={10}
                                    color="orange"
                                  />
                                </div>
                                <div className="pe-1">
                                  <MaterialIcons
                                    name="star"
                                    size={10}
                                    color="orange"
                                  />
                                </div>
                                <div className="pe-1">
                                  <MaterialIcons
                                    name="star"
                                    size={10}
                                    color="orange"
                                  />
                                </div>
                                <div className="pe-1">
                                  <MaterialIcons
                                    name="star-border"
                                    size={10}
                                    color="orange"
                                  />
                                </div>
                              </div>
                              <div className="ms-auto">
                                <div className="d-flex align-items-center">
                                  <Ionicons
                                    name="md-play"
                                    size={10}
                                    color="white"
                                  />
                                  <div className="ps-1">
                                    <p className="mb-0 text-white v-small">
                                      0.30
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </AliceCarousel>
            </div>
            <div className="my-2">
              <h6>Reviews with images</h6>
              <AliceCarousel
                mouseTracking
                touchMoveDefaultEvents={false}
                // items={productsRestraunt}
                // paddingLeft={50}
                paddingRight={50}
                disableDotsControls
                responsive={preview_responsive}
                disableButtonsControls
                controlsStrategy="alternate"
              >
                {user_img.slice(0, 11).map((data, i) => (
                  <div className="p-0 " key={i} onDragStart={handleDragStart}>
                    <div className="px-1">
                      {/* {i == 10 ? (
                                                  <div>
                                                    <img
                                                      src={data.img_path}
                                                      className="main-50-preview"
                                                      key={i}
                                                      onClick={() => {
                                                          // openImageUser(i);
                                                          SetOpenImage(!open_image)
                                                      }}
                                                  />
                                                  </div>
                                              ) : (
                                                  <div>
                                                      <img
                                                          src={data.img_path}
                                                          className="main-preview"
                                                          key={i}
                                                          onClick={() => {
                                                              // openImageUser(i);
                                                              SetOpenImage(!open_image)
                                                          }}
                                                      />
                                                  </div>
                                              )} /}
                      {i == 10 ? (
                        <Link to={"/review_gallery"}>
                          <div
                            className="p-0 img_over_container"
                            onClick={() => {
                              // openImageUser(6);
                              // SetOpenImage(!open_image)
                            }}
                          >
                            <div className="img_over_see px-1">
                              <img
                                src={data.img_path}
                                className="main-preview"
                                key={10}
                              />
                            </div>
                            <p className="fs-7 centered text-white">
                              See All
                            </p>
                          </div>
                        </Link>
                      ) : (
                        <img
                          src={data.img_path}
                          className="main-preview"
                          key={i}
                          onClick={() => {
                            // openImageUser(i);
                            SetOpenImage(!open_image);
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </AliceCarousel>
            </div>
            <div className="review ">
              {/* {data.review.map((reviewdata, index) => (
                <div className="my-2 border-bottom py-2">
                  <div key={index} className="flex box ">
                    <div>
                      <p className="rating_star">{reviewdata.value}★</p>
                    </div>
                    <div className="pl-1">
                      <h6 className="mb-1">{reviewdata.review}</h6>
                    </div>
                  </div>
                  <div>
                    <ReadMore>
                      GeeksforGeeks: A Computer Science portal for geeks. It
                      contains well written, well thought and well explained
                      computer science, programming articles and quizzes. It
                      provides a variety of services for you to learn, so
                      thrive and also have fun! Free Tutorials, Millions of
                      Articles, Live, Online and Classroom Courses ,Frequent
                      Coding Competitions, Webinars by Industry Experts,
                      Internship opportunities, and Job Opportunities.
                      Knowledge is power!
                    </ReadMore>
                    <div className="row pt-2">
                      {reviewdata.file.map((data, i) => (
                        <div className="col-2 px-1">
                          <img
                            src={data}
                            className="preview"
                            key={i}
                            onClick={() => {
                              openImageViewer(i);
                            }}
                          />
                        </div>
                      ))}
                      {viewer_open && (
                        <ImageViewer
                          src={reviewdata.file}
                          currentIndex={current_image}
                          backgroundStyle={{
                            backgroundColor: "rgba(0,0,0,0.5)",
                          }}
                          closeOnClickOutside={true}
                          onClose={closeImageViewer}
                        />
                      )}
                    </div>
                  </div>

                  <div className="row align-center pt-2">
                    <div className="col-md-4 col-6 pl-0">
                      <p className="pl-1 oneline text-black mb-0">
                        {reviewdata.customer}
                      </p>
                    </div>
                    <div className="col-md-4 col-6 end ">
                      <div
                        className="cursor"
                        onClick={() => {
                          likeclick(index);
                        }}
                      >
                        <Entypo
                          name="thumbs-up"
                          size={16}
                          color={reviewdata.like ? "darkblue" : "gray"}
                        />
                      </div>
                      <p className="pl-1 mb-0">{reviewdata.likeCount}</p>
                      <div className="d-flex align-items-center w-auto pl-2">
                        <div
                          className="cursor"
                          onClick={() => {
                            dislikeclick(index);
                          }}
                        >
                          <Entypo
                            name="thumbs-down"
                            size={16}
                            color={reviewdata.dislike ? "brown" : "gray"}
                          />
                        </div>
                        <p className="pl-1 mb-0">{reviewdata.dislikeCount}</p>
                      </div>
                    </div>
                    <div className="d-flex alifn-items-center col-12 pt-1 pr-0 pl-1">
                      <MaterialIcons
                        name="check-circle"
                        size={13}
                        color="gray"
                      />
                      <p className="mb-0 p-1 py-0">
                        {reviewdata.buyer}, {reviewdata.date}
                      </p>
                    </div>
                  </div>
                </div>
              ))} /}
            </div>

            <div
              className="p-2"
              onClick={() => {
                SaveContext(details[0]);
              }}
            >
              <Link className="cursor" to="/ecommerce/all_reviews">
                <p className="fw-bolder text-prime1 mb-0">View all reviews</p>
              </Link>
            </div>
          </div>
        </div> */}

        {/* <div className="bg-white pb-5 mt-2 p-2">
      <h6 className="pt-2 pl-0">Similiar Products</h6>
<div className="similiar">
        <AliceCarousel
          mouseTracking
          touchMoveDefaultEvents={false}
          // items={productsRestraunt}
          // paddingLeft={50}
          paddingRight={20}
          disableDotsControls
          responsive={responsive}
          disableButtonsControls
          controlsStrategy="alternate"
        >
          {similiar.map((data, index) => (
            <div
              className="py-0 px-1 prods mt-2"
              key={index}
              onDragStart={handleDragStart}
            >
              <div className="border pb-2">
                <div className="card bg-lightblue pt-2 pb-0 ">
                  {data.discount != "" ? (
                    <div className="text-center disc_ecom">
                      <p>
                        <b className="text-white">{data.discount}% Off</b>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="img-top-right cursor"
                    onClick={() => {
                      
                      // let product_show_data = product_show;
                      // product_show_data[index].fav_heart =
                      //     !product_show_data[index].fav_heart;
                      // SetProductShow((preState) => ({
                      //     ...preState,
                      //     Products: product_show_data,
                      // }));
                      
                    }}
                  >
                    {data.fav_heart ? (
                      <div className="wishlist-heart">
                        <Ionicons
                          name="ios-heart"
                          size={22}
                          color="rgb(255, 0, 48)"
                        />
                      </div>
                    ) : (
                      <div className="wishlist-heart">
                        <Ionicons
                          name="heart-outline"
                          size={22}
                          color="gray"
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className=""
                    onClick={() => {
                      SetQuick((prevValue: any) => {
                        prevValue.open = true;
                        prevValue.data = data;
                        return { ...prevValue };
                      });
                    }}
                  >
                    <img src={data.img_path} className="prods-img" />
                  </div>
                </div>

                <div className="bg-fff">
                  <div className="p-2 pt-1 pe-1">
                    <p className="oneline text-dark">{data.name}</p>

                    <div className="d-flex align-center pt-1">
                      <p className="text-dark pr-1">₹{data.offer_price}</p>
                      <small className="v-small text-gray text-end">
                        <s>₹{data.original_price}</s>
                      </small>
                      <div className="ms-auto">
                        <div className="d-flex align-items-center">
                          <p className="extra-small fw-500 mb-0 text-orange ">
                            3.5
                          </p>
                          <Ionicons name="md-star" size={9} color="#ffa500" />
                          <p className="extra-small fw-500 ps-1 mb-0 text-gray-200">
                            (785)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-0 text-center">
                    {data.addbtn ? (
                      <div
                      // className={
                      //   data.img_path !== "" ? "Grocebottom-offer" : ""
                      // }
                      >
                        <div className="flex Groceoffer center p-2">
                          <div
                            className="cursor pr-1 w-25 center"
                            onClick={() => {
                              // minus(index);
                            }}
                          >
                            <Entypo name="minus" size={18} color="#000" />
                          </div>
                          <div className="w-50 center">
                            
                            <p className="smooth">
                              <b>{data.addcount}</b>
                            </p>
                          </div>
                          <div
                            className="cursor pl-1 w-25"
                            onClick={() => {
                              // minus(index);
                            }}
                          >
                            <Entypo name="plus" size={18} color="#000" />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="ecomoffer"
                        onClick={(event) => {
                          
                          if (data.variant.length > 0) {
                            if (width < 720) {
                              variantDrawer("bottom", true, event, data);
                            } else {
                              variantDrawer("right", true, event, data);
                            }
                          } else {
                            
                          }
                        }}
                      >
                        <p className="cursor p-2">
                          <b className="text-prime1">Add</b>
                        </p>
                      </div>
                    )}
                    {data.variant != "" ? (
                      <small className="text-black">
                        <u className="underline">3+ more</u>
                      </small>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </AliceCarousel>
      </div>
    </div> */}

        {/* <div
      className={
        g_props?.fullWidth ? "col-12 p-1" : "col-6 pe-0 pb-2 box-even"
      }
      key={g_props?.index}
    >
      <div className="prod-card border-lightgray">
        <div className="">
          {g_props?.data.hasOwnProperty("images") ? (
            g_props?.view_type == "grid" &&
            g_props?.data.images.length > 1 ? (
              <div className="h-150px">
      

                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  {!isMobile && activeImageIndex > 0 && (
                    <button
                      onClick={scrollLeft}
                      className="scroll-left fs-12px pop-font"
                      style={{
                        // position: "absolute",
                        left: "5px",
                        top: "50%",
                        // transform: "translateY(-50%)",
                        // zIndex: 1,
                        // background: "#fff",
                        // color: "rgb(0, 87, 255)",
                        // border: "none",
                        // borderRadius: "50%",
                        // padding: "2px 7px",
                        // cursor: "pointer",
                        visibility: isAtStart ? "hidden" : "visible",
                        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        // fontSize:"12px",
                      }}
                    >
                      ❮
                    </button>
                  )}

                  <div
                    ref={scrollContainerRef}
                    onScroll={handleScroll}
                    style={{
                      display: "flex",
                      overflowX: "auto",
                      scrollBehavior: "smooth",
                      gap: "6px",
                      padding: "10px",
                      scrollbarWidth: "none", // Firefox
                      msOverflowStyle: "none", // IE
                    }}
                    onClick={() => {
                      g_props?.open_quick_view();
                      //   SetQuickView({
                      //     open: true,
                      //     value: g_props?.data,
                      //   });
                    }}
                  >
                    {g_props.data.images.map((i_ele: any) => (
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          context.app_data.image_base_url,
                          i_ele?.url,
                          {
                            format: "webp",
                            width: 240,
                          }
                        )}
                        thumb={AppHelper.imageSrc(
                          context.app_data.image_base_url,
                          i_ele?.url,
                          {
                            format: "webp",
                            width: 240,
                            quality: 10,
                          }
                        )}
                        class_name="Custom-prod-img"
                        // {

                        //   g_props?.data.available &&
                        //   g_props?.get_outlet_available
                        //     ? "Custom-prod-img"
                        //     : "Custom-prod-img "
                        // }
                        onIsVisible={() => {
                          
                        }}
                        loading="lazy"
                      />
                    ))}
                  </div>

                  <style>
                    {`
          div::-webkit-scrollbar {
            display: none;
          }
        `}
                  </style>

                  {!isMobile &&
                    !isAtEnd &&
                    g_props?.data.images.length - 1 && (
                      <button
                        onClick={scrollRight}
                        className="scroll-right fs-12px pop-font"
                        style={{
                          // position: "absolute",
                          right: "5px",
                          top: "50%",
                          // transform: "translateY(-50%)",
                          // zIndex: 1,
                          // background: "#fff",
                          // color: "rgb(0, 87, 255)",
                          // border: "none",
                          // borderRadius: "50%",
                          // padding: "2px 7px",
                          // cursor: "pointer",
                          visibility: isAtEnd ? "hidden" : "visible",
                          // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          // fontSize:"12px",
                        }}
                      >
                        ❯
                      </button>
                    )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    position: "relative",
                    bottom: "5px",
                    right: "4px",
                    gap: "2px",
                  }}
                >
                  {g_props.data.images.map((_, index: any) => (
                    <div
                      key={index}
                      onClick={() => scrollToImage(index)}
                      style={{
                        width: "5px",
                        height: "5px",
                        borderRadius: "50%",
                        backgroundColor:
                          activeImageIndex === index
                            ? "rgb(0, 87, 255)"
                            : "#ccc",
                        transition: "background-color 0.3s",
                      }}
                    ></div>
                  ))}
                </div>
              </div>
            ) : (
              <div
                className="h-140px p-2"
                onClick={() => {
                  g_props?.open_quick_view();
                  //   SetQuickView({
                  //     open: true,
                  //     value: g_props?.data,
                  //   });
                }}
              >
                <ImageContainer
                  src={AppHelper.imageSrc(
                    context.app_data.image_base_url,
                    g_props?.data.images[0]?.url,
                    {
                      format: "webp",
                      width: 320,
                    }
                  )}
                  thumb={AppHelper.imageSrc(
                    context.app_data.image_base_url,
                    g_props?.data.images[0]?.url,
                    {
                      format: "webp",
                      width: 320,
                      quality: 10,
                    }
                  )}
                  class_name={
                    g_props?.data.available && g_props?.get_outlet_available
                      ? "w-100 prod-image "
                      : "w-100 prod-image-gray "
                  }
                  style={{backgroundColor: bgColor}}
                  onIsVisible={() => {
                    
                  }}
                />
              </div>
            )
          ) : (
            <div className="h-140px prod-no-image-gray"
            onClick={() => {
              g_props?.open_quick_view();
              //   SetQuickView({
              //     open: true,
              //     value: g_props?.data,
              //   });
            }}
            style={{backgroundColor: bgColor}}></div>
          )}
        </div>
        <div className="px-2 pt-2 mb-2">
          <p className="text-dark pop-font double">{g_props?.data.name}</p>
          <p className="text-gray v-small pop-font">
            {g_props.data.type == "group" &&
              get_variant_name(g_props?.data) + ","}
            {g_props.data.hasOwnProperty("basic_specifications") &&
            Object.keys(g_props.data.basic_specifications).length > 0
              ? g_props.data.basic_specifications.weight +
                g_props.data.basic_specifications.weight_uint
              : null}
          </p>
        </div>
        <div className="px-2 pb-2 mt-auto">
          <div className="row">
            <div className="col-5 p-0 w-45">
              {g_props?.data.hasOwnProperty("show_price_without_gst") ? (
                g_props?.data.show_price_without_gst ? (
                  <>
                    {g_props?.data.price_without_gst != "" &&
                    g_props?.data.price_without_gst != null ? (
                      <p
                        className="text-dark"
                        style={{
                          fontSize: getFontSize(
                            get_mrp_data(g_props?.data?.price_without_gst)
                              ?.length
                          ),
                        }}
                      >
                        {get_mrp_data(g_props?.data?.price_without_gst)}
                      </p>
                    ) : null}
                  </>
                ) : (
                  <>
                    {g_props?.data.price_with_gst != "" &&
                    g_props?.data.price_with_gst != null ? (
                      <p
                        className="text-dark"
                        style={{
                          fontSize: getFontSize(
                            get_mrp_data(g_props?.data?.price_with_gst)
                              ?.length
                          ),
                        }}
                      >
                        {get_mrp_data(g_props?.data?.price_with_gst)}
                      </p>
                    ) : null}
                  </>
                )
              ) : null}
              <p
                className="strike"
                style={{
                  fontSize: getFontSize(
                    get_mrp_data(g_props?.data?.mrp)?.length,
                    18
                  ),
                }}
              >
                {get_mrp_data(g_props?.data?.mrp)}
              </p>
            </div>
            <div className="col-7 px-0 w-55">
              {g_props?.from == "vendor_page" ||
              g_props?.from == "category_page" ? (
                g_props?.get_outlet_available ? (
                  g_props?.data.available ? (
                    g_props?.data.addbtn ? (
                      <div>
                        <div className="prod-add-btn cursor">
                          <QuantityButtonComponent
                            from_page={"vendor"}
                            section_slug={
                              context.app_data.selected_section.slug
                            }
                            context_data={context}
                            minus={() => {
                              g_props?.onminus();
                            }}
                            pluse={() => {
                              g_props?.onpluse();
                            }}
                            addcount={g_props?.data.addcount}
                            api_loading={g_props?.data.api_loading}
                          />
                          {/* <div className="d-flex">
                            {g_props?.data.api_loading ? (
                              <Spinner />
                            ) : (
                              <>
                                //-
                                <div
                                  className="cursor pr-1 center"
                                  onClick={() => {
                                    g_props?.onminus();
                                  }}
                                >
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "b48a5c15d42fc79330a9658ff3779170.svg"
                                    }
                                    className="type-img"
                                  />
                                </div>
                                //qty
                                <div className="w-100 center">
                                  <p className="smooth">
                                    <b>{g_props?.data.addcount}</b>
                                  </p>
                                </div>
                                //+
                                <div
                                  className="cursor pl-1"
                                  onClick={() => {
                                    g_props?.onpluse();
                                  }}
                                >
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "2a39073bf6a8f72e9d703ad4b9cdeab4.svg"
                                    }
                                    className="type-img"
                                  />
                                </div>
                              </>
                            )}
                          </div> /}
                          {g_props?.data.customize ? (
                            <fieldset className="center-text-in-border">
                              <legend>More Options</legend>
                            </fieldset>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <div>
                          {g_props?.data.api_loading ? (
                            <div className="prod-add-btn cursor">
                              <p className="">
                                <Spinner />
                              </p>
                              {g_props?.data.customize ? (
                                <fieldset className="center-text-in-border">
                                  <legend>More Options</legend>
                                </fieldset>
                              ) : null}
                            </div>
                          ) : (
                            <div className="prod-add-btn cursor" onClick={(event) => {
                              
                              g_props?.onadd();
                            }}>
                              <p className="">
                                ADD
                              </p>
                              {g_props?.data.customize ? (
                                <fieldset className="center-text-in-border">
                                  <legend>More Options</legend>
                                </fieldset>
                              ) : null}
                            </div>
                          )}
                      </div>
                    )
                  ) : (
                    <>
                      <div className="prod-add-btn">
                        {g_props?.data.next_available_at != "" &&
                        g_props?.data.next_available_at != null ? (
                          <p className="">
                            Next Available at{" "}
                            {g_props?.data.next_available_at}
                          </p>
                        ) : (
                          <p className="fs-8px py-1">Unavailable</p>
                        )}
                      </div>
                    </>
                  )
                ) : null
              ) : null}

              {g_props?.from == "search_page" ? (
                g_props?.data.available ? (
                  <div>
                    <div
                      className="prod-add-btn cursor"
                      onClick={(event) => {
                        
                        // const navigate = useNavigate();
                        g_props?.navigate();
                      }}
                    >
                      {g_props?.data.api_loading ? (
                        <p className="">
                          <Spinner />
                        </p>
                      ) : (
                        <p className="">ADD</p>
                      )}
                      {/* {g_props?.data.customize ? ( /}
                        <fieldset className="center-text-in-border">
                          <legend>More Options</legend>
                        </fieldset>
                      {/* ) : null} /}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="prod-add-btn">
                      {g_props?.data.next_available_at != "" &&
                      g_props?.data.next_available_at != null ? (
                        <p className="">
                          Next Available at {g_props?.data.next_available_at}
                        </p>
                      ) : (
                        <p className="fs-8px py-1">Unavailable</p>
                      )}
                    </div>
                  </>
                )
              ) : null}

              {/* <div className="prod-add-btn">
              ADD
              {g_props?.data.variable == true ? (
                <fieldset className="center-text-in-border">
                  {" "}
                  <legend>{g_props?.data.no_of_variable} Options</legend>
                </fieldset>
              ) : null}
            </div> /}
            </div>
          </div>
        </div>
      </div>
    </div> */}

        {selected_product.available === true ? (
          <div className=" bottom-fixed py-2 px-3 w-100 mt-3 shadow d-flex align-items-center">
            {/* {variant_data.available ? ( */}
            {/* <div className="col-6 bg-fff p-2">
                        <h6 className="mb-0">₹{Math.ceil(total_price)}</h6>
                        <p className="mb-0">
                          <small>Total Product Price</small>
                        </p>
                    </div> */}
            {/* show_total ? ( */}
            <div className="w-50">
              <div>
                <div className="fw-bold text-dark">
                  ₹{selected_product?.price_with_gst}
                </div>
              </div>
              <div className="text-gray mt-0 pt-0 fs-10px">
                Inclusive of all taxes
              </div>

              {/* <p className="mb-0">
                          <small>Total Product Price</small>
                        </p> */}
            </div>
            {/* ) : (
                      <div className="w-50 end p-2">
                        <h6 className="mb-0">
                          <Skeleton height={10} width={80} />
                        </h6>
                        <p className="mb-0">
                          {/* <small>Total Product Price</small> /}
                          <Skeleton height={10} width={80} />
                        </p>
                      </div>
                    )
                  ) : null} */}
            <div className="w-50 end">
              {/* {get_outlet_available() ? (
                          props.data.available ? (
                            props.data.addbtn ? (
                              <div>
                                <div className="prod-add-btn">
                                  <div className="d-flex">
                                    {g_props.data.api_loading ? (
                                      <Spinner />
                                    ) : (
                                      <>
                                        <div
                                          className="cursor pr-1 w-25 center"
                                          onClick={() => {
                                            g_props.onminus();
                                          }}
                                        >
                                          <img
                                            src={
                                              AppConfig.CDN_Media_url +
                                              "b48a5c15d42fc79330a9658ff3779170.svg"
                                            }
                                            className="type-img"
                                          />
                                        </div>
                                        <div className="w-50 center">
                                          <p className="smooth">
                                            <b>{g_props.data.addcount}</b>
                                          </p>
                                        </div>
                                        <div
                                          className="cursor pl-1 w-25"
                                          onClick={() => {
                                            g_props.onpluse();
                                          }}
                                        >
                                          <img
                                            src={
                                              AppConfig.CDN_Media_url +
                                              "2a39073bf6a8f72e9d703ad4b9cdeab4.svg"
                                            }
                                            className="type-img"
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  {props.data.customize ? (
                                    <fieldset className="center-text-in-border">
                                      <legend>More Options</legend>
                                    </fieldset>
                                  ) : null}
                                </div>
                              </div>
                            ) : ( */}
              <div className="w-90per">
                <div
                  className="prod-add-btn bg-green"
                  onClick={(event) => {
                    
                    props.onadd();
                  }}
                >
                  {/* {props.data.api_loading ? (
                                    <p className="">
                                      <Spinner />
                                    </p>
                                  ) : ( */}
                  <div className="text-white pop-font fs-13px let_space_03">
                    ADD
                  </div>
                  {/* )} */}
                  {/* {props.data.customize ? (
                                    <fieldset className="center-text-in-border">
                                      <legend>More Options</legend>
                                    </fieldset>
                                  ) : null} */}
                </div>
              </div>
              {/* )
                          ) : (
                            <>
                              <div className="prod-add-btn">
                                {props.data.next_available_at != "" &&
                                props.data.next_available_at != null ? (
                                  <p className="">
                                    Next Available at {props.data.next_available_at}
                                  </p>
                                ) : (
                                  <p className="">Unavailable</p>
                                )}
                              </div>
                            </>
                          )
                        ) : null} */}
            </div>
          </div>
        ) : (
          <div className=" bottom-fixed p-3 w-100 mt-3 shadow center bg-green" 
                  onClick={() => {
                    
                    
                  }}
                >
                  <div className="text-white pop-font fs-13px let_space_03">
                    Notify Me!
                  </div>
              
          </div>
        )}

        {/* <div className="bottom-fixed w-100">
      <div className="row center ">
        <div className="col-6 p-0">
          <Link to="/cart">
            <div
              className="bg-white w-100 center p-3 inset-border"
              //   onClick={() => {
              //     product_addcart(index);
              //   }}
            >
              <h6 className="text-primary mb-0">Add Cart</h6>
            </div>
          </Link>
        </div>
        <div className="col-6 align-bottom p-0">
          <Link to="/ordersummary">
            <div className="bg-primary w-100 center p-3 inset-border">
              <h6 className="text-white mb-0">Buy Now</h6>
            </div>
          </Link>
        </div>
      </div>
    </div> */}

        <div className="social-icons">
          <SwipeableBottomSheet
            overflowHeight={0}
            style={{ zIndex: 1300 }}
            open={social_swipe}
            onClose={() => {
              SetSocialSwipe(false);
            }}
            onChange={() => {
              SetSocialSwipe(false);
            }}
          >
            <div
              className="bg-fff radius-top"
              style={{ minHeight: "500px", maxHeight: "auto" }}
            >
              <div className="center drawer-fixed">
                <hr className="hr-drawer" />
              </div>
              <div className="text-dark fs-13px pop-regular text-center pt-5 py-3">
                Share product via Social Media
              </div>
              <div className="mb-5 px-2 pb-5 pt-3">
                {/* <p className="fs-7 text-black prod-name marg">
                      Fresh Berries Premium BrandsFresh Berries Premium
                      Brands Fresh Berries Premium Brands (Multicolor)
                      Fresh Berries Premium Brands (Multicolor)
                  </p> */}
                <div className="row">
                  <div className="col-3 col-md-2 text-center">
                    <div
                      className="text-center cursor"
                      // onClick={copyToClipboard}
                    >
                      <img
                        src={
                          AppConfig.CDN_Image_url +
                          "951f81def35a713698ee432c3f5a7e97.png"
                        }
                        className="social_icon"
                      />
                      <p className="text-center pt-2 text-black pop-regular fs-11px let_space_03">Copy Link</p>
                    </div>
                  </div>

                  <div className="col-3 col-md-2 text-center">
                    <FacebookShareButton
                      url={shareUrl}
                      className="Demo__some-network__share-button"
                    >
                      <img
                        src={
                          AppConfig.CDN_Image_url +
                          "cae75b27547f4dc350c66f9d8c01bf96.png"
                        }
                        className="social_icon"
                      />
                    </FacebookShareButton>
                    <p className="text-center pt-2 text-black pop-regular fs-11px let_space_03">Facebook</p>
                  </div>
                  <div className="col-3 col-md-2 text-center">
                    <InstapaperShareButton
                      url={shareUrl}
                      className="Demo__some-network__share-button"
                    >
                      <img
                        src={
                          AppConfig.CDN_Image_url +
                          "56fca6895010fb9f8521d9e2f3f5622e.png"
                        }
                        className="social_icon"
                      />
                    </InstapaperShareButton>
                    <p className="text-center pt-2 text-black pop-regular fs-11px let_space_03">Instagram</p>
                  </div>
                  <div className="col-3 col-md-2 text-center">
                    <WhatsappShareButton
                      url={shareUrl}
                      separator=":: "
                      className="Demo__some-network__share-button"
                    >
                      <img
                        src={
                          AppConfig.CDN_Image_url +
                          "1df86c3f6a82e30223339abb20a22dd2.png"
                        }
                        className="social_icon"
                      />
                    </WhatsappShareButton>
                    <p className="text-center pt-2 text-black pop-regular fs-11px let_space_03">Whatsapp</p>
                  </div>
                  {/* <div className="col-3 col-md-2 mt-3 text-center">
                    <TelegramShareButton
                      url={shareUrl}
                      className="Demo__some-network__share-button"
                    >
                      
                    </TelegramShareButton>
                    <p className="text-center pt-2 text-black">Telegram</p>
                  </div> */}
                </div>
              </div>
            </div>
          </SwipeableBottomSheet>
        </div>
      </div>

      <Dialog
        classes={{ container: classes.root, paper: classes.paper }}
        open={rate_product}
        onClose={() => {
          SetRateProduct(false);
        }}
        className="center"
      >
        <div className="bg-fff">
          <div className="row pt-3">
            <div className="col-10 text-white">
              <h5>Rating & Reviews</h5>
            </div>
            <div
              className="col-2 text-right cursor"
              onClick={() => {
                SetRateProduct(false);
              }}
            >
              <Ionicons name="ios-close" size={20} color="gray" />
            </div>
          </div>
          <ReviewModal
            onSubmit={(data) => {
              
              SetRateProduct(false);
              details.review.push(data);
              // review.push(data);
            }}
          />
        </div>
      </Dialog>

      <Lightbox
        open={open_image}
        close={() => SetOpenImage(false)}
        inline={{
          style: { width: "100%", maxWidth: "900px", aspectRatio: "3 / 2" },
        }}
        carousel={{
          finite,
        }}
        slides={[
          {
            src: "https://images.unsplash.com/photo-1420593248178-d88870618ca0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8bmF0dXJhbHxlbnwwfHwwfHw%3D&w=1000&q=80",
            title: reviw_title(),
            description: descrip(),
          },
          {
            src: "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg",
            title: reviw_title(),
            description: descrip(),
          },
        ]}
        plugins={[Captions]}
      />

      <Lightbox
        open={open_video}
        close={() => SetOpenVideo(false)}
        inline={{
          style: { width: "100%", maxWidth: "900px", aspectRatio: "3 / 2" },
        }}
        carousel={{
          finite,
        }}
        slides={[
          {
            type: "video",
            width: "100%",
            height: 500,
            disablePictureInPicture,
            disableRemotePlayback,
            controlsList: controlsList.join(" "),
            poster: "http://clips.vorwaerts-gmbh.de/VfE_html5.mp4",
            sources: [
              {
                src: "http://clips.vorwaerts-gmbh.de/VfE_html5.mp4",
                type: "video/mp4",
              },
            ],
            title: reviw_title(),
            description: descrip(),
          },
        ]}
        plugins={[Captions, Video]}
      />

      {/* <div>
        {["right", "bottom"].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* <Button onClick={toggleDrawer(anchor, true)}>
                  {anchor}
                </Button> /}
            <Drawer
              anchor={anchor}
              open={seller_state[anchor]}
              onClose={(event) => {
                otherSellerDrawer(anchor, false, event);
              }}
              onOpen={(event) => {
                otherSellerDrawer(anchor, true, event);
              }}
            >
              <div className="pb-5">
                <div className="row p-md-3 pb-sm-2 border-bottom align-items-center">
                  <div className="col-10 ps-0">
                    <h6 className="mb-0">More seller options</h6>
                  </div>
                  <div
                    className="col-2 text-right cursor pe-0"
                    onClick={(event) => {
                      otherSellerDrawer(anchor, false, event);
                    }}
                  >
                    <Ionicons name="close" size={24} color="gray" />
                  </div>
                </div>
                <div className="row align-items-center py-2">
                  <div className="col-3">
                    <img
                      src={require("../../../assets/img/vege.png")}
                      className="other-seller-img"
                    />
                  </div>
                  <div className="col-9 ps-0 pe-1">
                    <p className="text-black fs-7 marg">
                      Fresh Berries Premium BrandsFresh Berries Premium Brands
                      Fresh Berries Premium Brands (Multicolor) Fresh Berries
                      Premium
                    </p>
                  </div>
                </div>
                <div className="overflowYScroll pb-5">
                  <p className="text-gray small px-2">Other sellers details</p>
                  <div className="pt-2 pb-5 mb-5">
                    {other_sellers.map((data, index) => (
                      <div className="p-2 " key={index}>
                        <div className="border-bottom">
                          <div className="row align-items-center pb-2 p-0 ">
                            <div className="col-9 ps-0">
                              <h6>
                                <b>₹ {data.price}</b>
                              </h6>
                              <p className="pb-2">{data.offer_detail}</p>
                            </div>
                            <div className="col-3 p-0">
                              <div
                                className="ecomoffer m-0 w-100"
                                onClick={(event) => {
                                  // width < 720
                                  //     ? toggleDrawer("bottom", true, event, data)
                                  //     : toggleDrawer("right", true, event, data)
                                  
                                }}
                              >
                                <p className="cursor p-2">
                                  <b className="text-prime1">Add</b>
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center pb-1">
                            <p className="fs-7 fw-bold text-dark ">
                              Sold by {data.seller}
                            </p>
                            {/* <p className="fs-7 fw-bold px-2">|</p> /}

                            <div className="d-flex align-items-center ms-auto ps-2">
                              {/* <MaterialIcons name="star-half" size={12} color="#ffa500" /> /}
                              <p className="extra-small fw-500 mb-0 text-orange fs-7 pe-1">
                                3.5
                              </p>
                              <Ionicons
                                name="md-star"
                                size={12}
                                color="#ffa500"
                              />
                              <p className="extra-small fw-500 ps-1 mb-0 text-gray-200 fs-7">
                                (785)
                              </p>
                            </div>
                          </div>

                          <div className="d-flex align-items-center pb-2">
                            <p className="text-black">
                              Arrives by {data.delivery_status}
                            </p>
                            <p className="px-2">|</p>
                            <p className="text-black pt-1">
                              Delivery Charge ₹ {data.delivery_price}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Drawer>
          </React.Fragment>
        ))}
      </div> */}

      <Dialog
        open={quick.open}
        // onEnter={}
        classes={{ container: classes.root, paper: classes.paper }}
        onClose={(data) => {
          SetQuick((prevValue: any) => {
            prevValue.open = false;
            return { ...prevValue };
          });
        }}
      >
        <div className="bg-fff pt-2">
          {/* <div className="center d-md-none d-sm-block">
                            <hr className="hr-drawer" />
                        </div> */}

          <div
            className="position-absolute center right-10 d-sm-none d-md-block p-2 cursor"
            onClick={() => {
              SetQuick((prevValue: any) => {
                prevValue.open = false;
                return { ...prevValue };
              });
            }}
          >
            <Ionicons name="close" size={20} color="black" />
          </div>

          <div className="p-2">
            {/* {quick.data.images.map((img, img_index)=>( */}
            {/* <img src={"https://cdn.1superapp.com/images/320/" +quick.data.images[0].url} className="w-100 img-cover" /> */}
            <img src={quick.data.img_path} className="img-cover w-100" />
            {/* ))} */}
            <div className="text-center pb-2">
              <h6 className="oneline">{quick.data.name}</h6>
              <div className="d-flex align-center justify-content-center">
                <p className="text-dark pr-1">₹{quick.data.offer_price} </p>
                {/* {quick.data.max_price - quick.data.min_price != 0 ? (
                                        <p className="text-dark text-end">
                                            ₹{quick.data.max_price}
                                        </p>
                                    ) : (null)} */}

                {/* <p className="pl-1"><b className="text-green">5% Off</b></p> */}
                <span className="px-1 text-gray">|</span>
                <p className="text-orange">4.1★</p>
              </div>
            </div>

            <p className="text-dark fw-bolder mt-1 mb-2">Description</p>
            <ReadMore>
              {/* {quick.data.description} */}
              It contains well written, well thought and well explained computer
              science, programming articles and quizzes. It provides a variety
              of services for you to learn, so thrive and also have fun! Free
              Tutorials, Millions of Articles, Live, Online and Classroom
              Courses ,Frequent Coding Competitions, Webinars by Industry
              Experts, Internship opportunities, and Job Opportunities.
              Knowledge is power!
            </ReadMore>
            <div className="mt-3">
              <p className="fw-bolder text-dark pb-1 mb-0">In Stock</p>
              <div className="d-flex align-items-center p-0">
                <p className="prod-name-mob fw-bolder text-gray mb-0">Seller</p>
                <p className="px-1 delivery_time mb-0 fw-bolder">
                  Super sellers
                </p>
              </div>
            </div>

            <div className="d-flex align-center mt-2 pb-2 border-bottom">
              <p className="m-0 pr-1">Delivery Near</p>
              <p className="mb-0 text-dark">Erode</p>
              <div className="ms-auto">
                <div className="d-flex align-items-center ">
                  <p className="pr-1 mb-0">30 mins</p>
                  <span className="text-gray">|</span>
                  <p className="pl-1 green mb-0">₹20</p>
                </div>
              </div>
            </div>
            <Link
              to={
                width < 548
                  ? "/ecommerce/ecommerce_mobile_product_details"
                  : "/ecommerce/ecommerce_product_details"
              }
            >
              <div
                className="d-flex align-items-center pt-2 cursor"
                onClick={(event) => {
                  SetQuick((prevValue: any) => {
                    prevValue.open = false;
                    return { ...prevValue };
                  });
                }}
              >
                <p className="mb-0 text-black">See more</p>
                <div className="ms-auto">
                  <MaterialIcons
                    name="keyboard-arrow-right"
                    size={20}
                    color="black"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </Dialog>

      <div className="variant">
        {["right", "bottom"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Drawer
              className="variant-modal"
              anchor={anchor}
              open={state[anchor]}
              onClose={(event) => {
                variantDrawer(anchor, false, event, variant_data);
              }}
              onOpen={(event) => {
                variantDrawer(anchor, true, event, variant_data);
              }}
            >
              <div className="row">
                <div className="sticky_drawer bg-fff">
                  <div className=" d-flex p-2 pt-0 pb-3 p-md-3 align-items-center">
                    <h5 className="mb-0">Variantions</h5>
                    <div className="ms-auto ">
                      <div
                        className="cursor"
                        onClick={(event) => {
                          variantDrawer(anchor, false, event, variant_data);
                        }}
                      >
                        <Ionicons name="close" size={20} color="gray" />
                        {/* <p className="mb-0 ps-1">Close</p> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Variants value={variant_data} /> */}
              </div>
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
