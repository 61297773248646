import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";

import { DataProvider } from "./src/utils/Context/DataContext";
// import Loader from "./src/utils/loader";
import { usePromiseTracker } from "react-promise-tracker";
import { Toaster } from "react-hot-toast";

//css
import "./src/assets/libraries/bootstrap/css/bootstrap.min.css";
import "./src/assets/libraries/fontawesome/css/all.min.css";
import "./src/assets/css/style.css";

// layouts
// import CommonLayout from './src/components/CommonLayout/CommonLayout'
// import RestaurantLayout from './src/Components/RestaurantLayout/RestaurantLayout'
// import GroceryLayout from './src/Components/GroceryLayout/GroceryLayout'
// import EcommerceLayout from './src/Components/EcommerceLayout/EcommerceLayout'

import MainLayout from "./src/components/MainLayout/MainLayout";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import MetaPixel from "./src/utils/Meta/MetaPixel";
import { AppConfig } from "./src/config";
import { FirebaseProvider } from "./src/utils/Context/FirebaseProvider";

// const firebaseConfig = {
//   apiKey: "AIzaSyBsxWj-5Yp00WCS-hWaiQtUmmOl2PqS2sQ",
//   authDomain: "superappmobile-2da9e.firebaseapp.com",
//   projectId: "superappmobile-2da9e",
//   storageBucket: "superappmobile-2da9e.appspot.com",
//   messagingSenderId: "127990175567",
//   appId: "1:127990175567:web:73c5df45d5e31ca293573a",
//   measurementId: "G-VQRHCS0SC5",
// };

loadScript(
  "https://maps.googleapis.com/maps/api/js?key=" +
    AppConfig.google_map_api +
    "&v=weekly&libraries=places,geometry"
);

function loadScript(url: any) {
  var index = window.document.getElementsByTagName("script")[0];
  var script = window.document.createElement("script");
  script.id = "google_map";
  script.src = url;
  // script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

export default function App() {
  
  useEffect(() => {
    if (localStorage.getItem("language_data") == undefined) {
      // localStorage.setItem("language_data", JSON.stringify(en));
    }
    // var importedScript = document.createElement("script");
    // importedScript.id = "google_map";
    // importedScript.src =
    //   "https://maps.googleapis.com/maps/api/js?key=AIzaSyC37EQ5KAX63pqvDFLC5EOSe3znJ0ebFVg&callback=initMap&v=weekly&libraries=places";
    // document.head.appendChild(importedScript);
    // loadScript(
    //   "https://maps.googleapis.com/maps/api/js?key=AIzaSyC37EQ5KAX63pqvDFLC5EOSe3znJ0ebFVg&callback=initMap&v=weekly&libraries=places"
    // );
    let css1: any = document.querySelector("#root > div");
    let css2: any = document.querySelector("#root > div > div");
    css1.removeAttribute("style");
    css2.removeAttribute("style");
    css1.removeAttribute("class");
    css2.removeAttribute("class");

    // if(window.location.hostname == "shop.1superapp.com"){
    // console.log("firebaseConfig :",firebaseConfig)
    // const app = initializeApp(firebaseConfig, "secondary");
    // const analytics = getAnalytics(app);
    // }
    // localStorage.setItem('firebase:debug', 'true');
  }, []);

  return (
    <FirebaseProvider>
      <DataProvider>
        {/* <BrowserRouter>
        <Routes>
          CommonLayout
          <Route path="/" element={<CommonLayout />}>
            <Route index element={<Home />} />
          </Route>
          RestaurantLayout
          <Route path="/restaurant" element={<RestaurantLayout />}>
            <Route index element={<RestaurantDashboard />} />
            <Route
              path="restaurant_shop"
              element={<RestaurantShop have_back={true} />}
            />
          </Route>
          GroceryLayout
          <Route path="/grocery" element={<GroceryLayout />}>
            <Route index element={<Home />} />
          </Route>
          EcommerceLayout
          <Route path="/ecommerce" element={<EcommerceLayout />}>
            <Route index element={<Home />} />
          </Route>

          MainLayout
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route
              path=":section_slug"
              element={<RestaurantDashboard />}
            ></Route>
          </Route>
        </Routes>
      </BrowserRouter> */}
        {/* {AppConfig.environment == "Production" ? ( */}
        {window.location.hostname == "shop.1superapp.com" ? (
          <MetaPixel />
        ) : null}
        {/* ) : null} */}
        <MainLayout promise_tracker={usePromiseTracker} />
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            className: "",
            style: {
              border: "1px solid #171546",
              padding: "10px",
            },
            duration: 2500,
          }}
        />
      </DataProvider>
    </FirebaseProvider>
  );
}
