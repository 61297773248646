import React, { useState, useEffect, useContext } from "react";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import DataContext from "../../../utils/Context/DataContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  makeStyles,
} from "@material-ui/core";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
import { api } from "../../../utils/Api";
import { AppHelper } from "../../../utils/Helper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { AppConfig } from "../../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end", // push the dialog to bottom
    },
  },
  paper: {
    // make the content full width
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      overflow: "scroll",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
      paddingBottom: "45px",
    },
    overflow: "scroll",
    [theme.breakpoints.up("md")]: {
      overflow: "scroll",
      maxWidth: "50%",
      width: "100%",
    },
  },
}));

export default function WishList(props: any) {
  const context = useContext(DataContext);
  const classes = useStyles();
  const [vendor_location_modal, SetVendorLocationModal] = useState({
    open: false,
    value: {},
    index: "",
  });
  const [width, SetWidth] = useState(innerWidth);
  const [location, SetLocation] = useState([
    {
      loc: "Erode",
      active: true,
    },
    { loc: "Pallipalyam", active: false },
    { loc: "Erode 1", active: false },
  ]);
  const [vendors_products, SetVendorsProducts] = useState(false);
  const [fav_heart, SetFavHeart] = useState(true);
  const [org_wishlist_data, SetOrgWishlistData] = useState([]);
  const [outlet_has_products_value, SetOutletHasProductsValue] = useState({});
  const [wishlist_data, SetWishlistData] = useState([]);
  const [page_data, SetPageData] = useState(1);
  const [next_page, SetNextPage] = useState(false);
  const [outlet_context_data, SetOutletContextData]: any = useOutletContext();
  const [api_loaded, SetApiLoaded] = useState(false);

  const responsive = {
    0: { items: 1 },
    200: { items: 2 },
    300: { items: 2 },
    350: { items: 3 },
    548: { items: 4 },
    720: { items: 5 },
    1024: { items: 6 },
  };

  useEffect(() => {
    
    
    // if (props.need_login) {
    //   if (!context.app_data.auth.logged_in) {
    //     // window.location =
    //     //   "https://" +
    //     //   window.location.hostname +
    //     //   "/app_dev/login?r=" +
    //     //   window.location.href;
    //     window.location =
    //     "https://" +
    //     window.location.hostname +
    //     "/app/login?r=" +
    //     window.location.href;
    //   }
    // }
    let send_data = {
      page_no: page_data,
    };
    get_wishlist(send_data, "push_type");
    if (width < 720) {
      SetOutletContextData((prevValue) => {
        prevValue.have_back = props.have_back;
        prevValue.title = "Wishlist";
        prevValue.have_footer = true;
        prevValue.link = {
          rel: "canonical",
          href: "http://mysite.com/example",
        };
        return { ...prevValue };
      });
    } else {
      SetOutletContextData((prevValue) => {
        prevValue.have_back = props.have_back;
        prevValue.title = "Wishlist";
        prevValue.have_footer = false;
        prevValue.link = {
          rel: "canonical",
          href: "http://mysite.com/example",
        };
        return { ...prevValue };
      });
    }
  }, []);

  useEffect(() => {
    
    if (props.need_login) {
      if (context.init_called && !context.app_data.auth.logged_in) {
        // window.location =
        //   "https://" +
        //   window.location.hostname +
        //   "/app_dev/login?r=" +
        //   window.location.href;
        window.location =
          "https://" +
          window.location.hostname +
          "/app/login?r=" +
          window.location.href;
      }
    }
  }, [context.app_data.auth.logged_in]);

  async function get_wishlist(get_data: any, type_value: any) {
    let pass_data = {
      get: {
        page: get_data.page_no,
      },
    };
    let response: any = await api("/wishlist");
    
    // SetSectionsData(response.response.data);
    // if (response.status_code == 200) {
    //   let outlet_has_products_data = {};
    //   outlet_has_products_data = outlet_has_products_value;
    //   if (response.response.hasOwnProperty("outlet_has_products")) {
    //     let data_value = response.response.outlet_has_products;
    //     SetOutletHasProductsValue((prevValue: any) => {
    //       Object.keys(data_value).map((key) => {
    //         prevValue[key] = data_value[key];
    //         outlet_has_products_data[key] = data_value[key];
    //       });
    //       return { ...prevValue };
    //     });
    //   }
    //   if (response.response.hasOwnProperty("data")) {
    //     SetOrgWishlistData((prevValue: any) => {
    //       response.response.data.map((ele: any) => {
    //         prevValue.push(ele);
    //       });
    //       return [...prevValue];
    //     });
    //     let value_data = response.response.data;
    //     value_data.map((data: any) => {
    //       let state_temp = {};
    //       state_temp.type = data.type;
    //       if (data.type == "product") {
    //         state_temp.variant_name = data?.variant_name;
    //         state_temp.product_name = data?.product_name;
    //       } else if (data.type == "seller") {
    //         state_temp.brand_id = data.brand_id;
    //         state_temp.brand_name = data.brand_name;
    //         state_temp.logo_url = data.logo_url;
    //         state_temp.outlets = data.outlets;
    //         state_temp.products = data.products;
    //         state_temp.selected_outlet = data.outlets[0];
    //         state_temp.fav_products = [];
    //         state_temp.other_products = [];
    //         data.products.map((product: any) => {
    //           let products_temp = {};
    //           product.is_loading = false;
    //           products_temp = product;
    //           let current_key = "";
    //           if (
    //             product.hasOwnProperty("product_id") &&
    //             product.hasOwnProperty("variant_id")
    //           ) {
    //             current_key =
    //               state_temp.selected_outlet.outlet_id +
    //               "_" +
    //               product.product_id +
    //               "_" +
    //               product.variant_id;
    //           } else {
    //             if (product.hasOwnProperty("product_id")) {
    //               current_key =
    //                 state_temp.selected_outlet.outlet_id +
    //                 "_" +
    //                 product.product_id +
    //                 "_0";
    //             }
    //             if (product.hasOwnProperty("variant_id")) {
    //               current_key =
    //                 state_temp.selected_outlet.outlet_id +
    //                 "_0_" +
    //                 product.variant_id;
    //             }
    //           }
    //           if (
    //             outlet_has_products_data.hasOwnProperty(current_key) &&
    //             outlet_has_products_data[current_key] == true
    //           ) {
    //             state_temp.fav_products.push(products_temp);
    //           } else {
    //             data.outlets.map((outlet) => {
    //               let current_key = "";
    //               if (
    //                 product.hasOwnProperty("product_id") &&
    //                 product.hasOwnProperty("variant_id")
    //               ) {
    //                 current_key =
    //                   outlet.outlet_id +
    //                   "_" +
    //                   product.product_id +
    //                   "_" +
    //                   product.variant_id;
    //               } else {
    //                 if (product.hasOwnProperty("product_id")) {
    //                   current_key =
    //                     outlet.outlet_id + "_" + product.product_id + "_0";
    //                 }
    //                 if (product.hasOwnProperty("variant_id")) {
    //                   current_key =
    //                     outlet.outlet_id + "_0_" + product.variant_id;
    //                 }
    //               }
    //               if (
    //                 outlet_has_products_data.hasOwnProperty(current_key) &&
    //                 outlet_has_products_data.current_key == true
    //               ) {
    //                 state_temp.other_products.push(products_temp);
    //               }
    //             });
    //           }
    //         });
    //       }
    //       SetWishlistData((prevValue: any) => {
    //         prevValue.push(state_temp);
    //         return [...prevValue];
    //       });
    //     });
    //   }
    //   if (response.response.hasOwnProperty("next_page")) {
    //     SetNextPage(response.response.next_page);
    //   }
    // }
    if (response.status_code == 200) {
      let outlet_has_products_data = {};
      outlet_has_products_data = outlet_has_products_value;
      if (response.response.hasOwnProperty("outlet_has_products")) {
        let data_value = response.response.outlet_has_products;
        SetOutletHasProductsValue((prevValue: any) => {
          Object.keys(data_value)?.map((key) => {
            prevValue[key] = data_value[key];
            outlet_has_products_data[key] = data_value[key];
          });
          return { ...prevValue };
        });
      }
      if (response.response.hasOwnProperty("data")) {
        SetOrgWishlistData((prevValue: any) => {
          response?.response?.data?.map((ele: any) => {
            ele.is_open = false;
            prevValue.push(ele);
          });
          return [...prevValue];
        });
        let value_data = response.response.data;
        let array_value: any = [];
        value_data?.map((data: any) => {
          let state_temp: any = {};
          state_temp.type = data.type;
          if (data.type == "product") {
            state_temp.variant_name = data?.variant_name;
            state_temp.product_name = data?.product_name;
          } else if (data.type == "seller") {
            state_temp.brand_id = data.brand_id;
            state_temp.brand_name = data.brand_name;
            state_temp.logo_url = data.logo_url;
            state_temp.outlets = data.outlets;
            state_temp.products = data.products;
            state_temp.outlet_products = data.outlet_products;
            state_temp.selected_outlet = data.outlets[0];
            state_temp.fav_products = [];
            state_temp.other_products = [];
            data?.products?.map((product: any) => {
              let products_temp = {};
              product.is_loading = false;
              products_temp = product;
              let current_key = "";
              if (
                product.hasOwnProperty("product_id") &&
                product.hasOwnProperty("variant_id")
              ) {
                current_key =
                  state_temp.selected_outlet.outlet_id +
                  "_" +
                  product.product_id +
                  "_" +
                  product.variant_id;
              } else {
                if (product.hasOwnProperty("product_id")) {
                  current_key =
                    state_temp.selected_outlet.outlet_id +
                    "_" +
                    product.product_id +
                    "_0";
                }
                if (product.hasOwnProperty("variant_id")) {
                  current_key =
                    state_temp.selected_outlet.outlet_id +
                    "_0_" +
                    product.variant_id;
                }
              }
              // main
              // if (
              //   outlet_has_products_data.hasOwnProperty(current_key) &&
              //   outlet_has_products_data[current_key] == true
              // ) {
              //   state_temp.fav_products.push(products_temp);
              // } else {
              //   data.outlets.map((outlet) => {
              //     let current_key = "";
              //     if (
              //       product.hasOwnProperty("product_id") &&
              //       product.hasOwnProperty("variant_id")
              //     ) {
              //       current_key =
              //         outlet.outlet_id +
              //         "_" +
              //         product.product_id +
              //         "_" +
              //         product.variant_id;
              //     } else {
              //       if (product.hasOwnProperty("product_id")) {
              //         current_key =
              //           outlet.outlet_id + "_" + product.product_id + "_0";
              //       }
              //       if (product.hasOwnProperty("variant_id")) {
              //         current_key =
              //           outlet.outlet_id + "_0_" + product.variant_id;
              //       }
              //     }
              //     if (
              //       outlet_has_products_data.hasOwnProperty(current_key) &&
              //       outlet_has_products_data.current_key == true
              //     ) {
              //       state_temp.other_products.push(products_temp);
              //     }
              //   });
              // }
              //
              if (
                data.outlet_products.hasOwnProperty(current_key) &&
                data.outlet_products[current_key] == true
              ) {
                state_temp.fav_products.push(products_temp);
              }

              if (
                !data.outlet_products.hasOwnProperty(current_key) &&
                outlet_has_products_data.hasOwnProperty(current_key)
              ) {
                state_temp.other_products.push(products_temp);
              }
            });
          }
          state_temp.is_open = false;
          array_value.push(state_temp);
        });
        SetWishlistData((prevValue: any) => {
          if (type_value == "clear") {
            prevValue = [];
          }
          
          prevValue = array_value;
          return [...prevValue];
        });
      }
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
      }
      SetApiLoaded(true);
    }
  }

  const OutletComponent = (props) => {
    const [selected_outlet, SetSelectedOutlet] = useState(
      props.value.selected_outlet
    );
    return (
      <div className="bg-fff radius-top">
        <div className="d-flex p-3 align-items-center shadow ">
          <h6 className="mb-0">Vendor locations</h6>
          <div className="ms-auto">
            <div
              className="cursor p-0"
              onClick={() => {
                SetVendorLocationModal({ open: false, value: {}, index: "" });
              }}
            >
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "5024605a7929ba6d2eecfcad42c7611a.svg"
                }
                className="w-20px"
              />
              <img src={AppConfig.CDN_Media_url+"f0e8463e68a70f6e4a7ae696c626f8aa.svg"} className="w-20px" />
            </div>
          </div>
        </div>
        <div className="p-3 pb-4 overflowY">
          {/* head */}
          {/* <div className="row align-items-center">
            <div className="col-3">
              <img
                // src={
                //   context.app_data.image_base_url +
                //   context.app_data.image_sizes[0] +
                //   "/" +
                //   props.value.logo_url
                // }
                src={AppHelper.imageSrc(
                  context.app_data.image_base_url,
                  props.value.logo_url,
                  {
                    format: "webp",
                    width: 320,
                  }
                )}
                className="vendor-list"
              />
            </div>
            <div className="col-9">
              <h6>{props.value.brand_name}</h6>
              <p
                className="text-gray v-small fw-500"
                onClick={() => {
                  
                }}
              >
                Select your vendors location
              </p>
            </div>
          </div> */}

          <div className="mt-3">
            <div className="row">
              {props?.value?.outlets?.map((lc, lc_index) => (
                <div className="col-md-4 col-6 mb-2" key={lc_index}>
                  <div
                    className={
                      selected_outlet.outlet_id == lc.outlet_id
                        ? "border-lc-active text-center radius p-2"
                        : "text-center radius p-2 border"
                    }
                    onClick={() => {
                      // SetLocation
                      SetSelectedOutlet(lc);
                    }}
                  >
                    <p
                      className={
                        selected_outlet.outlet_id == lc.outlet_id
                          ? "text-prime1"
                          : "text-gray"
                      }
                    >
                      {lc.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bottom-fix p-3 w-100 text-center">
          <button
            className="btn bg-prime1 radius py-2 px-3 text-white fs-7 fw-bold border-none"
            onClick={() => {
              props.close(selected_outlet);
            }}
          >
            Select location
          </button>
        </div>
      </div>
    );
  };

  function on_outlet_change(get_data: any) {
    
    SetWishlistData((prevValue) => {
      prevValue?.map((data: any, data_index: any) => {
        if (data.type == "seller") {
          if (data_index == get_data.index) {
            data.selected_outlet = get_data.selected_outlet;
            data.fav_products = [];
            data.other_products = [];
            data?.products?.map((product: any) => {
              let products_temp = {};
              product.is_loading = false;
              products_temp = product;
              let current_key = "";
              if (
                product.hasOwnProperty("product_id") &&
                product.hasOwnProperty("variant_id")
              ) {
                current_key =
                  data.selected_outlet.outlet_id +
                  "_" +
                  product.product_id +
                  "_" +
                  product.variant_id;
              } else {
                if (product.hasOwnProperty("product_id")) {
                  current_key =
                    data.selected_outlet.outlet_id +
                    "_" +
                    product.product_id +
                    "_0";
                }
                if (product.hasOwnProperty("variant_id")) {
                  current_key =
                    data.selected_outlet.outlet_id + "_0_" + product.variant_id;
                }
              }
              //
              // if (
              //   outlet_has_products_value.hasOwnProperty(current_key) &&
              //   outlet_has_products_value[current_key] == true
              // ) {
              //   data.fav_products.push(products_temp);
              // } else {
              //   data.outlets.map((outlet) => {
              //     let current_key = "";
              //     if (
              //       product.hasOwnProperty("product_id") &&
              //       product.hasOwnProperty("variant_id")
              //     ) {
              //       current_key =
              //         outlet.outlet_id +
              //         "_" +
              //         product.product_id +
              //         "_" +
              //         product.variant_id;
              //     } else {
              //       if (product.hasOwnProperty("product_id")) {
              //         current_key =
              //           outlet.outlet_id + "_" + product.product_id + "_0";
              //       }
              //       if (product.hasOwnProperty("variant_id")) {
              //         current_key =
              //           outlet.outlet_id + "_0_" + product.variant_id;
              //       }
              //     }
              //     if (
              //       outlet_has_products_value.hasOwnProperty(current_key) &&
              //       outlet_has_products_value.current_key == true
              //     ) {
              //       data.other_products.push(products_temp);
              //     }
              //   });
              // }
              //
              if (
                data.outlet_products.hasOwnProperty(current_key) &&
                data.outlet_products[current_key] == true
              ) {
                data.fav_products.push(products_temp);
              }

              if (
                !data.outlet_products.hasOwnProperty(current_key) &&
                outlet_has_products_value.hasOwnProperty(current_key)
              ) {
                data.other_products.push(products_temp);
              }
            });
          }
        }
      });
      
      return [...prevValue];
    });
  }

  async function removeWishlist(get_data: any, other_data: any) {
    let pass_data = {
      post: get_data,
    };
    let response: any = await api("/add_to_wishlist", pass_data);
    
    if (response.status_code == 200) {
      if (response.response.status) {
        // SetWishlistData((prevalue: any) => {
        //   // prevalue[index].fav_products[
        //   //   li_index
        //   // ].is_loading = true;
        //   prevalue[other_data.index].fav_products.splice(
        //     other_data.li_index,
        //     1
        //   );
        //   return [...prevalue];
        // });

        // SetWishlistData((prevValue) => {
        //   // prevValue[other_data.index].fav_products.splice(
        //   //   other_data.li_index,
        //   //   1
        //   // );
        //   prevValue.map((data: any, data_index: any) => {
        //     if (data.type == "seller") {
        //       if (data_index == get_data.index) {
        //         data.selected_outlet = get_data.selected_outlet;
        //         data.fav_products = [];
        //         data.other_products = [];
        //         data.products.map((product: any) => {
        //           let products_temp = {};
        //           product.is_loading = false;
        //           products_temp = product;
        //           let current_key = "";
        //           if (
        //             product.hasOwnProperty("product_id") &&
        //             product.hasOwnProperty("variant_id")
        //           ) {
        //             current_key =
        //               data.selected_outlet.outlet_id +
        //               "_" +
        //               product.product_id +
        //               "_" +
        //               product.variant_id;
        //           } else {
        //             if (product.hasOwnProperty("product_id")) {
        //               current_key =
        //                 data.selected_outlet.outlet_id +
        //                 "_" +
        //                 product.product_id +
        //                 "_0";
        //             }
        //             if (product.hasOwnProperty("variant_id")) {
        //               current_key =
        //                 data.selected_outlet.outlet_id +
        //                 "_0_" +
        //                 product.variant_id;
        //             }
        //           }
        //           if (
        //             data.outlet_products.hasOwnProperty(current_key) &&
        //             data.outlet_products[current_key] == true
        //           ) {
        //             data.fav_products.push(products_temp);
        //           }

        //           if (
        //             !data.outlet_products.hasOwnProperty(current_key) &&
        //             outlet_has_products_value.hasOwnProperty(current_key)
        //           ) {
        //             data.other_products.push(products_temp);
        //           }
        //         });
        //       }
        //     }
        //   });
        //   
        //   return [...prevValue];
        // });
        let send_data = {
          page_no: page_data,
        };
        get_wishlist(send_data, "clear");
      }
    }
  }

  const Spinner = () => <div className="loader-spinner"></div>;

  const ProductInOutlet = (p_props) => {
    const vendor_ele = p_props.vendor_ele;
    const [key_values, SetKeyValues] = useState([]);
    useEffect(() => {
      // vendor_ele.products.map((p_ele: any) => {
      //   let current_key = "";
      //   if (
      //     p_ele.hasOwnProperty("product_id") &&
      //     p_ele.hasOwnProperty("variant_id")
      //   ) {
      //     current_key =
      //       vendor_ele.selected_outlet.outlet_id +
      //       "_" +
      //       p_ele.product_id +
      //       "_" +
      //       p_ele.variant_id;
      //   } else {
      //     if (product.hasOwnProperty("product_id")) {
      //       current_key =
      //         vendor_ele.selected_outlet.outlet_id +
      //         "_" +
      //         p_ele.product_id +
      //         "_0";
      //     }
      //     if (product.hasOwnProperty("variant_id")) {
      //       current_key =
      //         vendor_ele.selected_outlet.outlet_id + "_0_" + p_ele.variant_id;
      //     }
      //   }
      //   SetKeyValues((prevalue: any) => {
      //     prevalue.push(current_key);
      //   });
      // });
    }, []);

    return <div className=""></div>;
  };

  const OwnAccordian = (acc_props: any) => {
    // const [is_open, SetIsOpen] = useState(false);
    return (
      <div className="card shadow p-2 radius-10">
        <div className="">{acc_props.children}</div>
        {acc_props.is_open && (
          <div className="w-100">
            {/* outlet */}
            <div className="py-3">
              <div className="overflowXScroll-scrollbar d-flex align-items-center">
                {acc_props.data?.outlets?.map(
                  (o_ele: any, o_ele_index: any) => (
                    <div
                      className="px-0"
                      onDragStart={(e) => {
                        e.preventDefault();
                      }}
                      key={o_ele_index}
                    >
                      <div
                        className="px-1"
                        onClick={() => {
                          
                          let send_data = {
                            index: acc_props.index,
                            selected_outlet: o_ele,
                            all_outlet: acc_props.data.outlets,
                          };
                          on_outlet_change(send_data);
                        }}
                      >
                        <p
                          className={
                            acc_props.data.selected_outlet.name == o_ele.name
                              ? "bg-white py-2 px-3 border-bottom-prime1 cursor text-center text-dark fw-bold celias-font"
                              : "cursor py-2 px-3 text-center v-small btn-sm fw-bold celias-font"
                          }
                          // ele.selected_outlet.name
                        >
                          {o_ele.name}
                        </p>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            {acc_props.data.fav_products?.length > 0 ? (
              acc_props.data?.fav_products?.map((list, li_index) => (
                <div key={li_index}>
                  <div className=" pb-3 ">
                    <div
                      className="row align-items-center cursor"
                      onClick={() => {
                        
                      }}
                    >
                      <div className="col-2 p-0">
                        {list.hasOwnProperty("variant_images") ? (
                          list.variant_images?.length > 0 ? (
                            <img
                              src={
                                list.hasOwnProperty("product_name")
                                  ? AppHelper.imageSrc(
                                      context.app_data.image_base_url,
                                      list.product_images[0],
                                      {
                                        format: "webp",
                                        width: 320,
                                      }
                                    )
                                  : AppHelper.imageSrc(
                                      context.app_data.image_base_url,
                                      list.variant_images[0],
                                      {
                                        format: "webp",
                                        width: 320,
                                      }
                                    )
                              }
                              className="wish-img-list"
                            />
                          ) : (
                            <img
                              src={
                                AppConfig.CDN_Image_url +
                                "33c67a26ab58ada3ee31b91f7efb32bb.png"
                              }
                              className="wish-img-list"
                            />
                          )
                        ) : null}
                        {list.hasOwnProperty("product_images") ? (
                          list.product_images?.length > 0 ? (
                            <img
                              src={
                                list.hasOwnProperty("product_name")
                                  ? AppHelper.imageSrc(
                                      context.app_data.image_base_url,
                                      list.product_images[0],
                                      {
                                        format: "webp",
                                        width: 320,
                                      }
                                    )
                                  : AppHelper.imageSrc(
                                      context.app_data.image_base_url,
                                      list.variant_images[0],
                                      {
                                        format: "webp",
                                        width: 320,
                                      }
                                    )
                              }
                              className="wish-img-list"
                            />
                          ) : (
                            <img
                              src={
                                AppConfig.CDN_Image_url +
                                "33c67a26ab58ada3ee31b91f7efb32bb.png"
                              }
                              className="wish-img-list"
                            />
                          )
                        ) : null}
                      </div>
                      <div className="col-9">
                        <p className="pt-1 oneline fw-500 mb-1 text-black celias-font">
                          {list.hasOwnProperty("product_name")
                            ? list.product_name
                            : list.variant_name}
                        </p>
                      </div>

                      <div className="col-1 text-center p-0 cursor">
                        <div
                          onClick={() => {
                           
                            SetWishlistData((prevalue: any) => {
                              prevalue[acc_props.index].fav_products[
                                li_index
                              ].is_loading = true;
                              return [...prevalue];
                            });
                            let pass_data = {
                              brand_id: acc_props.data.brand_id,
                              outlet_id:
                                acc_props.data.selected_outlet.outlet_id,
                              checked: 0,
                            };
                            if (list.hasOwnProperty("product_name")) {
                              pass_data.product_id = list.product_id;
                            } else {
                              pass_data.variant_id = list.variant_id;
                            }
                            let other_data = {
                              ele: acc_props.data,
                              index: acc_props.index,
                              list: list,
                              li_index: li_index,
                            };
                            removeWishlist(pass_data, other_data);
                          }}
                        >
                          {list.is_loading ? (
                            <Spinner />
                          ) : (
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "40c421836459cc569e228209956cfb7d.svg"
                              }
                              className="w-20"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h6 className="center p-3 bg-fff">No Favorite Product </h6>
            )}
            <div className="border-top pt-3">
              <p className="pt-2 fs-7 text-dark celias-font">
                Products in Other Outlet
              </p>
              {acc_props.data.other_products?.length > 0 ? (
                acc_props.data?.other_products?.map((list, li_index) => (
                  <div key={li_index}>
                    <div className="row pb-2">
                      <div className="col-3 col-md-3 p-0">
                        <img
                          // src={
                          //   context.app_data.image_base_url +
                          //   context.app_data.image_sizes[0] +
                          //   "/" +
                          //   list.product_images[0]
                          // }
                          src={
                            list.hasOwnProperty("product_name")
                              ? AppHelper.imageSrc(
                                  context.app_data.image_base_url,
                                  list.product_images[0],
                                  {
                                    format: "webp",
                                    width: 320,
                                  }
                                )
                              : AppHelper.imageSrc(
                                  context.app_data.image_base_url,
                                  list.variant_images[0],
                                  {
                                    format: "webp",
                                    width: 320,
                                  }
                                )
                          }
                          className="fav-img-list"
                        />
                      </div>
                      <div className="col-8 col-md-8">
                        <p className="pt-1 oneline fw-500 mb-1 text-black">
                          {list.hasOwnProperty("product_name")
                            ? list.product_name
                            : list.variant_name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center m-3">----------</p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className=" pb-3  bg-body-color mb-5">
      {/* header */}
      <div className="px-2 py-3 bg-white mb-1 fixed-search-bar custom-container  r-0">
        <h5 className="mb-0">My Wishlist</h5>
      </div>

      {/* body */}
      <div className="custom-container">
      <div className="row py-5">
        {/* hided */}
        <div className="">
          {/* {product_show.map((product_data, index) => (
                <div className="col-md-6 mb-3" key={index}>
                  <div className="card shadow p-2">
                    <div className="row">
                      <div className="col-4 col-md-3 p-0">
                        <img src={product_data.img_path} className="card-img-list" />
                        <div className="bottom-offer">
                          <div className="offer cursor p-2">
                            <p className="v-small text-prime1 fw-bold">Add to cart</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-7 col-md-8">
                        <Link to="/ecommerce/ecommerce_mobile_product_details">
                          <h6 className="pt-1 oneline fw-500">{product_data.name}</h6>
                          <p className="text-gray fw-500 v-small mt-2">
                            {product_data.description}
                          </p>
                          <div className="d-flex align-items-center mt-2">
                            <p className="fs-7 fw-bold text-black ">
                              ₹{product_data.offer_price}
                            </p>
                            <p className="ms-2 fs-7 text-gray">.</p>
                            <p className="ms-2 text-orange fs-7 fw-bold">4★</p>
                          </div>
                        </Link>
                      </div>

                      <div className="col-1 col-md-1 text-right p-0">
                        {fav_heart ? (
                          <div
                            onClick={() => {
                              if (fav_heart == true) {
                                SetFavHeart(false);
                              }
                            }}
                          >
                            <Ionicons
                              name="ios-heart"
                              size={22}
                              color="rgb(255, 0, 48)"
                            />
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              if (fav_heart == false) {
                                SetFavHeart(true);
                              }
                            }}
                          >
                            <Ionicons name="heart-outline" size={22} color="#333" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  // hide start{" "}
                  <div className="card p-2 border-bottom">
                    <div className="row">
                      <div className="col-3 col-md-2">
                        <img src={product_data.img_path} className="card-img-list" />
                      </div>
                      <div className="col-8 col-md-8">
                        <div className="p-1">
                          <p className="marg">{product_data.name}</p>
                          <div className="flex">
                            <h6>₹{product_data.offer_price}</h6>
                            <p className="pl-1">
                              <s>₹{product_data.original_price}</s>
                            </p>
                          </div>
                          <p className="save">
                            You have saves ₹{product_data.discount_price}
                          </p>
                        </div>
                      </div>
                      <div className="col-1 col-md-1 text-right">
                        <MaterialIcons name="delete-outline" size={20} color="#333" />
                      </div>
                    </div>
                  </div>
                  hide end //
                </div>
              ))} */}

          {/* {vendors_data.map((vendors, vendor_index) => (
                <div className="col-md-6 mb-3" key={vendor_index}>
                  <div
                    className="card shadow p-2 cursor"
                    onClick={() => {
                      SetVendorLocationModal({ open: true, value: vendors });
                      
                      
                    }}
                  >
                    <div className="row">
                      <div className="col-3 col-md-3 p-0">
                        <img src={vendors.src} className="card-img-list" />
                      </div>
                      <div className="col-8 col-md-8">
                        <h6 className="pt-1 oneline fw-500 mb-1 ">{vendors.name}</h6>
                        <div className="d-flex align-items-center mb-1">
                          <Ionicons name="location-outline" size={15} color="black" />
                          <p className="text-black fs-7 ms-1">Erode</p>
                        </div>
                        <p className="text-gray v-small">
                          North indian & South Indian cusine
                        </p>
                        <div className="d-flex align-items-center mt-2">
                          <div className="d-flex align-items-center">
                            <MaterialIcons
                              name="delivery-dining"
                              size={15}
                              color="black"
                            />
                            <p className="ms-2 text-dark">{vendors.deliveryTime}</p>
                          </div>
                          <p className="ms-2 fs-7 text-gray">.</p>
                          <p className="ms-2 text-orange fs-7 fw-bold">4★</p>
                        </div>
                      </div>
                      <div className="col-1 col-md-1 text-right p-0 cursor">
                        {fav_heart ? (
                          <div
                            onClick={() => {
                              if (fav_heart == true) {
                                SetFavHeart(false);
                              }
                            }}
                          >
                            <Ionicons
                              name="ios-heart"
                              size={22}
                              color="rgb(255, 0, 48)"
                            />
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              if (fav_heart == false) {
                                SetFavHeart(true);
                              }
                            }}
                          >
                            <Ionicons name="heart-outline" size={22} color="#333" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))} */}

          {/* {shop.map((details, details_index) => (
                <div className="col-md-6 mb-3" key={details_index}>
                  <div className="card shadow p-2">
                    <Accordion>
                      <div className="row">
                        <div className="col-3 col-md-3 p-0">
                          <img src={details.src} className="wish-img-list" />
                        </div>
                        <div className="col-9 summary-content">
                          <AccordionSummary
                            className="my-0 "
                            expandIcon={
                              <MaterialIcons
                                name="keyboard-arrow-down"
                                size={18}
                                color="black"
                              />
                            }
                          >
                            <div>
                              <h6 className="pt-1 oneline fw-500">{details.name}</h6>
                              <p className="text-gray v-small oneline">
                                North indian & South Indian cusine
                              </p>
                            </div>
                          </AccordionSummary>
                          <div className="d-flex align-items-center pt-2">
                            <div
                              className="d-flex align-items-center cursor"
                              onClick={() => {
                                SetVendorLocationModal({
                                  open: true,
                                  value: details,
                                });
                   
                              }}
                            >
                              <Ionicons
                                name="location-outline"
                                size={15}
                                color="black"
                              />
                              <p className="text-black fs-7 ms-1">Erode</p>
                            </div>
                            <div className="d-flex align-items-center ms-auto">
                              <p className="ms-2 text-orange fs-7 fw-bold">4★</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <AccordionDetails className="p-0 border-top mt-2 pt-2">
                        <div className="w-100">
                          {details.foodList.map((list, li_index) => (
                            <div key={li_index}>
                              <div className="row pb-2">
                                <div className="col-3 col-md-3 p-0">
                                  <img src={list.img_path} className="fav-img-list" />
                                </div>
                                <div className="col-8 col-md-8">
                                  <p className="pt-1 oneline fw-500 mb-1 text-black">
                                    {list.name}
                                  </p>
                                  <p className="fs-7 fw-bold text-black ">
                                    ₹{list.price}
                                  </p>
                                </div>
                                <div className="col-1 col-md-1 text-right p-0 cursor">
                                  {fav_heart ? (
                                    <div
                                      onClick={() => {
                                        if (fav_heart == true) {
                                          SetFavHeart(false);
                                        }
                                      }}
                                    >
                                      <Ionicons
                                        name="ios-heart"
                                        size={22}
                                        color="rgb(255, 0, 48)"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => {
                                        if (fav_heart == false) {
                                          SetFavHeart(true);
                                        }
                                      }}
                                    >
                                      <Ionicons
                                        name="heart-outline"
                                        size={22}
                                        color="#333"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              ))} */}
        </div>
        {api_loaded ? (
          wishlist_data?.length > 0 ? (
            wishlist_data?.map((ele: any, index: any) => (
              <div className="col-12 px-sm-2 px-md-2 pt-3" key={index}>
                {ele.type == "seller" ? (
                  <>
                    {/* old accordien hided */}
                    <div className="">
                      {/* <div className="card shadow p-2">
                        <Accordion>
                          <div className="row">
                            //image
                            <div className="col-2 p-0">
                              {ele.logo_url ? (
                                <img
                                  // src={
                                  //   context.app_data.image_base_url +
                                  //   context.app_data.image_sizes[0] +
                                  //   "/" +
                                  //   ele.logo_url
                                  // }
                                  src={AppHelper.imageSrc(
                                    context.app_data.image_base_url,
                                    ele.logo_url,
                                    {
                                      format: "webp",
                                      width: 320,
                                    }
                                  )}
                                  className="wish-img-list"
                                />
                              ) : (
                                <img
                                  src={require("../../../assets/img/dhosa.jpg")}
                                  className="wish-img-list"
                                  // className="near-img"
                                />
                              )}
                            </div>
                            <div className="col-9 summary-content">
                              <p className="oneline celias-font text-dark fs-7">
                                {ele.brand_name}
                              </p>
                              <p className="text-gray v-small oneline">
                                North indian & South Indian cusine
                              </p>
                              <div className="ms-auto cursor">
                                <MaterialCommunityIcons
                                  name="arrow-right-drop-circle-outline"
                                  size={18}
                                  color="gray"
                                />
                              </div>

                              <div
                                className="d-flex align-items-center cursor"
                                onClick={() => {
                                  SetVendorLocationModal({
                                    open: true,
                                    value: ele,
                                    index: index,
                                  });
                                }}
                              >
                                <Ionicons
                                  name="location-outline"
                                  size={15}
                                  color="gray"
                                />
                                <p className="text-darkgray fs-7 ms-1">
                                  {ele.selected_outlet.name}
                                </p>
                              </div>

                              //<div
                              //    className="d-flex align-items-center cursor"
                              //    onClick={() => {
                                    // SetVendorLocationModal({
                                    //   open: true,
                                    //   value: ele,
                                    //   index: index,
                                    // });
                              //    }}
                              //  >
                              //    <p className="text-darkgray fs-7 ms-1">See More</p>
                              //  </div>
                              //  <div className="d-flex align-items-center ms-auto">
                              //    <p className="ms-2 text-orange fs-7 fw-bold">4★</p>
                              //  </div>
                              //</div>
                            </div>
                            <div className="col-1 p-0 cursor wishlist-accordion">
                              <AccordionSummary
                                className="m-0 h-auto"
                                expandIcon={
                                  <MaterialIcons
                                    name="keyboard-arrow-down"
                                    size={20}
                                    color="black"
                                  />
                                }
                              >
                                <div className="d-flex align-items-center w-100">
                                  <p className="text-orange fs-7 fw-bold w-75">4★</p>
                                </div>
                              </AccordionSummary>
                            </div>
                          </div>

                          <AccordionDetails className="p-0 border-top mt-2 pt-2">
                            <div className="w-100">
                              // outlet
                              <div className="pb-3 pt-2">
                                <div className="overflowXScroll-scrollbar d-flex align-items-center">
                                  {ele?.outlets?.map(
                                    (o_ele: any, o_ele_index: any) => (
                                      <div
                                        className="px-0"
                                        onDragStart={(e) => {
                                          e.preventDefault();
                                        }}
                                        key={o_ele_index}
                                      >
                                        <div
                                          className="px-1"
                                          onClick={() => {
                                       
                                            let send_data = {
                                              index: index,
                                              selected_outlet: o_ele,
                                              all_outlet: ele.outlets,
                                            };
                                            on_outlet_change(send_data);
                                          }}
                                        >
                                          <p
                                            className={
                                              ele.selected_outlet.name ==
                                              o_ele.name
                                                ? "bg-prime1 py-2 px-3  cursor text-center text-white fw-bold btn-sm btn-text celias-font"
                                                : "cursor py-2 px-3 text-center v-small btn-sm fw-bold celias-font"
                                            }
                                            // ele.selected_outlet.name
                                          >
                                            {o_ele.name}
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              {ele.fav_products?.length > 0 ? (
                                ele?.fav_products?.map((list, li_index) => (
                                  <div key={li_index}>
                                    <div className="row pb-3">
                                      <div className="col-2 p-0">
                                        {list.product_images?.length > 0 ? (
                                          <img
                                            // src={
                                            //   context.app_data.image_base_url +
                                            //   context.app_data.image_sizes[0] +
                                            //   "/" +
                                            //   list.product_images[0]
                                            // }
                                            src={
                                              list.hasOwnProperty("product_name")
                                                ? AppHelper.imageSrc(
                                                    context.app_data
                                                      .image_base_url,
                                                    list.product_images[0],
                                                    {
                                                      format: "webp",
                                                      width: 320,
                                                    }
                                                  )
                                                : AppHelper.imageSrc(
                                                    context.app_data
                                                      .image_base_url,
                                                    list.variant_images[0],
                                                    {
                                                      format: "webp",
                                                      width: 320,
                                                    }
                                                  )
                                            }
                                            className="wish-img-list"
                                          />
                                        ) : (
                                          <img
                                            src={require("../../../assets/icon/food(1).png")}
                                            className="wish-img-list"
                                          />
                                        )}
                                      </div>
                                      <div className="col-9">
                                        <p className="pt-1 oneline fw-500 mb-1 text-black celias-font">
                                          {list.hasOwnProperty("product_name")
                                            ? list.product_name
                                            : list.variant_name}
                                        </p>
                                      </div>
                                      <div className="col-1 text-right p-0 cursor">
                                        <div
                                          onClick={() => {
                                            
                                            SetWishlistData((prevalue: any) => {
                                              prevalue[index].fav_products[
                                                li_index
                                              ].is_loading = true;
                                              return [...prevalue];
                                            });
                                            let pass_data = {
                                              brand_id: ele.brand_id,
                                              outlet_id:
                                                ele.selected_outlet.outlet_id,
                                              checked: 0,
                                            };
                                            if (
                                              list.hasOwnProperty("product_name")
                                            ) {
                                              pass_data.product_id =
                                                list.product_id;
                                            } else {
                                              pass_data.variant_id =
                                                list.variant_id;
                                            }
                                            let other_data = {
                                              ele: ele,
                                              index: index,
                                              list: list,
                                              li_index: li_index,
                                            };
                                            removeWishlist(pass_data, other_data);
                                          }}
                                        >
                                          {list.is_loading ? (
                                            <Spinner />
                                          ) : (
                                            <Ionicons
                                              name="ios-heart"
                                              size={22}
                                              color="rgb(255, 0, 48)"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <h6 className="center p-3 bg-fff">
                                  No Favorite Product{" "}
                                </h6>
                              )}

                              //<div className="">
                              //  <ProductInOutlet vendor_data={ele} />
                              //</div>
                              <div className="border-top pt-3">
                                <p className="pt-2 fs-7 text-dark celias-font">
                                  Products in Other Outlet
                                </p>
                                {ele.other_products?.length > 0 ? (
                                  ele?.other_products?.map((list, li_index) => (
                                    <div key={li_index}>
                                      <div className="row pb-2">
                                        <div className="col-3 col-md-3 p-0">
                                          <img
                                            // src={
                                            //   context.app_data.image_base_url +
                                            //   context.app_data.image_sizes[0] +
                                            //   "/" +
                                            //   list.product_images[0]
                                            // }
                                            src={
                                              list.hasOwnProperty("product_name")
                                                ? AppHelper.imageSrc(
                                                    context.app_data
                                                      .image_base_url,
                                                    list.product_images[0],
                                                    {
                                                      format: "webp",
                                                      width: 320,
                                                    }
                                                  )
                                                : AppHelper.imageSrc(
                                                    context.app_data
                                                      .image_base_url,
                                                    list.variant_images[0],
                                                    {
                                                      format: "webp",
                                                      width: 320,
                                                    }
                                                  )
                                            }
                                            className="fav-img-list"
                                          />
                                        </div>
                                        <div className="col-8 col-md-8">
                                          <p className="pt-1 oneline fw-500 mb-1 text-black">
                                            {list.hasOwnProperty("product_name")
                                              ? list.product_name
                                              : list.variant_name}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <p className="text-center m-3">----------</p>
                                )}
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div> */}
                    </div>
                    <div className="">
                      <OwnAccordian
                        is_open={ele.is_open}
                        data={ele}
                        index={index}
                      >
                        <div
                          className="row cursor align-items-center"
                          onClick={() => {
                            SetWishlistData((prevValue: any) => {
                              prevValue[index].is_open =
                                !prevValue[index].is_open;
                              return [...prevValue];
                            });
                          }}
                        >
                          <div className="col-2 p-0">
                            {ele.logo_url ? (
                              <img
                                // src={
                                //   context.app_data.image_base_url +
                                //   context.app_data.image_sizes[0] +
                                //   "/" +
                                //   ele.logo_url
                                // }
                                src={AppHelper.imageSrc(
                                  context.app_data.image_base_url,
                                  ele.logo_url,
                                  {
                                    format: "webp",
                                    width: 320,
                                  }
                                )}
                                className="wish-img-list"
                              />
                            ) : (
                              <img
                                src={require("../../../assets/img/dhosa.jpg")}
                                className="wish-img-list"
                                // className="near-img"
                              />
                            )}
                          </div>
                          <div className="col-9 summary-content">
                            <p className="oneline celias-font text-dark fs-7">
                              {ele.brand_name}
                            </p>
                            {/* <p className="text-gray v-small oneline">
                                  North indian & South Indian cusine
                                </p> */}
                            {/* <div className="ms-auto cursor">
                                <MaterialCommunityIcons
                                  name="arrow-right-drop-circle-outline"
                                  size={18}
                                  color="gray"
                                />
                              </div> */}

                            {/* <div
                                className="d-flex align-items-center cursor"
                                onClick={() => {
                                  SetVendorLocationModal({
                                    open: true,
                                    value: ele,
                                    index: index,
                                  });
                                }}
                              >
                                <Ionicons
                                  name="location-outline"
                                  size={15}
                                  color="gray"
                                />
                                <p className="text-darkgray fs-7 ms-1">
                                  {ele.selected_outlet.name}
                                </p>
                              </div> */}

                            {/* <div
                                className="d-flex align-items-center cursor"
                                onClick={() => {
                                  // SetVendorLocationModal({
                                  //   open: true,
                                  //   value: ele,
                                  //   index: index,
                                  // });
                                }}
                              >
                                <p className="text-darkgray fs-7 ms-1">See More</p>
                              </div>
                              <div className="d-flex align-items-center ms-auto">
                                <p className="ms-2 text-orange fs-7 fw-bold">4★</p>
                              </div>
                            </div> */}
                          </div>
                          <div className="col-1 p-0  wishlist-accordion text-center">
                            {ele.is_open ? (
                              <img
                                src={
                                  AppConfig.CDN_Media_url +
                                  "5fe2c4fb42bedd7c0eb6cbefa6e31d9c.svg"
                                }
                                className="tied-icon"
                              />
                            ) : (
                              <img
                                src={
                                  AppConfig.CDN_Media_url +
                                  "c9e4782b3357c99617ab27e0fbcfd8d0.svg"
                                }
                                className="tied-icon"
                              />
                            )}

                            <div className="d-flex align-items-center w-100">
                              {/* <p className="text-orange fs-7 fw-bold w-75">4★</p> */}
                            </div>
                          </div>
                        </div>
                      </OwnAccordian>
                    </div>
                  </>
                ) : null}
              </div>
            ))
          ) : (
            <h6 className="center p-3">No Favorite Product </h6>
          )
        ) : (
          [1, 2, 3, 4].map((ele) => (
            <div className="col-12 my-3">
              <div className="row bg-fff p-2">
                <div className="col-3 col-md-3 p-0">
                  <Skeleton height={50} />
                </div>
                <div className="col-9 summary-content">
                  <Skeleton width={30} height={10} />
                  <Skeleton height={10} />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      </div>
      {width < 720 ? (
        <SwipeableBottomSheet
          overflowHeight={0}
          style={{ zIndex: 1300 }}
          open={vendor_location_modal.open}
          onClose={() => {
            SetVendorLocationModal({ open: false, value: {}, index: "" });
          }}
          onChange={() => {
            SetVendorLocationModal({ open: false, value: {}, index: "" });
          }}
        >
          <div
            className="bg-fff radius-top"
            style={{ minHeight: "400px", maxHeight: "auto" }}
          >
            <div className="center drawer-fixed">
              <hr className="hr-drawer" />
            </div>
            <OutletComponent
              value={vendor_location_modal.value}
              close={(get_value: any) => {
                
                SetWishlistData((prevValue) => {
                  prevValue?.map((data: any, data_index: any) => {
                    if (data.type == "seller") {
                      if (data_index == vendor_location_modal.index) {
                        data.selected_outlet = get_value;
                        data.fav_products = [];
                        data.other_products = [];
                        data?.products?.map((product: any) => {
                          let products_temp = {};
                          product.is_loading = false;
                          products_temp = product;
                          let current_key = "";
                          if (
                            product.hasOwnProperty("product_id") &&
                            product.hasOwnProperty("variant_id")
                          ) {
                            current_key =
                              data.selected_outlet.outlet_id +
                              "_" +
                              product.product_id +
                              "_" +
                              product.variant_id;
                          } else {
                            if (product.hasOwnProperty("product_id")) {
                              current_key =
                                data.selected_outlet.outlet_id +
                                "_" +
                                product.product_id +
                                "_0";
                            }
                            if (product.hasOwnProperty("variant_id")) {
                              current_key =
                                data.selected_outlet.outlet_id +
                                "_0_" +
                                product.variant_id;
                            }
                          }
                          if (
                            outlet_has_products_value.hasOwnProperty(
                              current_key
                            ) &&
                            outlet_has_products_value[current_key] == true
                          ) {
                            data.fav_products.push(products_temp);
                          } else {
                            data?.outlets?.map((outlet) => {
                              let current_key = "";
                              if (
                                product.hasOwnProperty("product_id") &&
                                product.hasOwnProperty("variant_id")
                              ) {
                                current_key =
                                  outlet.outlet_id +
                                  "_" +
                                  product.product_id +
                                  "_" +
                                  product.variant_id;
                              } else {
                                if (product.hasOwnProperty("product_id")) {
                                  current_key =
                                    outlet.outlet_id +
                                    "_" +
                                    product.product_id +
                                    "_0";
                                }
                                if (product.hasOwnProperty("variant_id")) {
                                  current_key =
                                    outlet.outlet_id +
                                    "_0_" +
                                    product.variant_id;
                                }
                              }
                              if (
                                outlet_has_products_value.hasOwnProperty(
                                  current_key
                                ) &&
                                outlet_has_products_value.current_key == true
                              ) {
                                data.other_products.push(products_temp);
                              }
                            });
                          }
                        });
                      }
                    }
                  });
                  return [...prevValue];
                });
                SetVendorLocationModal({ open: false, value: {}, index: "" });
              }}
            />
          </div>
        </SwipeableBottomSheet>
      ) : (
        <Dialog
          open={vendor_location_modal.open}
          onClose={() => {
            SetVendorLocationModal({ open: false, value: {}, index: "" });
          }}
          classes={{ container: classes.root, paper: classes.paper }}
        >
          <OutletComponent
            value={vendor_location_modal.value}
            close={(get_value: any) => {
              SetWishlistData((prevValue) => {
                prevValue?.map((data: any, data_index: any) => {
                  if (data.type == "seller") {
                    if (data_index == vendor_location_modal.index) {
                      data.selected_outlet = get_value;
                      data.fav_products = [];
                      data.other_products = [];
                      data?.products?.map((product: any) => {
                        let products_temp = {};
                        product.is_loading = false;
                        products_temp = product;
                        let current_key = "";
                        if (
                          product.hasOwnProperty("product_id") &&
                          product.hasOwnProperty("variant_id")
                        ) {
                          current_key =
                            data.selected_outlet.outlet_id +
                            "_" +
                            product.product_id +
                            "_" +
                            product.variant_id;
                        } else {
                          if (product.hasOwnProperty("product_id")) {
                            current_key =
                              data.selected_outlet.outlet_id +
                              "_" +
                              product.product_id +
                              "_0";
                          }
                          if (product.hasOwnProperty("variant_id")) {
                            current_key =
                              data.selected_outlet.outlet_id +
                              "_0_" +
                              product.variant_id;
                          }
                        }
                        if (
                          outlet_has_products_value.hasOwnProperty(
                            current_key
                          ) &&
                          outlet_has_products_value[current_key] == true
                        ) {
                          data.fav_products.push(products_temp);
                        } else {
                          data?.outlets?.map((outlet) => {
                            let current_key = "";
                            if (
                              product.hasOwnProperty("product_id") &&
                              product.hasOwnProperty("variant_id")
                            ) {
                              current_key =
                                outlet.outlet_id +
                                "_" +
                                product.product_id +
                                "_" +
                                product.variant_id;
                            } else {
                              if (product.hasOwnProperty("product_id")) {
                                current_key =
                                  outlet.outlet_id +
                                  "_" +
                                  product.product_id +
                                  "_0";
                              }
                              if (product.hasOwnProperty("variant_id")) {
                                current_key =
                                  outlet.outlet_id + "_0_" + product.variant_id;
                              }
                            }
                            if (
                              outlet_has_products_value.hasOwnProperty(
                                current_key
                              ) &&
                              outlet_has_products_value.current_key == true
                            ) {
                              data.other_products.push(products_temp);
                            }
                          });
                        }
                      });
                    }
                  }
                });
                return [...prevValue];
              });
              SetVendorLocationModal({ open: false, value: {}, index: "" });
            }}
          />
        </Dialog>
      )}
    </div>
  );
}
