import React, {
  Component,
  useEffect,
  useContext,
  useState,
  useRef,
} from "react";
import {
  Link,
  useParams,
  useSearchParams,
  useOutletContext,
  useNavigate,
} from "react-router-dom";
import DataContext from "../../../utils/Context/DataContext";
import {
  Feather,
  FontAwesome,
  Entypo,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  AntDesign,
  SimpleLineIcons,
} from "@expo/vector-icons";
import {
  AccordionDetails,
  Drawer,
  Dialog,
  Badge,
  makeStyles,
  Modal,
  Slider,
} from "@material-ui/core";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { api } from "../../../utils/Api";
import { Waypoint } from "react-waypoint";
import VisibilitySensor from "react-visibility-sensor";
// import handleViewport from "react-in-viewport";
import { useInView, InView } from "react-cool-inview";
import { AppHelper } from "../../../utils/Helper";
import ImageContainer from "../../../components/ImageContainer/ImageContainer";
import { AppConfig } from "../../../config";

import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import Cookies from "js-cookie";
import GroceryCardComponent from "../../../components/GroceryCardComponent/GroceryCardComponent";

import MultiRangeSlider from "multi-range-slider-react";

import ResturantCardComponent from "../../../components/ResturantCardComponent/ResturantCardComponent";

const nodes = [
  {
    value: "mars",
    label: "Mars",
    children: [
      { value: "phobos", label: "Phobos" },
      { value: "deimos", label: "Deimos" },
    ],
  },
];

const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
};

const handleDragStart = (e: any) => e.preventDefault();
const discountresponsive = {
  0: { items: 1 },
  300: { items: 1 },
  350: { items: 1 },
  548: { items: 1 },
  720: { items: 2 },
  912: { items: 2 },
  1024: { items: 4 },
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end", // push the dialog to bottom
    },
  },
  paper: {
    // make the content full width
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      height: "100%",
      overflow: "scroll",
      paddingBottom: "45px",
    },
    overflow: "scroll",
  },
}));

const foodresponsive = {
  0: { items: 1 },
  300: { items: 1 },
  350: { items: 1 },
  548: { items: 2 },
  720: { items: 2 },
  912: { items: 3 },
  1024: { items: 3 },
};

const productresponsive = {
  0: { items: 1 },
  200: { items: 1 },
  300: { items: 2 },
  350: { items: 2 },
  548: { items: 3 },
  720: { items: 4 },
  1024: { items: 6 },
};

export default function RestaurantSearch(props: any) {
  const navigate = useNavigate();
  const context: any = useContext(DataContext);
  const [id_value, SetIdValue] = useState(useParams());
  const [url_params, SetUrlParams] = useSearchParams();
  const classes = useStyles();
  // const [main, SetMain] = useState([
  //   {
  //     img: require("../../../assets/img/dhosa.jpg"),
  //     name: "A2B Veg",
  //     type: "Restaurant",
  //   },
  //   {
  //     img: require("../../../assets/img/food8.jpg"),
  //     name: "Dindigul Thalappakadi Biriyani",
  //     type: "Dish",
  //   },
  //   {
  //     img: require("../../../assets/img/north.jpeg"),
  //     name: "Erode Punjabi",
  //     type: "Restaurant",
  //   },
  //   {
  //     img: require("../../../assets/img/food4.jpg"),
  //     name: "Rowthar Biriyani Center",
  //     type: "Restaurant",
  //   },
  //   {
  //     img: require("../../../assets/img/food7.jpeg"),
  //     name: "Pizza",
  //     type: "Dish",
  //   },
  //   {
  //     img: require("../../../assets/img/c65.jpg"),
  //     name: "Chicken fry",
  //     type: "Dish",
  //   },
  //   {
  //     img: require("../../../assets/img/images.jpeg"),
  //     name: "Saravana Bhavan",
  //     type: "Restruant",
  //   },
  // ]);
  // const [shop, SetShop] = useState({
  //   menu: [
  //     {
  //       foodList: [
  //         {
  //           img_path: require("../../../assets/img/dhosa.jpg"),
  //           name: "Dosa",
  //           description:
  //             "A dosa is a thin pancake or crepe originating from South India, made from a fermented batter predominantly consisting of lentils and rice.",
  //           type: "Veg",
  //           price: 30,
  //           addcount: 0,
  //           addbtn: false,
  //           variants: false,
  //           snackopen: false,
  //           addbtn: false,
  //           addCount: 0,
  //         },
  //         {
  //           img_path: require("../../../assets/img/food4.jpg"),
  //           name: "Biryani",
  //           description:
  //             "Biryani is a spiced mix of meat and rice, traditionally cooked over an open fire in a leather pot.",
  //           type: "Nonveg",
  //           price: 350,
  //           addcount: 0,
  //           addbtn: false,
  //           variants: false,
  //           addons: "",
  //           addbtn: false,
  //           addCount: 0,
  //         },
  //         {
  //           img_path: require("../../../assets/img/food5.jpg"),
  //           name: "Chicken Gravy",
  //           description:
  //             "Chicken chettinad gravy recipe - One of the most popular dishes from the chettinad cuisine.",
  //           type: "Nonveg",
  //           price: 150,
  //           addcount: 0,
  //           addbtn: false,
  //           variants: false,
  //           addbtn: false,
  //           addCount: 0,
  //         },
  //         {
  //           img_path: require("../../../assets/img/food6.jpg"),
  //           name: "Samosa (2 Pieces)",
  //           description:
  //             "A samosa is a South Asian fried or baked pastry with a savory filling like spiced potatoes, onions.",
  //           type: "Veg",
  //           price: 40,
  //           addcount: 0,
  //           addbtn: false,
  //           variants: false,
  //           addbtn: false,
  //           addCount: 0,
  //         },
  //         {
  //           img_path: require("../../../assets/img/category/pizza.png"),
  //           name: "Pizza",
  //           description:
  //             "pizza, dish of Italian origin consisting of a flattened disk of bread dough topped with some combination of olive oil, oregano, tomato, olives, mozzarella or other cheese, and many other ingredients.",
  //           type: "Veg",
  //           price: 140,
  //           addcount: 0,
  //           addbtn: false,
  //           variants: true,

  //           addbtn: false,
  //           addCount: 0,
  //         },
  //         {
  //           img_path: require("../../../assets/img/images.jpeg"),
  //           name: "Masala Dosa",
  //           description:
  //             " Masala dosa is a variation of the popular South Indian Food, made from a fermented batter predominantly consisting of lentils and rice.",
  //           type: "Veg",
  //           price: 50,
  //           addcount: 0,
  //           addbtn: false,
  //           variants: false,
  //           addbtn: false,
  //           addCount: 0,
  //         },
  //       ],
  //     },
  //   ],
  // });
  const [variants_size, SetVariantsSize] = useState([
    {
      item: "Small",
      price: 250,
    },
    {
      item: "Medium",
      price: 300,
    },
  ]);
  const [addons, SetAddons] = useState([
    {
      title: "toppings",
      child: [
        {
          name: "Cheese",
          price: 20,
          addbtn: false,
          addcount: 0,
        },
        {
          name: "Onion",
          price: 25,
          addbtn: false,
          addcount: 0,
        },

        {
          name: "Mushroom",
          price: 105,
          addbtn: false,
          addcount: 0,
        },
      ],
    },
    {
      title: "Addons",
      child: [
        {
          name: "Pepsi",
          price: 30,
          addbtn: false,
          addcount: 0,
        },
        {
          name: "Cheese Garlic Bread",
          price: 125,
          addbtn: false,
          addcount: 0,
        },

        {
          name: "Mushroom",
          price: 105,
          addbtn: false,
          addcount: 0,
        },
      ],
    },
  ]);
  const [size, SetSize] = useState(false);
  const [view_cart, SetCart] = useState(true);
  const [width, SetWidth] = React.useState(innerWidth);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [quick_view, SetQuickView] = useState({
    open: false,
    value: false,
  });
  const [filter, SetFilter] = useState({
    open: false,
    value: "",
  });
  const [filter_option, SetFilterOption] = useState({
    open: true,
    value: "",
  });
  const [is_loading, SetIsLoading] = useState(true);
  const [is_search_loading, SetIsSearchLoading] = useState(true);
  // const [restaurant, SetRestaurant] = useState([
  //   {
  //     type: 0,
  //     id: 1,
  //     img: require("../../../assets/img/dhosa.jpg"),
  //     offer: 25,
  //     name: "A2B Veg",
  //     cuisine: "South Indian, North Indian, Chinnese & more",
  //     rate: 4.6,
  //     deliveryTime: "35 mins",
  //     delivery: "Free Delivery",
  //   },
  //   {
  //     type: 1,
  //     id: 2,
  //     img: require("../../../assets/img/food8.jpg"),
  //     offer: null,
  //     name: "Dindigul Thalappakadi Biriyani",
  //     cuisine: "South Indian, North Indian, Chinnese & more",
  //     rate: 3.9,
  //     deliveryTime: "41 mins",
  //     delivery: "",
  //   },
  //   {
  //     type: 0,
  //     id: 3,
  //     img: require("../../../assets/img/north.jpeg"),
  //     offer: null,
  //     name: "Erode Punjabi",
  //     cuisine: "South Indian, North Indian, Chinnese & more",
  //     rate: 3.9,
  //     deliveryTime: "51 mins",
  //     delivery: "",
  //   },
  //   {
  //     type: 1,
  //     id: 4,
  //     img: require("../../../assets/img/food4.jpg"),
  //     offer: null,
  //     name: "Rowthar Biriyani Center",
  //     cuisine: "South Indian, North Indian, Chinnese & more",
  //     rate: 3.8,
  //     deliveryTime: "32 mins",
  //     delivery: "Free Delivery",
  //   },
  //   {
  //     type: 0,
  //     id: 5,
  //     img: require("../../../assets/img/food7.jpeg"),
  //     offer: 13,
  //     name: "Pizza Hut",
  //     cuisine: "South Indian, North Indian, Chinnese & more",
  //     rate: 4.6,
  //     deliveryTime: "35 mins",
  //     delivery: "",
  //   },
  //   {
  //     type: 1,
  //     id: 6,
  //     img: require("../../../assets/img/c65.jpg"),
  //     offer: 16,
  //     name: "Kokarako Chicken Center",
  //     cuisine: "South Indian, North Indian, Chinnese & more",
  //     rate: 3.6,
  //     deliveryTime: "28 mins",
  //     delivery: "Free Delivery",
  //   },
  //   {
  //     type: 0,
  //     id: 7,
  //     img: require("../../../assets/img/images.jpeg"),
  //     offer: 5,
  //     name: "Saravana Bhavan",
  //     cuisine: "South Indian, North Indian, Chinnese & more",
  //     rate: 4.2,
  //     deliveryTime: "35 mins",
  //     delivery: "Free Delivery",
  //   },
  // ]);
  const [search_data, SetSearchData] = useState("");
  const [initial_render, SetInitialRender] = useState(true);
  const [vendor_data, SetVendorData] = useState([]);
  const [unavailble_vendor_data, SetUnavailableVendorData] = useState([]);
  const [specifications_data, SetSpecificationsData] = useState([]);
  const [recent_search, SetRecentSearch] = useState([]);
  const [show_search_result, SetShowSearchResult] = useState(true);
  const [outlet_context_data, SetOutletContextData]: any = useOutletContext();

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [filter_search, SetFilterSearch] = useState("");
  const slug_value = useParams();

  const [tree_checked, setTreeChecked] = useState([]);
  const [tree_expanded, setTreeExpanded] = useState([]);

  const [filtered_nodes, SetFilteredNodes] = useState([]);
  const [is_filter_applied, SetIsFilterApplied] = useState(false);
  const [is_all_selected, SetIsAllSelected] = useState(false);

  useEffect(() => {
    
    context.SetAppData((prevValue) => {
      prevValue.backHaves = props.backHaves;
      prevValue.bg_white++;
      return { ...prevValue };
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // setTimeout(() => {
    //   // SetIsLoading(false);
    //   
    // }, 3000);
    let send_data: any = {
      search_data: "",
      specifications_data: specifications_data,
    };

    if (url_params.get("q") != null) {
      SetSearchData(url_params.get("q"));
      send_data.search_data = url_params.get("q");
    }

    get_search_init();
    if (props.from_category) {
      get_vendors(send_data);
      // get_unavailble_vendors(send_data);
    } else {
      SetIsSearchLoading(false);
    }
    SetOutletContextData((prevValue) => {
      prevValue.have_back = props.have_back;
      prevValue.have_back_value = true;
      prevValue.back_value = "/" + id_value.section_slug;
      prevValue.title = "Search";
      prevValue.have_footer = true;
      prevValue.link = {
        rel: "canonical",
        href: "http://mysite.com/example",
      };
      return { ...prevValue };
    });

    return () => {
      context.SetAppData((prevValue: any) => {
        if (prevValue.bg_white > 0) {
          prevValue.bg_white--;
        }
        return { ...prevValue };
      });
    };
  }, []);

  useEffect(() => {
    SetVendorData([]);
    if (initial_render) {
      SetInitialRender(false);
    } else {
      SetVendorData([]);
      SetUnavailableVendorData([]);
      SetIsSearchLoading(true);
      if (search_data == "") {
        
        // get_products(filter_data)
        let send_data = {
          search_data: "",
          specifications_data: specifications_data,
        };
        //
        // get_vendors(send_data);
        // get_unavailble_vendors(send_data);
        //
        // SetIsSearchLoading(true);
        // SetUrlParams((prevalue: any) => {
        //   if (url_params.get("filter") != null) {
        //     prevalue["filter"] = url_params.get("filter");
        //   }
        //   // if (url_params.get("from") != null) {
        //   //   prevalue["from"] = url_params.get("from");
        //   // }
        //   prevalue.delete("q");
        //   return { ...prevalue };
        // });
        if (url_params.get("filter") != null) {
          let filter_value = decodeURI(url_params.get("filter"));
          let filter_value1 = JSON.parse(filter_value);
          SetSpecificationsData((prevalue) => {
            prevalue?.map((ele: any, e_index: any) => {
              if (e_index == 1) {
                ele.open = true;
              } else {
                ele.open = false;
              }
              filter_value1?.map((f_ele: any) => {
                if (f_ele.id == ele.id) {
                  ele?.options?.map((opt_ele: any) => {
                    f_ele?.value?.map((v_ele: any) => {
                      if (opt_ele.id == v_ele) {
                        opt_ele.checked = true;
                      }
                    });
                  });
                }
              });
            });
          
            return [...prevalue];
          });
          SetIsFilterApplied(true);
        }
        // SetShowSearchResult(false);
      } else {
        const timeOutId = setTimeout(() => triggerItemChange(search_data), 500);
        return () => clearTimeout(timeOutId);
      }
    }
  }, [search_data]);

  useEffect(() => {
    
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (url_params.get("q") != null) {
      SetSearchData(url_params.get("q"));
    } else {
      SetSearchData("");
    }
  }, [slug_value]);

  useEffect(() => {
    SetIsSearchLoading(true)
    SetVendorData([]);
    SetUnavailableVendorData([]);
    let send_data: any = {
      search_data: "",
      specifications_data: specifications_data,
    };

    if (url_params.get("q") != null) {
      SetSearchData(url_params.get("q"));
      send_data.search_data = url_params.get("q");
    }

    get_vendors(send_data);

  }, [props.category])

  function triggerItemChange(value: any) {
    
    SetUrlParams(
      (prevalue: any) => {
        if (url_params.get("filter") != null) {
          prevalue["filter"] = url_params.get("filter");
        }
        // if (url_params.get("from") != null) {
        //   prevalue["from"] = url_params.get("from");
        // }
        prevalue["q"] = value;
        return { ...prevalue };
      },
      { replace: true }
    );
    let send_data = {
      search_data: value,
      specifications_data: specifications_data,
    };
    SetShowSearchResult(true);
    get_vendors(send_data);
    // get_unavailble_vendors(send_data);
    // SetIsSearchLoading(true);
    let send_data1 = {
      from: "search_submit",
      keyword: value,
    };
    set_searched(send_data1);
  }

  function toggleDrawer(anchor: any, open: any, event: any) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  }

  async function get_search_init() {
    let pass_data = {
      get: {
        section_slug: id_value.section_slug,
      },
    };
    let response = await api("/search_init", pass_data);
    
    if (response.status_code === 200) {
      SetIsLoading(false);
      if (response.response.hasOwnProperty("recent_search")) {
        SetRecentSearch(response.response.recent_search);
      }
      if (response.response.hasOwnProperty("data")) {
        let s_data = response.response.data;

        if (url_params.get("filter") != null) {
          let filter_value = decodeURI(url_params.get("filter"));
          let filter_value1 = JSON.parse(filter_value);
          
          SetSpecificationsData((prevalue: any) => {
            s_data?.map((ele: any, s_ele_index: any) => {
              if (s_ele_index == 0) {
                ele.open = true;
              } else {
                ele.open = false;
              }
              let expand_data: any = [];
              if (ele.d == "c") {
                ele?.options?.map((opt_ele: any) => {
                  opt_ele.checked = [];
                  opt_ele.expanded = [];
                  opt_ele.disabled = false;
                  opt_ele.label = opt_ele.name;
                  opt_ele.value = opt_ele.id;
                  opt_ele = child_add_value(opt_ele);
                  // if (opt_ele.hasOwnProperty("children")) {
                  //   expand_data.push(opt_ele.id);
                  //   opt_ele.children.map((c_ele: any) => {
                  //     c_ele.label = c_ele.name;
                  //     c_ele.value = c_ele.id;
                  //   });
                  // }
                });
                
                setExpanded(expand_data);
              }
              filter_value1?.map((f_ele: any) => {
                
                if (f_ele.d == "c") {
                  if (f_ele.id == ele.slno) {
                    let checked_data = f_ele.value;
                    // setChecked(checked_data);
                    setTreeChecked(checked_data);
                  }
                }
                if (f_ele.d == "s" || f_ele.d == "b") {
                  if (f_ele.id == ele.id) {
                    ele?.options?.map((opt_ele: any) => {
                      f_ele?.value?.map((v_ele) => {
                        if (opt_ele.id == v_ele) {
                          opt_ele.checked = true;
                        }
                      });
                    });
                  }
                }
                if (f_ele.d == "p") {
                  if (f_ele.id == ele.slno) {
                    let push_value = [];
                    if (f_ele.hasOwnProperty("min")) {
                      push_value.push(f_ele.min);
                    } else {
                      push_value.push(0);
                    }
                    if (f_ele.hasOwnProperty("max")) {
                      push_value.push(f_ele.max);
                    } else {
                      push_value.push(10000);
                    }
                    ele.value = push_value;
                  }
                }
              });
            });
            
            prevalue = s_data;
            return [...prevalue];
          });
          SetIsFilterApplied(true);
        } else {
          s_data?.map((s_ele: any, s_ele_index: any) => {
            if (s_ele_index == 0) {
              s_ele.open = true;
            } else {
              s_ele.open = false;
            }
            let expand_data: any = [];
            if (s_ele.d == "c") {
              s_ele?.options?.map((opt_ele: any) => {
                opt_ele.checked = [];
                opt_ele.expanded = [];
                opt_ele.disabled = false;
                opt_ele.label = opt_ele.name;
                opt_ele.value = opt_ele.id;
                opt_ele = child_add_value(opt_ele);
                // if (opt_ele.hasOwnProperty("children")) {
                //   expand_data.push(opt_ele.id);
                //   opt_ele.children.map((c_ele: any) => {
                //     c_ele.label = c_ele.name;
                //     c_ele.value = c_ele.id;
                //   });
                // }
              });
              
              setExpanded(expand_data);
            }

            if (s_ele.d == "s" || s_ele.d == "b") {
              s_ele?.options?.map((opt_ele: any) => {
                opt_ele.checked = false;
                opt_ele.disabled = false;
              });
            }
            if (s_ele.d == "p") {
              s_ele.value = [0, 10000];
            }
          });
          SetSpecificationsData(s_data);
          SetIsFilterApplied(false);
        }
      }
      // if (response.response.hasOwnProperty("specifications")) {
      //   let s_data = response.response.specifications;
      //   s_data?.map((s_ele: any) => {
      //     s_ele?.options?.map((opt_ele: any) => {
      //       opt_ele.checked = false;
      //       opt_ele.disabled = false;
      //     });
      //   });
      //   SetSpecificationsData(s_data);
      // }
    }
  }

  function child_add_value(get_data: any) {
    if (get_data.hasOwnProperty("children")) {
      get_data.children.map((c_ele: any) => {
        c_ele.label = c_ele.name;
        c_ele.value = c_ele.id;
        c_ele = child_add_value(c_ele);
      });
    }
    return get_data;
  }

  const on_search_submit = (e: any) => {
    e.preventDefault();
    
    let send_data = {
      from: "search_submit",
      keyword: search_data,
    };
    set_searched(send_data);
  };

  async function set_searched(get_data: any) {
    let pass_data = {
      post: {
        section_slug: id_value.section_slug,
        // keyword: "",
        // brand_id: "",
        // outlet_id: "",
        // product_id: "",
        // variant_id: "",
      },
      get: {
        section_slug: id_value.section_slug,
      },
    };
    if (get_data.from == "search_submit") {
      pass_data.post.keyword = get_data.keyword;
    }
    if (get_data.from == "open_click") {
      pass_data.post.keyword = get_data.keyword;
      pass_data.post.brand_id = get_data.brand_id;
      pass_data.post.outlet_id = get_data.outlet_id;
    }
    if (get_data.from == "add_click") {
      pass_data.post.keyword = get_data.keyword;
      pass_data.post.brand_id = get_data.brand_id;
      pass_data.post.outlet_id = get_data.outlet_id;
      if (get_data.type == "group") {
        pass_data.post.product_id = get_data.product_id;
      }
      if (get_data.type != "group") {
        pass_data.post.variant_id = get_data.variant_id;
      }
    }
    
    let response = await api("/searched", pass_data);
    
    if (response.status_code === 200) {
      if (response.response.hasOwnProperty("recent_search")) {
        SetRecentSearch(response.response.recent_search);
      }
    }
  }

  async function get_vendors(get_data: any) {
    
    let lat_lang: any = {};
    if (Cookies.get("location") != undefined) {
      lat_lang = JSON.parse(Cookies.get("location"));
    }
    let pass_data = {
      get: {
        slug: id_value.section_slug,
        search: get_data.search_data,
        lat: lat_lang.lat,
        lng: lat_lang.lang,
      },
    };
    let filter_data = [];

    // get_data?.specifications_data?.map((s_ele: any, s_index: any) => {
    //   let is_checked = s_ele.options.some((opt_ele) => opt_ele.checked == true);
    //   if (is_checked) {
    //     let checked_data = s_ele.options.filter(
    //       (opt_ele) => opt_ele.checked == true
    //     );
    //     let push_value = {
    //       id: s_ele.id,
    //       value: [],
    //     };
    //     checked_data?.map((ele) => {
    //       push_value.value.push(ele.id);
    //     });
    //     filter_data.push(push_value);
    //   }
    // });
    // pass_data.get.filter = encodeURI(JSON.stringify(filter_data));
    if (url_params.get("filter") != null) {
      // prevalue["filter"] = url_params.get("filter");
      pass_data.get.filter = url_params.get("filter");
    } else {
    
      if (Object.hasOwn(get_data, "sample_filter_value")) {
        pass_data.get.filter = encodeURI(
          JSON.stringify(get_data.sample_filter_value)
        );
      } else {
        pass_data.get.filter = [];
      }
    }

    if (props?.category) {
      pass_data.get.category_slug = '["' + props?.category + '"]';
    }

    
    let response = await api("/section_vendors", pass_data);
    
    if (response.status_code === 200) {
      if (response.response.hasOwnProperty("vendors")) {
        let vendors_value = response.response.vendors;
        vendors_value?.map((v_ele: any, v_index: any) => {
          let selected_outlet_value = v_ele.outlets.find(
            (o_ele: any) => o_ele.checked == true
          );
          v_ele.selected_outlet = selected_outlet_value;
          v_ele.outlet_product_is_loading = true;
          v_ele.outlet_product = [];
          v_ele.have_products = false;
          v_ele.outlet_product_have_next_page = false;
          v_ele.is_viewed = false;
          v_ele.is_called = 0;
          //
          // let send_data = {
          //   v_index: v_index,
          //   selected_outlet: selected_outlet_value,
          //   page_no: 1,
          //   search_data: get_data.search_data,
          //   specifications_data: get_data.specifications_data,
          //   from: "available",
          // };
          // if (get_data.hasOwnProperty("sample_filter_value")) {
          //   send_data.sample_filter_value = get_data.sample_filter_value;
          // }
          // get_outlet_product(send_data);
          //
          // let outlet_product_value = get_outlet_product();
          // v_ele.outlet_product.push(outlet_product_value);
        });
        SetVendorData(vendors_value);
      } else {
        SetVendorData([]);
      }
      SetIsSearchLoading(false);
      get_unavailble_vendors(get_data);
    }
  }

  async function get_unavailble_vendors(get_data: any) {
    
    let lat_lang: any = {};
    if (Cookies.get("location") != undefined) {
      lat_lang = JSON.parse(Cookies.get("location"));
    }
    let pass_data = {
      get: {
        slug: id_value.section_slug,
        search: get_data.search_data,
        lat: lat_lang.lat,
        lng: lat_lang.lang,
        unavailable: 1,
      },
    };
    let filter_data = [];
    if (url_params.get("filter") != null) {
      pass_data.get.filter = url_params.get("filter");
    } else {
      
      if (Object.hasOwn(get_data, "sample_filter_value")) {
        pass_data.get.filter = encodeURI(
          JSON.stringify(get_data.sample_filter_value)
        );
      } else {
        pass_data.get.filter = [];
      }
    }

    if (props?.category) {
      pass_data.get.category_slug = '["' + props?.category + '"]';
    }

    
    let response = await api("/section_vendors", pass_data);
    
    if (response.status_code === 200) {
      if (response.response.hasOwnProperty("vendors")) {
        let vendors_value = response.response.vendors;
        vendors_value?.map((v_ele: any, v_index) => {
          let selected_outlet_value = v_ele.outlets.find(
            (o_ele) => o_ele.checked == true
          );
          v_ele.selected_outlet = selected_outlet_value;
          v_ele.outlet_product_is_loading = true;
          v_ele.outlet_product = [];
          v_ele.have_products = false;
          v_ele.outlet_product_have_next_page = false;
          v_ele.is_viewed = false;
          v_ele.is_called = 0;
          // let send_data = {
          //   v_index: v_index,
          //   selected_outlet: selected_outlet_value,
          //   page_no: 1,
          //   search_data: get_data.search_data,
          //   specifications_data: get_data.specifications_data,
          //   from: "unavailable",
          // };
          // if (get_data.hasOwnProperty("sample_filter_value")) {
          //   send_data.sample_filter_value = get_data.sample_filter_value;
          // }
          // get_outlet_product(send_data);
          //
          // let outlet_product_value = get_outlet_product();
          // v_ele.outlet_product.push(outlet_product_value);
        });
        SetUnavailableVendorData(vendors_value);
        // SetUnavailableVendorData((prevalue) => {
        //   vendors_value.map((ele) => {
        //     prevalue.push(ele);
        //   });
        //   return [...prevalue];
        // });
      } else {
        SetUnavailableVendorData([]);
      }
      SetIsSearchLoading(false);
    }
  }

  async function get_outlet_product(get_data: any) {
    

    let pass_data = {
      get: {
        section_slug: id_value.section_slug,
        seller_slug: get_data.selected_outlet.slug,
        search: get_data.search_data,
        page: get_data.page_no,
        type: "all",
      },
    };
    let filter_data = [];
    // get_data?.specifications_data?.map((s_ele: any, s_index: any) => {
    //   let is_checked = s_ele.options.some((opt_ele) => opt_ele.checked == true);
    //   if (is_checked) {
    //     let checked_data = s_ele.options.filter(
    //       (opt_ele) => opt_ele.checked == true
    //     );
    //     let push_value = {
    //       id: s_ele.id,
    //       value: [],
    //     };
    //     checked_data?.map((ele) => {
    //       push_value.value.push(ele.id);
    //     });
    //     filter_data.push(push_value);
    //   }
    // });
    // pass_data.get.filter = encodeURI(JSON.stringify(filter_data));
    if (url_params.get("filter") != null) {
      // prevalue["filter"] = url_params.get("filter");
      pass_data.get.filter = url_params.get("filter");
    } else {
      if (get_data.hasOwnProperty("sample_filter_value")) {
        pass_data.get.filter = encodeURI(
          JSON.stringify(get_data.sample_filter_value)
        );
      } else {
        pass_data.get.filter = [];
      }
    }
    if (props?.category) {
      pass_data.get.category_slug = '["' + props?.category + '"]';
    }
    
    let response = await api("/vendor_products", pass_data);
    
    if (response.status_code == 200) {
      if (get_data.from == "available") {
        SetVendorData((prevalue: any) => {
          prevalue?.map((v_ele: any, v_index: any) => {
            if (v_index == get_data.v_index) {
              if (response.response.hasOwnProperty("products")) {
                if (response.response.products.length > 0) {
                  v_ele.have_products = true;
                  v_ele.outlet_product = response.response.products;
                } else {
                  v_ele.have_products = false;
                  let send_data = {
                    section_slug: id_value.section_slug,
                    seller_slug: get_data.selected_outlet.slug,
                    search: "",
                    page: get_data.page_no,
                    type: "all",
                    v_index: get_data.v_index,
                  };
                  // get_product_without_search(send_data);
                }
              }
              v_ele.outlet_product_is_loading = false;
              v_ele.is_viewed = true;
              // v_ele.is_called = v_ele.is_called + 1;
              v_ele.outlet_product_have_next_page =
                response.response.products_next_page;
            }
          });
          return [...prevalue];
        });
      } else {
        SetUnavailableVendorData((prevalue: any) => {
          prevalue?.map((v_ele: any, v_index: any) => {
            if (v_index == get_data.v_index) {
              if (response.response.hasOwnProperty("products")) {
                if (response.response.products.length > 0) {
                  v_ele.have_products = true;
                  v_ele.outlet_product = response.response.products;
                } else {
                  v_ele.have_products = false;
                  let send_data = {
                    section_slug: id_value.section_slug,
                    seller_slug: get_data.selected_outlet.slug,
                    search: "",
                    page: get_data.page_no,
                    type: "all",
                    v_index: get_data.v_index,
                  };
                  // get_product_without_search(send_data);
                }
              }
              v_ele.outlet_product_is_loading = false;
              v_ele.is_viewed = true;
              // v_ele.is_called = v_ele.is_called + 1;
              v_ele.outlet_product_have_next_page =
                response.response.products_next_page;
            }
          });
          return [...prevalue];
        });
      }
    }
  }

  async function get_product_without_search(get_data: any) {
    let pass_data: any = {
      get: {
        section_slug: get_data.section_slug,
        seller_slug: get_data.seller_slug,
        search: get_data.search,
        page: get_data.page,
        type: get_data.type,
      },
    };
    if (props?.category) {
      pass_data.get.category_slug = '["' + props?.category + '"]';
    }
    
    let response = await api("/vendor_products", pass_data);
    
    if (response.status_code == 200) {
      SetVendorData((prevalue: any) => {
        prevalue?.map((v_ele: any, v_index: any) => {
          if (v_index == get_data.v_index) {
            if (response.response.hasOwnProperty("products")) {
              if (response.response.products.length > 0) {
                v_ele.outlet_product = response.response.products;
              }
            }
            v_ele.outlet_product_is_loading = false;
            v_ele.outlet_product_have_next_page =
              response.response.products_next_page;
          }
        });
        return [...prevalue];
      });
    }
  }

  const recent_search_skeleton = () => {
    return (
      <div className="d-flex pb-3">
        <Skeleton
          height={20}
          width={100}
          style={{ borderRadius: 20, marginRight: 10 }}
        />
        <Skeleton
          height={20}
          width={50}
          style={{ borderRadius: 20, marginRight: 10 }}
        />
      </div>
    );
  };

  const vendor_skeleton = () => {
    return (
      <div className="mt-5">
        {[1, 2, 3, 4, 5].map((ele) => (
          <div className="mt-3 card p-2 pb-3">
            <Skeleton height={10} width={100} />
            <div className="restaurant-list search-alice row px-0 pb-1">
              <AliceCarousel
                mouseTracking
                touchMoveDefaultEvents={false}
                paddingRight={20}
                disableDotsControls
                responsive={foodresponsive}
                disableButtonsControls
                controlsStrategy="alternate"
              >
                {[1, 2, 3, 4, 5, 6].map((data, index) => (
                  <div
                    className="restaurant-list__item mt-2"
                    key={index}
                    onDragStart={handleDragStart}
                  >
                    <div className="restaurant-list__item_card cursor">
                      <Skeleton height={160} width="100%" />
                    </div>
                  </div>
                ))}
              </AliceCarousel>
            </div>
          </div>
        ))}
      </div>
    );
  };

  // const Block = (props: { inViewport: boolean }) => {
  //   const { inViewport, forwardedRef } = props;
  //   const color = inViewport ? "#217ac0" : "#ff9800";
  //   const text = inViewport ? "In viewport" : "Not in viewport";
  //   return (
  //     <div className="viewport-block" ref={forwardedRef}>
  //       <h3>{text}</h3>
  //       <div style={{ width: "400px", height: "300px", background: color }} />
  //     </div>
  //   );
  // };

  // const ViewportBlock = handleViewport(Block /** options: {}, config: {} **/);

  // const ref = useRef();

  const {
    observe,
    scrollDirection: { horizontal },
  } = useInView({
    // trackVisibility: true,
    threshold: [0.5],
    onEnter: ({ unobserve }) => {
      // Pause observe when loading data
      
      // 
      unobserve();
    },
  });

  // const {
  //   ref,
  //   inView,
  //   scrollDirection: { horizontal },
  //   entry,
  //   observe,
  //   unobserve,
  // } = useInView({
  //   threshold: 0.5, // Default is 0
  //   onChange: ({ inView, scrollDirection, entry, observe, unobserve }) => {
  //     // Triggered whenever the target meets a threshold, e.g. [0.25, 0.5, ...]
  //     
  //   },
  //   onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
  //     // Triggered when the target enters the viewport
  //     
  //   },
  //   onLeave: ({ scrollDirection, entry, observe, unobserve }) => {
  //     // Triggered when the target leaves the viewport
  //     
  //   },
  //   // More useful options...
  // });

  function get_to_path(data: any, v_ele: any) {
    let return_data;
    if (data.hasOwnProperty("product_id")) {
      if (
        search_data != null &&
        search_data != undefined &&
        search_data.length > 0
      ) {
        // return_data =
        //   "/" +
        //   id_value.section_slug +
        //   "/" +
        //   v_ele.selected_outlet.slug +
        //   "?q=" +
        //   search_data +
        //   "&prod_id=" +
        //   data.product_id;
        return_data =
          "/" +
          id_value.section_slug +
          "/" +
          v_ele.selected_outlet.slug +
          "?q=" +
          search_data;
      } else {
        // return_data =
        //   "/" +
        //   id_value.section_slug +
        //   "/" +
        //   v_ele.selected_outlet.slug +
        //   "?prod_id=" +
        //   data.product_id;
        return_data =
          "/" + id_value.section_slug + "/" + v_ele.selected_outlet.slug;
      }
    } else {
      if (
        search_data != null &&
        search_data != undefined &&
        search_data.length > 0
      ) {
        // return_data =
        //   "/" +
        //   id_value.section_slug +
        //   "/" +
        //   v_ele.selected_outlet.slug +
        //   "?q=" +
        //   search_data +
        //   "&prod_id=" +
        //   data.variant_id;
        return_data =
          "/" +
          id_value.section_slug +
          "/" +
          v_ele.selected_outlet.slug +
          "?q=" +
          search_data;
      } else {
        // return_data =
        //   "/" +
        //   id_value.section_slug +
        //   "/" +
        //   v_ele.selected_outlet.slug +
        //   "?prod_id=" +
        //   data.variant_id;
        return_data =
          "/" + id_value.section_slug + "/" + v_ele.selected_outlet.slug;
      }
    }
    let filter_data = [];
    specifications_data?.map((s_ele: any, s_index: any) => {
      let is_checked: any;
      if (s_ele.d == "c" || s_ele.d == "b" || s_ele.d == "s") {
        is_checked = s_ele.options?.some((opt_ele) => opt_ele.checked == true);
      }
      if (is_checked) {
        let checked_data = s_ele.options.filter(
          (opt_ele) => opt_ele.checked == true
        );
        let push_value = {
          id: s_ele.id,
          value: [],
        };
        checked_data?.map((ele) => {
          push_value.value.push(ele.id);
        });
        filter_data.push(push_value);
      }
    });
    if (filter_data.length > 0) {
      return_data =
        return_data + "&filter=" + encodeURI(JSON.stringify(filter_data));
    }
    
    return encodeURI(return_data);
  }

  function get_to_path_with_category(data: any, v_ele: any) {
    let return_data;

    return_data =
      "/" +
      id_value.section_slug +
      "/" +
      v_ele.selected_outlet.slug +
      "?category=" +
      props.category;

    let filter_data = [];
    specifications_data?.map((s_ele: any, s_index: any) => {
      let is_checked: any;
      if (s_ele.d == "c" || s_ele.d == "b" || s_ele.d == "s") {
        is_checked = s_ele.options?.some((opt_ele) => opt_ele.checked == true);
      }
      if (is_checked) {
        let checked_data = s_ele.options.filter(
          (opt_ele) => opt_ele.checked == true
        );
        let push_value = {
          id: s_ele.id,
          value: [],
        };
        checked_data?.map((ele) => {
          push_value.value.push(ele.id);
        });
        filter_data.push(push_value);
      }
    });
    if (filter_data.length > 0) {
      return_data =
        return_data + "&filter=" + encodeURI(JSON.stringify(filter_data));
    }
    
    return encodeURI(return_data);
  }

  function get_to_path1(v_ele: any) {
    let return_data;
    if (v_ele.have_products) {
      return_data =
        "/" +
        id_value.section_slug +
        "/" +
        v_ele.selected_outlet.slug +
        "?q=" +
        search_data;
    } else {
      return_data =
        "/" + id_value.section_slug + "/" + v_ele.selected_outlet.slug;
    }
    let filter_data = [];
    specifications_data?.map((s_ele: any, s_index: any) => {
      let is_checked: any;
      if (s_ele.d == "c" || s_ele.d == "b" || s_ele.d == "s") {
        is_checked = s_ele.options?.some((opt_ele) => opt_ele.checked == true);
      }
      if (is_checked) {
        let checked_data = s_ele.options.filter(
          (opt_ele) => opt_ele.checked == true
        );
        let push_value = {
          id: s_ele.id,
          value: [],
        };
        checked_data?.map((ele) => {
          push_value.value.push(ele.id);
        });
        filter_data.push(push_value);
      }
    });
    if (filter_data.length > 0) {
      return_data =
        return_data + "&filter=" + encodeURI(JSON.stringify(filter_data));
    }
    return encodeURI(return_data);
  }

  const get_diet_icon = (get_data: any) => {
    return (
      <>
        {get_data?.specifications?.map((ele: any) =>
          ele.id == AppConfig.diet_type ? (
            <>
              {ele.value[0].id == AppConfig.vegetarian ? (
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "e301ce3af353aab421a2f76c898a439d.png"
                  }
                  className="type-img"
                />
              ) : null}
              {ele.value[0].id == AppConfig.non_vegetarian ? (
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "5c7a11ae863a9b5cfa0e1844c7a186b9.png"
                  }
                  className="type-img"
                />
              ) : null}
              {ele.value[0].id == AppConfig.eggetarian ? (
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "fea28c272b8bbfb74f54b073bea5cfaa.png"
                  }
                  className="type-img"
                />
              ) : null}
              {ele.value[0].id == AppConfig.vegan ? (
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "1e2d32e8b1e3f2fd5eb65c3b8957674e.png"
                  }
                  className="type-img"
                />
              ) : null}
            </>
          ) : null
        )}
      </>
    );
  };

  function check_have_filter() {
    let return_value = false;

    SetSpecificationsData((prevalue) => {
      if (prevalue.length > 0) {
        return_value = prevalue.every((ele: any) => {
          return ele.options?.some((p_ele) => p_ele.checked == true);
        });
      }

      return [...prevalue];
    });
    // return_value = specifications_data.every((ele: any) => {
    //   return ele.options.some((p_ele) => p_ele.checked == true)
    // });
    
    return return_value;
  }

  // function get_is_checked(get_data: any) {
  //   let return_data = false;
  //   if (get_data.d == "c") {
  //     if (tree_checked.length > 0) {
  //       return_data = true;
  //     }
  //     return return_data;
  //   }
  //   if (get_data.d == "b") {
  //     return_data = get_data.options.some((opt_ele) => opt_ele.checked == true);
  //     return return_data;
  //   }
  //   if (get_data.d == "s") {
  //     return_data = get_data.options.some((opt_ele) => opt_ele.checked == true);
  //     return return_data;
  //   }
  //   if (get_data.d == "p") {
  //     if (
  //       (get_data.hasOwnProperty("value") && get_data.value[0] > 0) ||
  //       get_data.value[1] < 10000
  //     ) {
  //       return_data = true;
  //     }
  //     return return_data;
  //   }
  // }

  function get_is_checked(get_data: any) {
    let return_data = false;
    if (get_data.d == "c") {
      if (tree_checked.length > 0) {
        return_data = true;
      }
      return return_data;
    }
    if (get_data.d == "b") {
      return_data = get_data.options?.some(
        (opt_ele: any) => opt_ele.checked == true
      );
      return return_data;
    }
    if (get_data.d == "s") {
      return_data = get_data.options?.some(
        (opt_ele: any) => opt_ele.checked == true
      );
      return return_data;
    }
    if (get_data.d == "p") {
      if (get_data.hasOwnProperty("value")) {
        if (
          (get_data.value != null &&
            get_data.value != undefined &&
            get_data.value[0] > 0) ||
          get_data.value[1] < 10000
        ) {
          return_data = true;
        }
      }

      return return_data;
    }
  }

  function filterd_value(option_value: any) {
    let return_value: any = [];
    SetFilterSearch((prevalue: any) => {
      let search_value = prevalue;
      if (search_value == "") {
        return_value = option_value;
      } else {
        return_value = option_value.filter((value) => {
          const searchStr = search_value.toLowerCase();
          const nameMatches = value.name.toLowerCase().includes(searchStr);
          return nameMatches;
        });
      }
      return prevalue;
    });
    return return_value;
  }

  function filterNodes(filterText: any) {
    return (filtered: any, node: any) => {
      let children = (node.children || []).reduce(filterNodes(filterText), []);
      if (
        node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
          -1 ||
        children.length
      ) {
        filtered.push({ ...node, children });
      }
      return filtered;
    };
  }

  function clear_all_filter() {
    SetSpecificationsData((prevalue): any => {
      prevalue.map((ele: any) => {
        // if (ele.id != "115") {
        if (ele.d == "c") {
          setTreeChecked([]);
        }
        if (ele.d == "b") {
          ele.options.map((opt_ele: any) => {
            opt_ele.checked = false;
          });
        }
        if (ele.d == "s") {
          ele.options.map((opt_ele: any) => {
            opt_ele.checked = false;
          });
        }
        if (ele.d == "p") {
          ele.value = [0, 10000];
        }
        // }
      });
    
      return prevalue;
    });
  }

  function make_select_all(option_data: any) {
    
    let new_checked_data: any = [];
    option_data.map((ele: any) => {
      new_checked_data.push(ele.id);
      if (ele.hasOwnProperty("children") && ele.children.length > 0) {
        let children_value = check_children(ele.children);
        children_value.map((ele1: any) => {
          new_checked_data.push(ele1);
        });
        // new_checked_data.push(check_children(ele.children));
        // check_children(ele.children)
      }
    });
    
    setTreeChecked(new_checked_data);
  }

  function check_children(child_data: any) {
    let return_data: any = [];
    child_data.map((ele: any) => {
      return_data.push(ele.id);
      if (ele.hasOwnProperty("children") && ele.children.length > 0) {
        let children_value = check_children(ele.children);
        children_value.map((ele1: any) => {
          return_data.push(ele1);
        });
        // return_data.push(check_children(ele.children));
      }
    });
    return return_data;
  }

  function is_all_selected_check() {
    let return_data = false;
    setTreeChecked((prevalue) => {
      SetFilteredNodes((prevalue1) => {
        if (prevalue.length === prevalue1.length) {
          return_data = true;
        }
        return [...prevalue1];
      });
      return [...prevalue];
    });
    // return return_data
    SetIsAllSelected(return_data);
  }

  function get_unavailable_details(get_data: any) {
    let return_value;
    let index_value = get_data.outlets.findIndex(
      (ele: any) => ele.checked == true
    );
    if (index_value != -1) {
      let est_time = Math.floor(get_data.outlets[index_value].etd_seconds / 60);
      if (
        get_data.outlets[index_value].hasOwnProperty("next_available_at_outlet")
      ) {
        return_value =
          "Outlet is not accepting orders at the moment. " +
          get_data.outlets[index_value].next_available_at_outlet;
      } else if (
        get_data.outlets[index_value].hasOwnProperty("next_available_at")
      ) {
        return_value = get_data.outlets[index_value].next_available_at;
      } else {
        return_value = "Outlet not accepting orders at the moment";
      }
    }
    return return_value;
  }

  const VendorCardComponent = (r_props: any) => {
    return (
      <div className="mb-3 p-2 box-3">
        <div className="d-flex pt-2 mb-2">
          <div>
            {r_props.v_ele.hasOwnProperty("banner_image") ? (
              <img
                src={AppHelper.imageSrc(
                  context.app_data.image_base_url,
                  r_props.v_ele.banner_image,
                  {
                    format: "webp",
                    width: 320,
                  }
                )}
                // className="res-img"
                className={
                  r_props.v_ele.selected_outlet.available
                    ? "res-img"
                    : "res-img-gray"
                }
              />
            ) : (
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "11d7bcaa7cc795223d03cba91e57d936.jpg"
                }
                className={
                  r_props.v_ele.selected_outlet.available
                    ? "res-img"
                    : "res-img-gray"
                }
              />
            )}
          </div>
          <div className="ps-2">
            <h6 className="mb-1">{r_props.v_ele.brand_name}</h6>
            <p className="mt-1 celias-font">
              {r_props.v_ele.selected_outlet.name} /{" "}
              {r_props.v_ele.selected_outlet.distance_kms} KM /{" "}
              {Math.floor(r_props.v_ele.selected_outlet.etd_seconds / 60)} mins
            </p>
          </div>
          <div className="ms-auto cursor ">
            <Link to={get_to_path1(r_props.v_ele)}>
              <div
                className="px-2"
                onClick={() => {
                  let send_data = {
                    from: "open_click",
                    keyword: search_data,
                    brand_id: r_props.v_ele.brand_id,
                    outlet_id: r_props.v_ele.selected_outlet.outlet_id,
                  };
                  set_searched(send_data);
                  context.SetAppData((prevValue) => {
                    prevValue.selected_vendor = r_props.v_ele;
                    prevValue.selected_vendor_outlet =
                      r_props.v_ele.selected_outlet;
                    return { ...prevValue };
                  });
                }}
              >
                {/* open */}
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "74db726f2280f86ee4dc651af07e2c3c.svg"
                  }
                  className="w-20px"
                />
                {/* <SimpleLineIcons
                  name="arrow-right-circle"
                  size={20}
                  color="#333"
                /> */}
              </div>
            </Link>
          </div>
        </div>

        {r_props.v_ele.outlet_product_is_loading ? (
          <div className="restaurant-list search-alice row px-0">
            <AliceCarousel
              mouseTracking
              // touchMoveDefaultEvents={true}
              paddingRight={30}
              disableDotsControls
              responsive={foodresponsive}
              disableButtonsControls
              controlsStrategy="alternate"
              preservePosition={true}
            >
              {[1, 2, 3, 4, 5, 6].map((data, index) => (
                <div
                  className="restaurant-list__item mt-2"
                  key={index}
                  onDragStart={handleDragStart}
                >
                  <div className="cursor">
                    <Skeleton height={140} width="100%" />
                  </div>
                </div>
              ))}
            </AliceCarousel>
          </div>
        ) : (
          r_props.v_ele?.outlet_product?.length > 0 && (
            <div className="restaurant-list search-alice row px-0 carousel-products-2">
              <AliceCarousel
                mouseTracking
                // touchMoveDefaultEvents={true}
                paddingRight={30}
                disableDotsControls
                responsive={foodresponsive}
                disableButtonsControls
                controlsStrategy="alternate"
                preservePosition={true}
              >
                {r_props.v_ele?.outlet_product?.map((data, index) => (
                  // <div
                  //   className="me-2 h-100"
                  //   key={index}
                  //   onDragStart={handleDragStart}
                  // >
                  //   <div className="row border br-20 py-3 px-2 w-100 h-100">
                  //     <div className="col-8 p-0 pe-1 cursor">
                  //       <div>
                  //         <div className="d-flex align-items-center w-100 mb-1">
                  //           {data.hasOwnProperty("specifications")
                  //             ? get_diet_icon(data)
                  //             : null}
                  //         </div>
                  //         <h6 className="pt-1 mb-1 fs-14px">{data.name}</h6>
                  //         {/* oneline */}
                  //         <p className="marg celias-font fs-11px">
                  //           {data.description}
                  //         </p>
                  //         {data.hasOwnProperty("show_price_without_gst") &&
                  //         data.show_price_without_gst &&
                  //         data?.price_without_gst ? (
                  //           <div className="mt-1 fs-13px">
                  //             &#8377;{data?.price_without_gst}
                  //           </div>
                  //         ) : (
                  //           !data.hasOwnProperty("show_price_without_gst") &&
                  //           data?.price_with_gst && (
                  //             <div className="mt-1 fs-13px">
                  //               &#8377;{data?.price_with_gst}
                  //             </div>
                  //           )
                  //         )}
                  //       </div>
                  //     </div>
                  //     <div className="col-4 p-0">
                  //       <div>
                  //         {data.hasOwnProperty("images") ? (
                  //           <ImageContainer
                  //             src={AppHelper.imageSrc(
                  //               context.app_data.image_base_url,
                  //               data.images[0].url,
                  //               {
                  //                 format: "webp",
                  //                 width: 320,
                  //               }
                  //             )}
                  //             thumb={AppHelper.imageSrc(
                  //               context.app_data.image_base_url,
                  //               data.images[0].url,
                  //               {
                  //                 format: "webp",
                  //                 width: 320,
                  //                 quality: 10,
                  //               }
                  //             )}
                  //             // class_name="near-img "
                  //             class_name={
                  //               r_props.type != "unavailable"
                  //                 ? data.available
                  //                   ? "near-img h-80px"
                  //                   : "near-img-gray h-80px"
                  //                 : "near-img-gray h-80px"
                  //             }
                  //             onIsVisible={() => {
                  //             
                  //             }}
                  //           />
                  //         ) : (
                  //           <div className="near-img h-80px"></div>
                  //         )}
                  //         {r_props.type != "unavailable" ? (
                  //           data.available ? (
                  //             <Link to={get_to_path(data, r_props.v_ele)}>
                  //               <div
                  //                 className={
                  //                   data.images !== "" ? "bottom-offer" : ""
                  //                 }
                  //               >
                  //                 <div
                  //                   className="offer p-0 d-flex align-items-center"
                  //                   onClick={() => {
                  //                     
                  //                     let send_data = {
                  //                       from: "add_click",
                  //                       keyword: search_data,
                  //                       brand_id: r_props.v_ele.brand_id,
                  //                       outlet_id:
                  //                         r_props.v_ele.selected_outlet
                  //                           .outlet_id,
                  //                       type: data.type,
                  //                     };
                  //                     if (data.type == "group") {
                  //                       send_data.product_id = data.product_id;
                  //                     }
                  //                     if (data.type != "group") {
                  //                       send_data.variant_id = data.variant_id;
                  //                     }
                  //                     context.SetAppData((prevValue) => {
                  //                       prevValue.selected_vendor =
                  //                         r_props.v_ele;
                  //                       prevValue.selected_vendor_outlet =
                  //                         r_props.v_ele.selected_outlet;
                  //                       if (data.type == "group") {
                  //                         prevValue.searched_prod_id =
                  //                           data.product_id;
                  //                       } else {
                  //                         prevValue.searched_prod_id =
                  //                           data.variant_id;
                  //                       }

                  //                       return { ...prevValue };
                  //                     });
                  //                     set_searched(send_data);
                  //                   }}
                  //                 >
                  //                   <p className="cursor p-2 text-dark celias-font w-100">
                  //                     Add
                  //                   </p>
                  //                 </div>
                  //               </div>
                  //             </Link>
                  //           ) : (
                  //             <div
                  //               className={
                  //                 data.images !== "" ? "bottom-offer" : ""
                  //               }
                  //             >
                  //               <div className="offer p-0 d-flex align-items-center">
                  //                 {/* Unavailable */}
                  //                 {data.next_available_at != "" &&
                  //                 data.next_available_at != null ? (
                  //                   <p className="cursor p-1 celias-font v-small fs-9px text-gray w-100">
                  //                     Next Available at {data.next_available_at}
                  //                   </p>
                  //                 ) : (
                  //                   <p className="cursor py-2 celias-font v-small fs-9px text-gray w-100">
                  //                     Unavailable
                  //                   </p>
                  //                 )}
                  //               </div>
                  //             </div>
                  //           )
                  //         ) : null}
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                  <div
                    className={" p-2 w-100"}
                    // className="row border-bottom pb-3 pt-1 px-2 w-100"
                    key={index}
                  >
                    <div className="row p-2 border radius-10">
                      <ResturantCardComponent
                        from={"search_page"}
                        data={data}
                        get_outlet_available={get_outlet_available()}
                        get_diet_icon={get_diet_icon(data)}
                        quick_view={() => {
                          // SetOutletContextData(
                          //   (prevalue: any) => {
                          //     prevalue.is_bottom_sheet_open =
                          //       true;
                          //     return { ...prevalue };
                          //   }
                          // );
                          // SetQuickView({
                          //   open: true,
                          //   value: data,
                          // });
                        }}
                        on_add_click={() => {
                         
                          let send_data = {
                            from: "add_click",
                            keyword: search_data,
                            brand_id: r_props.v_ele.brand_id,
                            outlet_id: r_props.v_ele.selected_outlet.outlet_id,
                            type: data.type,
                          };
                          if (data.type == "group") {
                            send_data.product_id = data.product_id;
                          }
                          if (data.type != "group") {
                            send_data.variant_id = data.variant_id;
                          }
                          context.SetAppData((prevValue) => {
                            prevValue.selected_vendor = r_props.v_ele;
                            prevValue.selected_vendor_outlet =
                              r_props.v_ele.selected_outlet;
                            if (data.type == "group") {
                              prevValue.searched_prod_id = data.product_id;
                            } else {
                              prevValue.searched_prod_id = data.variant_id;
                            }
                            return { ...prevValue };
                          });
                          set_searched(send_data);
                          // navigate(get_to_path(data, r_props.v_ele));

                          if (props?.from_category) {
                            navigate(
                              get_to_path_with_category(data, r_props.v_ele)
                            );
                          } else {
                            navigate(get_to_path(data, r_props.v_ele));
                          }
                        }}
                        add_wishlist={() => {
                          
                        }}
                      />
                    </div>
                  </div>
                ))}
              </AliceCarousel>
            </div>
          )
        )}

        {r_props.type == "unavailable" ? (
          <div
            className={
              r_props.v_ele?.outlet_product?.length > 0
                ? "unavailable-detail p-2"
                : "unavailable-detail pt-2"
            }
          >
            <p className="v-small text-red">
              {get_unavailable_details(r_props.v_ele)}
            </p>
          </div>
        ) : null}
      </div>
    );
  };

  function get_outlet_available() {
    let return_value = true;
    context?.app_data?.selected_vendor?.outlets?.map((ele: any) => {
      if (ele.slug == id_value.seller_slug) {
        if (ele.available) {
          return_value = true;
        } else {
          return_value = false;
        }
      }
    });

    return return_value;
  }

  const CardComponent = (g_props: any) => {
    return g_props.for == "grocery" ? (
      <>
        <div className="p-1 pt-0 prods" key={g_props.index}>
          <div className="prod-card border-lightgray">
            <div
              className=""
              onClick={() => {
                SetQuickView({
                  open: true,
                  value: g_props.data,
                });
              }}
            >
              {g_props.data.hasOwnProperty("images") ? (
                <div className="h-140px">
                  <ImageContainer
                    src={AppHelper.imageSrc(
                      context.app_data.image_base_url,
                      g_props.data.images[0].url,
                      {
                        format: "webp",
                        width: 320,
                      }
                    )}
                    thumb={AppHelper.imageSrc(
                      context.app_data.image_base_url,
                      g_props.data.images[0].url,
                      {
                        format: "webp",
                        width: 320,
                        quality: 10,
                      }
                    )}
                    class_name={
                      g_props.data.available && get_outlet_available()
                        ? "w-100 prod-image "
                        : "w-100 prod-image-gray "
                    }
                    onIsVisible={() => {
                      
                    }}
                  />
                </div>
              ) : (
                <div className="w-100 prod-image-gray"></div>
              )}
              {/* <img src="{prod.img}" className="w-100 prod-image" /> */}
            </div>
            <div className="p-2">
              <p className="text-dark pop-font">{g_props.data.name}</p>
              {/* <p className="text-gray v-small pop-font">{prod.weight}</p> */}
              <div className="row pt-3">
                <div className="col-4 p-0">
                  {g_props.data.hasOwnProperty("show_price_without_gst") ? (
                    g_props.data.show_price_without_gst ? (
                      <>
                        {g_props.data.price_without_gst != "" &&
                        g_props.data.price_without_gst != null ? (
                          <p className="text-dark">
                            ₹{g_props.data.price_without_gst}
                          </p>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {g_props.data.price_with_gst != "" &&
                        g_props.data.price_with_gst != null ? (
                          <p className="text-dark">
                            ₹{g_props.data.price_with_gst}
                          </p>
                        ) : null}
                      </>
                    )
                  ) : null}
                  <p className="strike">₹{g_props.data.mrp}</p>
                </div>
                <div className="col-8 px-1">
                  <Link to={get_to_path(g_props.data, g_props.v_ele)}>
                    <div className="prod-add-btn">
                      <p className="">ADD</p>
                      {g_props.data.customize ? (
                        <fieldset className="center-text-in-border">
                          <legend>More Options</legend>
                        </fieldset>
                      ) : null}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : null;
    // ) : g_props.view_type == "grid" ? (
    //   <div
    //     className="py-0 px-1 prods mt-2 col-6 col-md-4 col-lg-3"
    //     key={g_props.index}
    //   >
    //     <div className="border pb-2">
    //       <div className="card bg-lightblue pt-2 pb-0">
    //         {/* discount */}
    //         {/* {data?.discount != "" ? (
    //             <div className="text-center disc_ecom">
    //               <p>
    //                 <b className="text-white">
    //                   {data?.discount}% Off
    //                 </b>
    //               </p>
    //             </div>
    //           ) : null} */}
    //         {/* favourite */}
    //         <div
    //           className="img-top-right cursor"
    //           onClick={() => {
    //             
    //             g_props.onfav();
    //           }}
    //         >
    //           {g_props.data?.favourite ? (
    //             <div className="wishlist-heart">
    //               <img
    //                 src={
    //                   AppConfig.CDN_Media_url +
    //                   "eff3dd986a87c9e8224c9547708adcd4.svg"
    //                 }
    //                 className="w-20"
    //               />
    //             </div>
    //           ) : (
    //             <div className="wishlist-heart">
    //               <img
    //                 src={
    //                   AppConfig.CDN_Media_url +
    //                   "717d8ac4005e4eda5f72bd6b5485d4be.svg"
    //                 }
    //                 className="w-20"
    //               />
    //             </div>
    //           )}
    //         </div>
    //         {/* image */}
    //         <div
    //           className=""
    //           onClick={() => {
    //             // SetQuick({ open: true, data: data });
    //             
    //             SetQuickView({
    //               open: true,
    //               value: g_props.data,
    //             });
    //           }}
    //         >
    //           {/* <img
    //               src={data?.img_path}
    //               className="prods-img"
    //             /> */}
    //           {g_props.data.hasOwnProperty("images") ? (
    //             <ImageContainer
    //               src={AppHelper.imageSrc(
    //                 context.app_data.image_base_url,
    //                 g_props.data.images[0].url,
    //                 {
    //                   format: "webp",
    //                   width: 320,
    //                 }
    //               )}
    //               thumb={AppHelper.imageSrc(
    //                 context.app_data.image_base_url,
    //                 g_props.data.images[0].url,
    //                 {
    //                   format: "webp",
    //                   width: 320,
    //                   quality: 10,
    //                 }
    //               )}
    //               class_name={
    //                 g_props.data.available && get_outlet_available()
    //                   ? "prods-img "
    //                   : "prods-img-gray"
    //               }
    //               onIsVisible={() => {
    //                 
    //               }}
    //             />
    //           ) : (
    //             <div className="prods-img center radius"></div>
    //           )}
    //         </div>
    //       </div>
    //       {/* name, desc, price */}
    //       <div className="p-2 pt-1 pe-1">
    //         <p className="oneline text-dark">{g_props.data?.name}</p>
    //         <p className="oneline">{g_props.data?.description}</p>

    //         <div className="d-flex align-center pt-1">
    //           <p className="text-dark pr-1">
    //             {/* ₹{data?.price_without_gst} */}

    //             {g_props.data.hasOwnProperty("show_price_without_gst") ? (
    //               g_props.data.show_price_without_gst ? (
    //                 <>
    //                   {g_props.data.price_without_gst != "" &&
    //                   g_props.data.price_without_gst != null
    //                     ? "₹ " + g_props.data.price_without_gst
    //                     : null}
    //                 </>
    //               ) : (
    //                 <>
    //                   {g_props.data.price_with_gst != "" &&
    //                   g_props.data.price_with_gst != null
    //                     ? "₹ " + g_props.data.price_with_gst
    //                     : null}
    //                 </>
    //               )
    //             ) : null}
    //           </p>
    //           <small className="v-small text-gray text-end">
    //             <s>₹{g_props.data?.mrp}</s>
    //           </small>
    //           {/* ratting */}
    //           {/* <div className="ms-auto">
    //               <div className="d-flex align-items-center">
    //                 <p className="extra-small fw-500 mb-0 text-orange ">
    //                   3.5
    //                 </p>
    //                 <Ionicons
    //                   name="md-star"
    //                   size={9}
    //                   color="#ffa500"
    //                 />
    //                 <p className="extra-small fw-500 ps-1 mb-0 text-gray-200">
    //                   (785)
    //                 </p>
    //               </div>
    //             </div> */}
    //         </div>
    //       </div>
    //       {/* button */}
    //       <div className="pt-0 text-center">
    //         {get_outlet_available() ? (
    //           g_props.data.available ? (
    //             g_props.data.addbtn ? (
    //               <>
    //                 <div className="ecomoffer">
    //                   <div className="flex  center p-2">
    //                     {g_props.data.api_loading ? (
    //                       <Spinner />
    //                     ) : (
    //                       <>
    //                         {/* - */}
    //                         <div
    //                           className="cursor pr-1 w-25 center"
    //                           onClick={() => {
    //                             g_props.onminus();
    //                           }}
    //                         >
    //                           <img
    //                             src={
    //                               AppConfig.CDN_Media_url +
    //                               "b48a5c15d42fc79330a9658ff3779170.svg"
    //                             }
    //                             className="type-img"
    //                           />
    //                         </div>
    //                         {/* qty */}
    //                         <div className="w-50 center">
    //                           <p className="smooth">
    //                             <b>{g_props.data.addcount}</b>
    //                           </p>
    //                         </div>
    //                         {/* + */}
    //                         <div
    //                           className="cursor pl-1 w-25"
    //                           onClick={() => {
    //                             g_props.onpluse();
    //                           }}
    //                         >
    //                           <img
    //                             src={
    //                               AppConfig.CDN_Media_url +
    //                               "2a39073bf6a8f72e9d703ad4b9cdeab4.svg"
    //                             }
    //                             className="type-img"
    //                           />
    //                         </div>
    //                       </>
    //                     )}
    //                   </div>
    //                 </div>
    //               </>
    //             ) : (
    //               <div>
    //                 <div
    //                   className="ecomoffer"
    //                   onClick={(event) => {
    //                     
    //                     g_props.onadd();
    //                   }}
    //                 >
    //                   <div className="">
    //                     {g_props.data.api_loading ? (
    //                       <p className="center cursor p-2">
    //                         <Spinner />
    //                       </p>
    //                     ) : (
    //                       <p className="cursor p-2">
    //                         <b>Add</b>
    //                       </p>
    //                     )}
    //                   </div>
    //                 </div>
    //               </div>
    //             )
    //           ) : (
    //             <div
    //               className={
    //                 g_props.data.hasOwnProperty("images") &&
    //                 g_props.data.images.length > 0
    //                   ? "bottom-offer mb-3"
    //                   : "bottom-offer mb-3"
    //               }
    //             >
    //               <div className="offer mb-3">
    //                 {/* Unavailable */}
    //                 {g_props.data.next_available_at != "" &&
    //                 g_props.data.next_available_at != null ? (
    //                   <p className="cursor p-1 celias-font v-small text-gray">
    //                     Next Available at {g_props.data.next_available_at}
    //                   </p>
    //                 ) : (
    //                   <p className="cursor p-2 celias-font v-small text-gray">
    //                     Unavailable
    //                   </p>
    //                 )}
    //               </div>
    //             </div>
    //           )
    //         ) : null}

    //         {g_props.data.customize ? (
    //           <small className="text-black">
    //             <u className="underline">Customizable</u>
    //           </small>
    //         ) : null}
    //       </div>
    //     </div>
    //   </div>
    // ) : (
    //   <div
    //     onDragStart={handleDragStart}
    //     className="p-1 mt-3 prods"
    //     key={g_props.index}
    //   >
    //     <div className="border cursor">
    //       {/* top */}
    //       <div className="container-img">
    //         <div
    //           className="img-top-right cursor"
    //           onClick={() => {
    //             
    //             g_props.onfav();
    //           }}
    //         >
    //           {/* {product_data.fav_heart ? (
    //             <div className="wishlist-heart">
    //               <Ionicons
    //                 name="ios-heart"
    //                 size={24}
    //                 color="rgb(255, 0, 48)"
    //               />
    //             </div>
    //           ) : (
    //             <div className="wishlist-heart">
    //               <Ionicons name="heart-outline" size={24} color="gray" />
    //             </div>
    //           )} */}
    //         </div>
    //         <div
    //           className="px-1"
    //           onClick={() => {
    //             // SetQuick({ open: true, data: product_data });
    //             SetQuickView({
    //               open: true,
    //               value: g_props.data,
    //             });
    //           }}
    //         >
    //           {/* <img src={product_data.img_path} className="ecom-card-img" /> */}
    //           {g_props.data.hasOwnProperty("images") ? (
    //             <ImageContainer
    //               src={AppHelper.imageSrc(
    //                 context.app_data.image_base_url,
    //                 g_props.data.images[0].url,
    //                 {
    //                   format: "webp",
    //                   width: 320,
    //                 }
    //               )}
    //               thumb={AppHelper.imageSrc(
    //                 context.app_data.image_base_url,
    //                 g_props.data.images[0].url,
    //                 {
    //                   format: "webp",
    //                   width: 320,
    //                   quality: 10,
    //                 }
    //               )}
    //               class_name={
    //                 g_props.data.available && get_outlet_available()
    //                   ? "prods-img "
    //                   : "prods-img-gray"
    //               }
    //               onIsVisible={() => {
    //                 
    //               }}
    //             />
    //           ) : (
    //             <div className="prods-img center radius"></div>
    //           )}
    //         </div>
    //       </div>
    //       {/* details */}
    //       <div className="p-2">
    //         <p className="text-dark oneline">{g_props.data.name}</p>
    //         <div className="py-1">
    //           <p className="v-small oneline">{g_props.data.description}</p>
    //         </div>
    //         <div className="d-flex align-center">
    //           {/* <p className="text-dark pr-1">₹{g_props.data.offer_price}</p> */}
    //           <p className="text-dark pr-1">
    //             {g_props.data.hasOwnProperty("show_price_without_gst") ? (
    //               g_props.data.show_price_without_gst ? (
    //                 <>
    //                   {g_props.data.price_without_gst != "" &&
    //                   g_props.data.price_without_gst != null
    //                     ? "₹ " + g_props.data.price_without_gst
    //                     : null}
    //                 </>
    //               ) : (
    //                 <>
    //                   {g_props.data.price_with_gst != "" &&
    //                   g_props.data.price_with_gst != null
    //                     ? "₹ " + g_props.data.price_with_gst
    //                     : null}
    //                 </>
    //               )
    //             ) : null}
    //           </p>
    //           <p className="v-small text-gray text-end">
    //             <s>₹{g_props.data.mrp}</s>
    //           </p>
    //           <p className="pl-1">
    //             <b className="text-prime1">{g_props.data.discount} Off</b>
    //           </p>
    //         </div>
    //         {/* rating/ free delivery */}
    //         <div className="row align-items-center">
    //           {/* <div className="col-7 p-0">
    //             <small>Free delivery</small>
    //           </div> */}
    //           {/* <div className="col-5 p-0">
    //             <div className="end align-items-center">
    //               <div className="d-flex align-items-center">
    //                 <small className="mb-0 text-orange me-1">3.5</small>
    //                 <Ionicons name="md-star" size={9} color="gold" />
    //               </div>
    //               <small className="ps-1 mb-0 text-gray">(785)</small>
    //             </div>
    //           </div> */}
    //         </div>

    //         <div className="pt-2 text-center">
    //           {product_data.addbtn ? (
    //             <div>
    //               <div className="flex Groceoffer center p-2">
    //                 <div
    //                   className="cursor pr-1 w-25 center"
    //                   onClick={() => {
    //                     //   minus(index);
    //                   }}
    //                 >
    //                   <Entypo name="minus" size={18} color="#000" />
    //                 </div>
    //                 <div className="w-50 center">
    //                   <p className="smooth">
    //                     <b>{product_data.addcount}</b>
    //                   </p>
    //                 </div>
    //                 <div
    //                   className="cursor pl-1 w-25"
    //                   onClick={() => {
    //                     // minus(index);
    //                   }}
    //                 >
    //                   <Entypo name="plus" size={18} color="#000" />
    //                 </div>
    //               </div>
    //             </div>
    //           ) : (
    //             <div
    //               className="ecomoffer"
    //               onClick={(event) => {
    //                 if (product_data.variant.length > 0) {
    //                   if (width < 720) {
    //                     variantDrawer("bottom", true, event, product_data);
    //                   } else {
    //                     variantDrawer("right", true, event, product_data);
    //                   }
    //                 } else {
    //                   
    //                 }
    //               }}
    //             >
    //               <p className="cursor p-2">
    //                 <b className="text-prime1">Add</b>
    //               </p>
    //             </div>
    //           )}

    //           {product_data.variant != "" ? (
    //             <small className="text-black">
    //               <u className="underline">3+ more</u>
    //             </small>
    //           ) : null}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
  };

  const handleOnView = (type: any, v_index: any, isVisible: any) => {
    
    if (isVisible) {
      if (type == "available") {
        SetVendorData((prevalue: any) => {
          // prevalue[v_index].is_viewed = true;
          let selected_outlet_value = prevalue[v_index].outlets.find(
            (o_ele: any) => o_ele.checked == true
          );

          let send_data: any = {
            v_index: v_index,
            selected_outlet: selected_outlet_value,
            page_no: 1,
            search_data: "",
            specifications_data: specifications_data,
            from: "available",
          };
          if (url_params.get("q") != null) {
            SetSearchData(url_params.get("q"));
            send_data.search_data = url_params.get("q");
          }
          if (
            prevalue[v_index].is_viewed == false &&
            prevalue[v_index].is_called == 0 &&
            prevalue[v_index].outlet_product_is_loading
          ) {
         
            // prevalue[v_index].outlet_product_is_loading = true;
            prevalue[v_index].outlet_product = [];
            prevalue[v_index].have_products = false;
            prevalue[v_index].outlet_product_have_next_page = false;
            prevalue[v_index].is_called = 1;

            get_outlet_product(send_data);
          }
          return [...prevalue];
        });
      }
      if (type == "unavailable") {
        SetUnavailableVendorData((prevalue: any) => {
          let selected_outlet_value = prevalue[v_index].outlets.find(
            (o_ele: any) => o_ele.checked == true
          );

          let send_data: any = {
            v_index: v_index,
            selected_outlet: selected_outlet_value,
            page_no: 1,
            search_data: "",
            specifications_data: specifications_data,
            from: "unavailable",
          };

          if (url_params.get("q") != null) {
            SetSearchData(url_params.get("q"));
            send_data.search_data = url_params.get("q");
          }

          if (
            prevalue[v_index].is_viewed == false &&
            prevalue[v_index].is_called == 0 &&
            prevalue[v_index].outlet_product_is_loading
          ) {
         
            // prevalue[v_index].outlet_product_is_loading = true;
            prevalue[v_index].outlet_product = [];
            prevalue[v_index].have_products = false;
            prevalue[v_index].outlet_product_have_next_page = false;
            prevalue[v_index].is_called = 1;

            get_outlet_product(send_data);
          }
          return [...prevalue];
        });
      }
    }
  };

  return (
    <div className="page-main custom-container px-sm-0 px-md-2 bg-lightcolor">
      <div className=" pb-3 pt-0">
        {/* <div className="res-search">
            <input
                type="text"
                className="res-search-input"
                placeholder="Search for foods"
            />
            <div className="ms-auto">
                <img
                    src={require("../../../assets/icon/search.png")}
                    className=""
                />
            </div>
        </div> */}

        {!props?.from_category && (
          <>
            {/* filter / search input */}
            <div className="row py-3 px-md-1 px-sm-2 bg-fff fixed-search-bar ">
              <div className="input-icons form-group pe-0 search-div w-100 p-2">
                {/* Search */}
                <div className="cursor center pe-1">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "4075afa7aafecff54a5220213ad396f0.svg"
                    }
                    className="w-20px"
                  />
                </div>
                <form onSubmit={on_search_submit} className="ps-2 w-100">
                  <input
                    type="text"
                    className="form-control search-bar p-0"
                    placeholder="Search here"
                    autoFocus={true}
                    value={search_data}
                    onChange={(e) => {
                      SetSearchData(e.target.value);

                      if (e.target.value == "") {
                        SetUrlParams(
                          (prevalue: any) => {
                            if (url_params.get("filter") != null) {
                              prevalue["filter"] = url_params.get("filter");
                            }
                            // if (url_params.get("from") != null) {
                            //   prevalue["from"] = url_params.get("from");
                            // }
                            prevalue.delete("q");
                            return { ...prevalue };
                          },
                          { replace: true }
                        );
                      } else {
                        // const timeOutId = setTimeout(() => {
                        //   SetUrlParams((prevalue: any) => {
                        //     if (url_params.get("filter") != null) {
                        //       prevalue["filter"] = url_params.get("filter");
                        //     }
                        //     // if (url_params.get("from") != null) {
                        //     //   prevalue["from"] = url_params.get("from");
                        //     // }
                        //     prevalue["q"] = e.target.value;
                        //     return { ...prevalue };
                        //   });
                        // }, 500);
                        // return () => clearTimeout(timeOutId);
                      }

                      // SetUrlParams((prevalue: any) => {
                      //   if (url_params.get("filter") != null) {
                      //     prevalue["filter"] = url_params.get("filter");
                      //   }
                      //   // if (url_params.get("from") != null) {
                      //   //   prevalue["from"] = url_params.get("from");
                      //   // }
                      //   prevalue["q"] = e.target.value;
                      //   return { ...prevalue };
                      // });

                      // SetShowSearchResult(false)
                      // // SetUrlParams({ q: e.target.value });
                      // SetUrlParams((prevalue: any) => {
                      //   
                      //   return prevalue;
                      // });
                      // SetUrlParams((prev) => [
                      //   ...prev.entries(),
                      //   ["q", e.target.value],
                      // ]);
                    }}
                  />
                </form>
                {search_data != "" ? (
                  <div
                    className="cursor close-search center pe-2"
                    onClick={() => {
                      SetSearchData("");
                      SetUrlParams(
                        (prevalue: any) => {
                          if (url_params.get("filter") != null) {
                            prevalue["filter"] = url_params.get("filter");
                          }
                          // if (url_params.get("from") != null) {
                          //   prevalue["from"] = url_params.get("from");
                          // }
                          prevalue.delete("q");
                          return { ...prevalue };
                        },
                        { replace: true }
                      );
                      SetIsSearchLoading(true);
                    }}
                  >
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "35a4b68d7c79c198866ebaeb42c2e07e.svg"
                      }
                      className="w-20px"
                    />
                    {/* <Ionicons name="close-outline" size={22} color="#222" /> */}
                  </div>
                ) : null}

                {/* Filter */}
                <div
                  className="search-div-filter cursor border-left ps-2"
                  onClick={() => {
                    
                  

                    SetFilter({
                      open: true,
                      value: "",
                    });
                    SetSpecificationsData((prevalue) => {
                      prevalue.map((ele: any) => {
                        if (ele.d == "c") {
                          SetFilteredNodes(ele.options);
                          is_all_selected_check();
                        }
                      });
                      return [...prevalue];
                    });
                  }}
                >
                  <div className="">
                    {/* {check_have_filter() ? (
                      <Badge
                        color="error"
                        badgeContent={""}
                        className="cursor mr-3"
                      >
                        <Ionicons name="options-outline" size={20} color="#777" />
                      </Badge>
                    ) : ( */}
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "feec1a4b10f3b639621e5fcc34808c94.svg"
                      }
                      className="w-20px"
                    />
                    {/* <Ionicons name="options-outline" size={20} color="#222" /> */}
                    {is_filter_applied ? (
                      <span className="position-absolute mt-1 ms-1 translate-middle p-1 bg-green border-light rounded-circle">
                        {/* <span className="visually-hidden">
                          New alerts
                        </span> */}
                      </span>
                    ) : null}
                    {/* )} */}
                  </div>
                  {/* Filter */}
                </div>
              </div>
            </div>

            {/* Recently Searched */}
            {show_search_result && search_data == ""? (
              <div className=" px-12 py-3 shadow-bottom">
                <h6 className="mb-3 mt-5">Recently Searched</h6>
                {is_loading ? (
                  recent_search_skeleton()
                ) : (
                  <>
                    <div className="over-scroll">
                      {recent_search.length > 0 ? (
                        recent_search?.map((ele: any, index: any) => (
                          <label
                            className="resta-radio-button cursor pb-1"
                            onClick={() => {
                              SetSearchData(ele.keyword);
                              SetShowSearchResult(true);
                            }}
                            key={index}
                          >
                            {/* <input type="radio" name="brand1" className="radio" /> */}
                            <span>{ele.keyword}</span>
                          </label>
                        ))
                      ) : (
                        <div className="card p-3 text-center">
                          <p className=" celias-font">No Recent Search</p>
                        </div>
                      )}
                      {/* <label className="resta-radio-button cursor pb-2">
                      <input type="radio" name="brand1" className="radio" />
                      <span>Annapoorna</span>
                      </label>
                      <label className="resta-radio-button cursor pb-2">
                        <input type="radio" name="brand1" className="radio" />
                        <span>Kfc</span>
                      </label>
                      <label className="resta-radio-button cursor pb-2 ">
                        <input type="radio" name="brand1" className="radio" />
                        <span>Cheeze pizza</span>
                      </label>
                      <label className="resta-radio-button cursor  pb-2">
                        <input type="radio" name="brand1" className="radio" />
                        <span>chicken</span>
                      </label>
                      <label className="resta-radio-button cursor  pb-2">
                        <input type="radio" name="brand1" className="radio" />
                        <span>Cakes</span>
                      </label> */}
                    </div>
                    {/* <div className="mt-3">
                      <h6 className="mb-3">Cuisines</h6>
                      {specifications_data?.map((ele: any, ele_index: any) =>
                        ele.name == "Cuisines"
                          ? ele?.options?.map((opt_data: any, opt_index: any) => (
                              <label
                                className="resta-radio-button cursor pb-2"
                                key={opt_index}
                                onClick={() => {
                                  // SetSearchData(ele.keyword);
                                  
                                  SetShowSearchResult(true);
                                  SetIsSearchLoading(false);
                                  SetSpecificationsData((prevalue: any) => {
                                    prevalue[ele_index].options[opt_index].checked =
                                      !prevalue[ele_index].options[opt_index]
                                        .checked;

                                    let send_data = {
                                      search_data: "",
                                      specifications_data: prevalue,
                                    };
                                    get_vendors(send_data);
                                    return [...prevalue];
                                  });
                                }}
                              >
                                <span>{opt_data.name}</span>
                              </label>
                            ))
                          : null
                      )}
                    </div> */}
                  </>
                )}
              </div>
            ) : null}
          </>
        )}

        {/* Search result */}
        {/* {show_search_result ? ( */}

        {/* from search */}
        {search_data != "" ? (
          is_search_loading ? (
            vendor_skeleton()
          ) : (
            <div className="pb-5">
              <div className="mt-2">
                {vendor_data.length > 0 || unavailble_vendor_data.length > 0 ? (
                  <div className="">
                    {!props?.from_category && (
                      <div className="px-12">
                        {show_search_result ? (
                          <h5 className="py-3">Search Result</h5>
                        ) : context.app_data.selected_section.sector != "1" &&
                          context.app_data.selected_section.sector != "3" ? (
                          <h5 className="py-3">Near by Resturant</h5>
                        ) : (
                          <h5 className="py-3">Near by Shop</h5>
                        )}
                      </div>
                    )}

                    {vendor_data?.map((v_ele: any, v_index: any) => (
                      <VisibilitySensor
                        // partialVisibility
                        partialVisibility={"top"}
                        // offset={{top:10}}
                        minTopValue={1}
                        active={true}
                        onChange={(isVisible: any) => {
                          
                          if (isVisible) {
                            
                            handleOnView("available", v_index, isVisible);
                          }
                        }}
                      >
                        {context.app_data.selected_section.sector != "1" &&
                        context.app_data.selected_section.sector != "3" ? (
                          <VendorCardComponent
                            v_ele={v_ele}
                            type={"available"}
                            v_index={v_index}
                          />
                        ) : (
                          <div className="mb-2 p-2 box-2">
                            <div className="d-flex pt-2 mb-2">
                              <div>
                                {v_ele.hasOwnProperty("banner_image") ? (
                                  <img
                                    src={AppHelper.imageSrc(
                                      context.app_data.image_base_url,
                                      v_ele.banner_image,
                                      {
                                        format: "webp",
                                        width: 320,
                                      }
                                    )}
                                    // className="res-img"
                                    className={
                                      v_ele.selected_outlet.available
                                        ? "res-img"
                                        : "res-img-gray"
                                    }
                                  />
                                ) : (
                                  <img
                                    src={
                                      AppConfig.CDN_Image_url +
                                      "11d7bcaa7cc795223d03cba91e57d936.jpg"
                                    }
                                    className={
                                      v_ele.selected_outlet.available
                                        ? "res-img"
                                        : "res-img-gray"
                                    }
                                  />
                                )}
                              </div>
                              <div className="ps-2">
                                <h6 className="mb-1">{v_ele.brand_name}</h6>
                                <p className="mt-1 celias-font">
                                  {v_ele.selected_outlet.name} /{" "}
                                  {v_ele.selected_outlet.distance_kms} KM /{" "}
                                  {Math.floor(
                                    v_ele.selected_outlet.etd_seconds / 60
                                  )}{" "}
                                  mins
                                </p>
                              </div>
                              <div className="ms-auto cursor ">
                                <Link to={get_to_path1(v_ele)}>
                                  <div
                                    className="px-2"
                                    onClick={() => {
                                      let send_data = {
                                        from: "open_click",
                                        keyword: search_data,
                                        brand_id: v_ele.brand_id,
                                        outlet_id:
                                          v_ele.selected_outlet.outlet_id,
                                      };
                                      set_searched(send_data);
                                      context.SetAppData((prevValue) => {
                                        prevValue.selected_vendor = v_ele;
                                        prevValue.selected_vendor_outlet =
                                          v_ele.selected_outlet;
                                        return { ...prevValue };
                                      });
                                    }}
                                  >
                                    {/* open */}
                                    <img
                                      src={
                                        AppConfig.CDN_Media_url +
                                        "74db726f2280f86ee4dc651af07e2c3c.svg"
                                      }
                                      className="w-20px"
                                    />
                                    {/* <SimpleLineIcons
                                      name="arrow-right-circle"
                                      size={20}
                                      color="#333"
                                    /> */}
                                  </div>
                                </Link>
                              </div>
                            </div>

                            {v_ele.outlet_product_is_loading ? (
                              <div className="restaurant-list search-alice row px-0">
                                <AliceCarousel
                                  mouseTracking
                                  // touchMoveDefaultEvents={true}
                                  paddingRight={
                                    context.app_data.selected_section.sector !=
                                      "1" &&
                                    context.app_data.selected_section.sector !=
                                      "3"
                                      ? 30
                                      : 20
                                  }
                                  disableDotsControls
                                  responsive={
                                    context.app_data.selected_section.sector !=
                                      "1" &&
                                    context.app_data.selected_section.sector !=
                                      "3"
                                      ? foodresponsive
                                      : productresponsive
                                  }
                                  disableButtonsControls
                                  controlsStrategy="alternate"
                                  preservePosition={true}
                                >
                                  {[1, 2, 3, 4, 5, 6].map((data, index) => (
                                    <div
                                      className="restaurant-list__item mt-2"
                                      key={index}
                                      onDragStart={handleDragStart}
                                    >
                                      <div className="cursor">
                                        <Skeleton height={100} width="100%" />
                                      </div>
                                    </div>
                                  ))}
                                </AliceCarousel>
                              </div>
                            ) : (
                              v_ele?.outlet_product?.length > 0 && (
                                <div className="carousel-products-2">
                                  {v_ele?.outlet_product?.length > 2 ? (
                                    <div className="restaurant-list search-alice row px-0">
                                      <AliceCarousel
                                        mouseTracking
                                        // touchMoveDefaultEvents={true}
                                        paddingRight={
                                          context.app_data.selected_section
                                            .sector != "1" &&
                                          context.app_data.selected_section
                                            .sector != "3"
                                            ? 30
                                            : 20
                                        }
                                        disableDotsControls
                                        responsive={
                                          context.app_data.selected_section
                                            .sector != "1" &&
                                          context.app_data.selected_section
                                            .sector != "3"
                                            ? foodresponsive
                                            : productresponsive
                                        }
                                        disableButtonsControls
                                        controlsStrategy="alternate"
                                        preservePosition={true}
                                      >
                                        {v_ele?.outlet_product?.map(
                                          (data: any, index: any) => (
                                            <GroceryCardComponent
                                              for="grocery"
                                              index={index}
                                              fullWidth={true}
                                              data={data}
                                              view_type="slide"
                                              v_ele={v_ele}
                                              // onfav={() => {
                                              //   
                                              //   let send_data = {
                                              //     brand_id:
                                              //       context.app_data.selected_vendor
                                              //         .brand_id,
                                              //     outlet_id:
                                              //       context.app_data
                                              //         .selected_vendor_outlet.outlet_id,
                                              //     checked: 0,
                                              //   };
                                              //   if (data.type == "group") {
                                              //     send_data.product_id =
                                              //       data.product_id;
                                              //   }
                                              //   if (data.type == "product") {
                                              //     send_data.variant_id =
                                              //       data.variant_id;
                                              //   }
                                              //   if (!data.favourite) {
                                              //     send_data.checked = 1;
                                              //   }
                                              //   let change_data = {
                                              //     // menu_name: menu.name,
                                              //     // index: index,
                                              //   };
                                              //   addWishlist(
                                              //     send_data,
                                              //     "search",
                                              //     change_data
                                              //   );
                                              // }}
                                              // onadd={() => {
                                     

                                              //   if (data.type == "group") {
                                              //     if (data.customize) {
                                              //       // not competed for search produxt customize
                                              //       SetShowVariantData(
                                              //         (preValue: any) => {
                                              //           preValue.is_customize = false;
                                              //           preValue.product_index = index;
                                              //           preValue.product = data;
                                              //           preValue.is_open = true;
                                              //           preValue.collection_name =
                                              //             "search";
                                              //           preValue.from = "search";
                                              //           return {
                                              //             ...preValue,
                                              //           };
                                              //         }
                                              //       );
                                              //       SetIsVariantOpened(true);
                                              //       SetOutletContextData(
                                              //         (prevalue: any) => {
                                              //           prevalue.is_bottom_sheet_open =
                                              //             true;
                                              //           return {
                                              //             ...prevalue,
                                              //           };
                                              //         }
                                              //       );
                                              //     } else {
                                              //       SetSearchProductList(
                                              //         (prevalue: any) => {
                                              //           prevalue[index].api_loading =
                                              //             true;
                                              //           return [...prevalue];
                                              //         }
                                              //       );
                                              //       
                                              //       let send_data = {
                                              //         for: "group",
                                              //         product_id: data.id,
                                              //         variant_id: data.variant_id,
                                              //         from: "search",
                                              //         product_index: index,
                                              //       };
                                              //       add_product_cart(send_data);
                                              //     }
                                              //   } else {
                                              //     SetSearchProductList(
                                              //       (prevalue: any) => {
                                              //         prevalue[index].api_loading =
                                              //           true;
                                              //         return [...prevalue];
                                              //       }
                                              //     );
                                              //     
                                              //     let send_data = {
                                              //       for: "product",
                                              //       variant_id: data.id,
                                              //       from: "search",
                                              //       product_index: index,
                                              //     };
                                              //     add_product_cart(send_data);
                                              //   }
                                              // }}
                                              // onminus={() => {
                                              //   let filter_data = [];
                                              //   if (data.type == "group") {
                                              //     filter_data = cart_data.filter(
                                              //       (f_ele) =>
                                              //         f_ele.product_id == data.id
                                              //     );
                                              //   } else {
                                              //     filter_data = cart_data.filter(
                                              //       (f_ele) =>
                                              //         f_ele.variant_id == data.id
                                              //     );
                                              //   }

                                        

                                              //   if (filter_data.length > 1) {
                                              //     toast.dismiss(toast_id);
                                              //     toast_id = toast.error(
                                              //       "This item has multiple customizations added. Remove the correct item from the cart."
                                              //     );
                                              //   } else {
                                              //     SetSearchProductList(
                                              //       (prevalue: any) => {
                                              //         prevalue[index].api_loading =
                                              //           true;
                                              //         return [...prevalue];
                                              //       }
                                              //     );
                                              //     let qty_value =
                                              //       parseInt(
                                              //         filter_data[
                                              //           filter_data.length - 1
                                              //         ].quantity
                                              //       ) - 1;
                                              //     if (qty_value > 0) {
                                              //       let send_data = {
                                              //         cart_product_id:
                                              //           filter_data[
                                              //             filter_data.length - 1
                                              //           ].cart_id,
                                              //         quantity: qty_value,
                                              //         collection_name: "search",
                                              //         product_index: index,
                                              //         type: "remove",
                                              //       };
                                              //       update_cart_quantity(send_data);
                                              //     } else {
                                              //       let send_data = {
                                              //         cart_product_id:
                                              //           filter_data[
                                              //             filter_data.length - 1
                                              //           ].cart_id,
                                              //         quantity: qty_value,
                                              //         collection_name: "search",
                                              //         product_index: index,
                                              //         type: "remove",
                                              //       };
                                              //       remove_cart(send_data);
                                              //     }
                                              //   }
                                              // }}
                                              // onpluse={() => {
                                              //   
                                              //   
                                              //   

                                              //   if (data.type == "group") {
                                              //     if (data.customize) {
                                              //       // not completed for search product on custamozation
                                              //       let filter_data = cart_data.filter(
                                              //         (f_ele) =>
                                              //           f_ele.product_id == data.id
                                              //       );
                                            
                                              //       let send_value = "";
                                              //       if (filter_data.length > 0) {
                                              //         if (
                                              //           filter_data[
                                              //             filter_data.length - 1
                                              //           ].hasOwnProperty("addons")
                                              //         ) {
                                              //           filter_data[
                                              //             filter_data.length - 1
                                              //           ]?.addons?.map((ele) => {
                                              //             send_value =
                                              //               send_value + ele.name + ",";
                                              //           });
                                              //         }
                                              //       }
                                              //       SetSameVariantsDialog(
                                              //         (preValue: any) => {
                                              //           preValue.is_open = true;
                                              //           preValue.value = send_value;
                                              //           preValue.cart_data =
                                              //             filter_data[
                                              //               filter_data.length - 1
                                              //             ];
                                              //           preValue.product_index = index;
                                              //           preValue.product_data = data;
                                              //           preValue.collection_name =
                                              //             "search";
                                              //           preValue.from = "search";
                                              //           return {
                                              //             ...preValue,
                                              //           };
                                              //         }
                                              //       );
                                              //     } else {
                                              //       SetSearchProductList(
                                              //         (prevalue: any) => {
                                              //           prevalue[index].api_loading =
                                              //             true;
                                              //           return [...prevalue];
                                              //         }
                                              //       );
                                              //       
                                              //       let filter_data = cart_data.filter(
                                              //         (f_ele) =>
                                              //           f_ele.product_id == data.id
                                              //       );
                                           
                                              //       let cart_data_value =
                                              //         filter_data[
                                              //           filter_data.length - 1
                                              //         ];
                                              //       let send_data = {
                                              //         cart_data: cart_data_value,
                                              //         quantity:
                                              //           parseInt(
                                              //             cart_data_value.quantity
                                              //           ) + parseInt(1),
                                              //       };
                                              //       update_product_qty(send_data);
                                              //     }
                                              //   } else {
                                              //     SetSearchProductList(
                                              //       (prevalue: any) => {
                                              //         prevalue[index].api_loading =
                                              //           true;
                                              //         return [...prevalue];
                                              //       }
                                              //     );
                                              //     
                                              //     let filter_data = cart_data.filter(
                                              //       (f_ele) =>
                                              //         f_ele.variant_id == data.id
                                              //     );
                                          
                                              //     let cart_data_value =
                                              //       filter_data[filter_data.length - 1];
                                              //     let send_data = {
                                              //       cart_data: cart_data_value,
                                              //       quantity:
                                              //         parseInt(
                                              //           cart_data_value.quantity
                                              //         ) + parseInt(1),
                                              //     };
                                              //     update_product_qty(send_data);
                                              //   }
                                              // }}
                                              context={context}
                                              get_outlet_available={get_outlet_available()}
                                              from="search_page"
                                              navigate={() => {
                                                
                                                let send_data = {
                                                  from: "add_click",
                                                  keyword: search_data,
                                                  brand_id: v_ele.brand_id,
                                                  outlet_id:
                                                    v_ele.selected_outlet
                                                      .outlet_id,
                                                  type: data.type,
                                                };
                                                if (data.type == "group") {
                                                  send_data.product_id =
                                                    data.product_id;
                                                }
                                                if (data.type != "group") {
                                                  send_data.variant_id =
                                                    data.variant_id;
                                                }
                                                context.SetAppData(
                                                  (prevValue) => {
                                                    prevValue.selected_vendor =
                                                      v_ele;
                                                    prevValue.selected_vendor_outlet =
                                                      v_ele.selected_outlet;
                                                    if (data.type == "group") {
                                                      prevValue.searched_prod_id =
                                                        data.product_id;
                                                    } else {
                                                      prevValue.searched_prod_id =
                                                        data.variant_id;
                                                    }

                                                    return { ...prevValue };
                                                  }
                                                );
                                                set_searched(send_data);
                                                navigate(
                                                  get_to_path(data, v_ele)
                                                );
                                              }}
                                              open_quick_view={() => {
                                                SetQuickView({
                                                  open: true,
                                                  value: data,
                                                });
                                              }}
                                            />
                                          )
                                        )}
                                      </AliceCarousel>
                                    </div>
                                  ) : (
                                    <div className="row">
                                      {v_ele?.outlet_product?.map(
                                        (data: any, index: any) => (
                                          <GroceryCardComponent
                                            for="grocery"
                                            index={index}
                                            data={data}
                                            view_type="slide"
                                            v_ele={v_ele}
                                            context={context}
                                            get_outlet_available={get_outlet_available()}
                                            from="search_page"
                                            navigate={() => {
                                              
                                              let send_data = {
                                                from: "add_click",
                                                keyword: search_data,
                                                brand_id: v_ele.brand_id,
                                                outlet_id:
                                                  v_ele.selected_outlet
                                                    .outlet_id,
                                                type: data.type,
                                              };
                                              if (data.type == "group") {
                                                send_data.product_id =
                                                  data.product_id;
                                              }
                                              if (data.type != "group") {
                                                send_data.variant_id =
                                                  data.variant_id;
                                              }
                                              context.SetAppData(
                                                (prevValue) => {
                                                  prevValue.selected_vendor =
                                                    v_ele;
                                                  prevValue.selected_vendor_outlet =
                                                    v_ele.selected_outlet;
                                                  if (data.type == "group") {
                                                    prevValue.searched_prod_id =
                                                      data.product_id;
                                                  } else {
                                                    prevValue.searched_prod_id =
                                                      data.variant_id;
                                                  }

                                                  return { ...prevValue };
                                                }
                                              );
                                              set_searched(send_data);
                                              navigate(
                                                get_to_path(data, v_ele)
                                              );
                                            }}
                                            open_quick_view={() => {
                                              SetQuickView({
                                                open: true,
                                                value: data,
                                              });
                                            }}
                                          />
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              )
                            )}

                            {/* {type == "unavailable" ? (
                              <div
                                className={
                                  v_ele?.outlet_product?.length > 0
                                    ? "unavailable-detail"
                                    : "unavailable-detail border-top mt-2"
                                }
                              >
                                <p className="v-small">{get_unavailable_details(v_ele)}</p>
                              </div>
                            ) : null} */}
                          </div>
                        )}
                      </VisibilitySensor>
                    ))}
                    {unavailble_vendor_data?.map((v_ele: any, v_index: any) => (
                      <VisibilitySensor
                        // partialVisibility
                        partialVisibility={"top"}
                        // offset={{top:10}}
                        minTopValue={1}
                        active={true}
                        onChange={(isVisible: any) => {
                          
                          if (isVisible) {
                            
                            handleOnView("unavailable", v_index, isVisible);
                          }
                        }}
                      >
                        {context.app_data.selected_section.sector != "1" &&
                        context.app_data.selected_section.sector != "3" ? (
                          <VendorCardComponent
                            v_ele={v_ele}
                            v_index={v_index}
                            type={"unavailable"}
                          />
                        ) : (
                          <div className="mb-2 p-2 box-2">
                            {/* header */}
                            <div className="d-flex pt-2 mb-2">
                              <div>
                                {v_ele.hasOwnProperty("banner_image") ? (
                                  <img
                                    src={AppHelper.imageSrc(
                                      context.app_data.image_base_url,
                                      v_ele.banner_image,
                                      {
                                        format: "webp",
                                        width: 320,
                                      }
                                    )}
                                    // className="res-img"
                                    className={
                                      v_ele.selected_outlet.available
                                        ? "res-img"
                                        : "res-img-gray"
                                    }
                                  />
                                ) : (
                                  <img
                                    src={
                                      AppConfig.CDN_Image_url +
                                      "11d7bcaa7cc795223d03cba91e57d936.jpg"
                                    }
                                    className={
                                      v_ele.selected_outlet.available
                                        ? "res-img"
                                        : "res-img-gray"
                                    }
                                  />
                                )}
                              </div>
                              <div className="ps-2">
                                <h6 className="mb-1">{v_ele.brand_name}</h6>
                                <p className="mt-1 celias-font">
                                  {v_ele.selected_outlet.name} /{" "}
                                  {v_ele.selected_outlet.distance_kms} KM /{" "}
                                  {Math.floor(
                                    v_ele.selected_outlet.etd_seconds / 60
                                  )}{" "}
                                  mins
                                </p>
                              </div>
                              <div className="ms-auto cursor ">
                                <Link to={get_to_path1(v_ele)}>
                                  <div
                                    className="px-2"
                                    onClick={() => {
                                      let send_data = {
                                        from: "open_click",
                                        keyword: search_data,
                                        brand_id: v_ele.brand_id,
                                        outlet_id:
                                          v_ele.selected_outlet.outlet_id,
                                      };
                                      set_searched(send_data);
                                      context.SetAppData((prevValue) => {
                                        prevValue.selected_vendor = v_ele;
                                        prevValue.selected_vendor_outlet =
                                          v_ele.selected_outlet;
                                        return { ...prevValue };
                                      });
                                    }}
                                  >
                                    {/* open */}
                                    <img
                                      src={
                                        AppConfig.CDN_Media_url +
                                        "74db726f2280f86ee4dc651af07e2c3c.svg"
                                      }
                                      className="w-20px"
                                    />
                                    {/* <SimpleLineIcons
                                  name="arrow-right-circle"
                                  size={20}
                                  color="#333"
                                /> */}
                                  </div>
                                </Link>
                              </div>
                            </div>

                            {v_ele.outlet_product_is_loading ? (
                              <div className="restaurant-list search-alice row px-0">
                                <AliceCarousel
                                  mouseTracking
                                  // touchMoveDefaultEvents={true}
                                  paddingRight={
                                    context.app_data.selected_section.sector !=
                                      "1" &&
                                    context.app_data.selected_section.sector !=
                                      "3"
                                      ? 30
                                      : 20
                                  }
                                  disableDotsControls
                                  responsive={
                                    context.app_data.selected_section.sector !=
                                      "1" &&
                                    context.app_data.selected_section.sector !=
                                      "3"
                                      ? foodresponsive
                                      : productresponsive
                                  }
                                  disableButtonsControls
                                  controlsStrategy="alternate"
                                  preservePosition={true}
                                >
                                  {[1, 2, 3, 4, 5, 6].map((data, index) => (
                                    <div
                                      className="restaurant-list__item mt-2"
                                      key={index}
                                      onDragStart={handleDragStart}
                                    >
                                      <div className="cursor">
                                        <Skeleton height={100} width="100%" />
                                      </div>
                                    </div>
                                  ))}
                                </AliceCarousel>
                              </div>
                            ) : (
                              v_ele?.outlet_product?.length > 0 && (
                                <div className="carousel-products-2">
                                  {v_ele?.outlet_product?.length > 2 ? (
                                    <div className="restaurant-list search-alice row px-0">
                                      <AliceCarousel
                                        mouseTracking
                                        // touchMoveDefaultEvents={true}
                                        paddingRight={
                                          context.app_data.selected_section
                                            .sector != "1" &&
                                          context.app_data.selected_section
                                            .sector != "3"
                                            ? 30
                                            : 20
                                        }
                                        disableDotsControls
                                        responsive={
                                          context.app_data.selected_section
                                            .sector != "1" &&
                                          context.app_data.selected_section
                                            .sector != "3"
                                            ? foodresponsive
                                            : productresponsive
                                        }
                                        disableButtonsControls
                                        controlsStrategy="alternate"
                                        preservePosition={true}
                                      >
                                        {v_ele?.outlet_product?.map(
                                          (data: any, index: any) => (
                                            <GroceryCardComponent
                                              for="grocery"
                                              index={index}
                                              fullWidth={true}
                                              data={data}
                                              view_type="slide"
                                              v_ele={v_ele}
                                              // onfav={() => {
                                              //   
                                              //   let send_data = {
                                              //     brand_id:
                                              //       context.app_data.selected_vendor
                                              //         .brand_id,
                                              //     outlet_id:
                                              //       context.app_data
                                              //         .selected_vendor_outlet.outlet_id,
                                              //     checked: 0,
                                              //   };
                                              //   if (data.type == "group") {
                                              //     send_data.product_id =
                                              //       data.product_id;
                                              //   }
                                              //   if (data.type == "product") {
                                              //     send_data.variant_id =
                                              //       data.variant_id;
                                              //   }
                                              //   if (!data.favourite) {
                                              //     send_data.checked = 1;
                                              //   }
                                              //   let change_data = {
                                              //     // menu_name: menu.name,
                                              //     // index: index,
                                              //   };
                                              //   addWishlist(
                                              //     send_data,
                                              //     "search",
                                              //     change_data
                                              //   );
                                              // }}
                                              // onadd={() => {
                             

                                              //   if (data.type == "group") {
                                              //     if (data.customize) {
                                              //       // not competed for search produxt customize
                                              //       SetShowVariantData(
                                              //         (preValue: any) => {
                                              //           preValue.is_customize = false;
                                              //           preValue.product_index = index;
                                              //           preValue.product = data;
                                              //           preValue.is_open = true;
                                              //           preValue.collection_name =
                                              //             "search";
                                              //           preValue.from = "search";
                                              //           return {
                                              //             ...preValue,
                                              //           };
                                              //         }
                                              //       );
                                              //       SetIsVariantOpened(true);
                                              //       SetOutletContextData(
                                              //         (prevalue: any) => {
                                              //           prevalue.is_bottom_sheet_open =
                                              //             true;
                                              //           return {
                                              //             ...prevalue,
                                              //           };
                                              //         }
                                              //       );
                                              //     } else {
                                              //       SetSearchProductList(
                                              //         (prevalue: any) => {
                                              //           prevalue[index].api_loading =
                                              //             true;
                                              //           return [...prevalue];
                                              //         }
                                              //       );
                                              //       
                                              //       let send_data = {
                                              //         for: "group",
                                              //         product_id: data.id,
                                              //         variant_id: data.variant_id,
                                              //         from: "search",
                                              //         product_index: index,
                                              //       };
                                              //       add_product_cart(send_data);
                                              //     }
                                              //   } else {
                                              //     SetSearchProductList(
                                              //       (prevalue: any) => {
                                              //         prevalue[index].api_loading =
                                              //           true;
                                              //         return [...prevalue];
                                              //       }
                                              //     );
                                              //     
                                              //     let send_data = {
                                              //       for: "product",
                                              //       variant_id: data.id,
                                              //       from: "search",
                                              //       product_index: index,
                                              //     };
                                              //     add_product_cart(send_data);
                                              //   }
                                              // }}
                                              // onminus={() => {
                                              //   let filter_data = [];
                                              //   if (data.type == "group") {
                                              //     filter_data = cart_data.filter(
                                              //       (f_ele) =>
                                              //         f_ele.product_id == data.id
                                              //     );
                                              //   } else {
                                              //     filter_data = cart_data.filter(
                                              //       (f_ele) =>
                                              //         f_ele.variant_id == data.id
                                              //     );
                                              //   }


                                              //   if (filter_data.length > 1) {
                                              //     toast.dismiss(toast_id);
                                              //     toast_id = toast.error(
                                              //       "This item has multiple customizations added. Remove the correct item from the cart."
                                              //     );
                                              //   } else {
                                              //     SetSearchProductList(
                                              //       (prevalue: any) => {
                                              //         prevalue[index].api_loading =
                                              //           true;
                                              //         return [...prevalue];
                                              //       }
                                              //     );
                                              //     let qty_value =
                                              //       parseInt(
                                              //         filter_data[
                                              //           filter_data.length - 1
                                              //         ].quantity
                                              //       ) - 1;
                                              //     if (qty_value > 0) {
                                              //       let send_data = {
                                              //         cart_product_id:
                                              //           filter_data[
                                              //             filter_data.length - 1
                                              //           ].cart_id,
                                              //         quantity: qty_value,
                                              //         collection_name: "search",
                                              //         product_index: index,
                                              //         type: "remove",
                                              //       };
                                              //       update_cart_quantity(send_data);
                                              //     } else {
                                              //       let send_data = {
                                              //         cart_product_id:
                                              //           filter_data[
                                              //             filter_data.length - 1
                                              //           ].cart_id,
                                              //         quantity: qty_value,
                                              //         collection_name: "search",
                                              //         product_index: index,
                                              //         type: "remove",
                                              //       };
                                              //       remove_cart(send_data);
                                              //     }
                                              //   }
                                              // }}
                                              // onpluse={() => {
                                              //   
                                              //   
                                              //   

                                              //   if (data.type == "group") {
                                              //     if (data.customize) {
                                              //       // not completed for search product on custamozation
                                              //       let filter_data = cart_data.filter(
                                              //         (f_ele) =>
                                              //           f_ele.product_id == data.id
                                              //       );
                                    
                                              //       let send_value = "";
                                              //       if (filter_data.length > 0) {
                                              //         if (
                                              //           filter_data[
                                              //             filter_data.length - 1
                                              //           ].hasOwnProperty("addons")
                                              //         ) {
                                              //           filter_data[
                                              //             filter_data.length - 1
                                              //           ]?.addons?.map((ele) => {
                                              //             send_value =
                                              //               send_value + ele.name + ",";
                                              //           });
                                              //         }
                                              //       }
                                              //       SetSameVariantsDialog(
                                              //         (preValue: any) => {
                                              //           preValue.is_open = true;
                                              //           preValue.value = send_value;
                                              //           preValue.cart_data =
                                              //             filter_data[
                                              //               filter_data.length - 1
                                              //             ];
                                              //           preValue.product_index = index;
                                              //           preValue.product_data = data;
                                              //           preValue.collection_name =
                                              //             "search";
                                              //           preValue.from = "search";
                                              //           return {
                                              //             ...preValue,
                                              //           };
                                              //         }
                                              //       );
                                              //     } else {
                                              //       SetSearchProductList(
                                              //         (prevalue: any) => {
                                              //           prevalue[index].api_loading =
                                              //             true;
                                              //           return [...prevalue];
                                              //         }
                                              //       );
                                              //       
                                              //       let filter_data = cart_data.filter(
                                              //         (f_ele) =>
                                              //           f_ele.product_id == data.id
                                              //       );
                                       
                                              //       let cart_data_value =
                                              //         filter_data[
                                              //           filter_data.length - 1
                                              //         ];
                                              //       let send_data = {
                                              //         cart_data: cart_data_value,
                                              //         quantity:
                                              //           parseInt(
                                              //             cart_data_value.quantity
                                              //           ) + parseInt(1),
                                              //       };
                                              //       update_product_qty(send_data);
                                              //     }
                                              //   } else {
                                              //     SetSearchProductList(
                                              //       (prevalue: any) => {
                                              //         prevalue[index].api_loading =
                                              //           true;
                                              //         return [...prevalue];
                                              //       }
                                              //     );
                                              //     
                                              //     let filter_data = cart_data.filter(
                                              //       (f_ele) =>
                                              //         f_ele.variant_id == data.id
                                              //     );
                                          
                                              //     let cart_data_value =
                                              //       filter_data[filter_data.length - 1];
                                              //     let send_data = {
                                              //       cart_data: cart_data_value,
                                              //       quantity:
                                              //         parseInt(
                                              //           cart_data_value.quantity
                                              //         ) + parseInt(1),
                                              //     };
                                              //     update_product_qty(send_data);
                                              //   }
                                              // }}
                                              context={context}
                                              get_outlet_available={get_outlet_available()}
                                              from="search_page"
                                              navigate={() => {
                                               
                                                let send_data = {
                                                  from: "add_click",
                                                  keyword: search_data,
                                                  brand_id: v_ele.brand_id,
                                                  outlet_id:
                                                    v_ele.selected_outlet
                                                      .outlet_id,
                                                  type: data.type,
                                                };
                                                if (data.type == "group") {
                                                  send_data.product_id =
                                                    data.product_id;
                                                }
                                                if (data.type != "group") {
                                                  send_data.variant_id =
                                                    data.variant_id;
                                                }
                                                context.SetAppData(
                                                  (prevValue) => {
                                                    prevValue.selected_vendor =
                                                      v_ele;
                                                    prevValue.selected_vendor_outlet =
                                                      v_ele.selected_outlet;
                                                    if (data.type == "group") {
                                                      prevValue.searched_prod_id =
                                                        data.product_id;
                                                    } else {
                                                      prevValue.searched_prod_id =
                                                        data.variant_id;
                                                    }

                                                    return { ...prevValue };
                                                  }
                                                );
                                                set_searched(send_data);
                                                navigate(
                                                  get_to_path(data, v_ele)
                                                );
                                              }}
                                              open_quick_view={() => {
                                                SetQuickView({
                                                  open: true,
                                                  value: data,
                                                });
                                              }}
                                            />
                                          )
                                        )}
                                      </AliceCarousel>
                                    </div>
                                  ) : (
                                    <div className="row">
                                      {v_ele?.outlet_product?.map(
                                        (data: any, index: any) => (
                                          <GroceryCardComponent
                                            for="grocery"
                                            index={index}
                                            data={data}
                                            view_type="slide"
                                            v_ele={v_ele}
                                            context={context}
                                            get_outlet_available={get_outlet_available()}
                                            from="search_page"
                                            navigate={() => {
                                             
                                              let send_data = {
                                                from: "add_click",
                                                keyword: search_data,
                                                brand_id: v_ele.brand_id,
                                                outlet_id:
                                                  v_ele.selected_outlet
                                                    .outlet_id,
                                                type: data.type,
                                              };
                                              if (data.type == "group") {
                                                send_data.product_id =
                                                  data.product_id;
                                              }
                                              if (data.type != "group") {
                                                send_data.variant_id =
                                                  data.variant_id;
                                              }
                                              context.SetAppData(
                                                (prevValue) => {
                                                  prevValue.selected_vendor =
                                                    v_ele;
                                                  prevValue.selected_vendor_outlet =
                                                    v_ele.selected_outlet;
                                                  if (data.type == "group") {
                                                    prevValue.searched_prod_id =
                                                      data.product_id;
                                                  } else {
                                                    prevValue.searched_prod_id =
                                                      data.variant_id;
                                                  }

                                                  return { ...prevValue };
                                                }
                                              );
                                              set_searched(send_data);
                                              navigate(
                                                get_to_path(data, v_ele)
                                              );
                                            }}
                                            open_quick_view={() => {
                                              SetQuickView({
                                                open: true,
                                                value: data,
                                              });
                                            }}
                                          />
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                            <div
                              className={
                                v_ele?.outlet_product?.length > 0
                                  ? "unavailable-detail p-2"
                                  : "unavailable-detail pt-2"
                              }
                            >
                              <p className="v-small">
                                {get_unavailable_details(v_ele)}
                              </p>
                            </div>
                          </div>
                        )}
                      </VisibilitySensor>
                    ))}
                  </div>
                ) : (
                  <div className="card p-3 mt-5 text-center">
                    <p>No Search result</p>
                  </div>
                )}
              </div>
            </div>
          )
        ) : null}

        {/* From Category */}
        {props.from_category ? (
          is_search_loading ? (
            vendor_skeleton()
          ) : (
            <div className="pb-5">
              <div className="mt-2">
                {vendor_data.length > 0 || unavailble_vendor_data.length > 0 ? (
                  <div className="">
                    {!props?.from_category && (
                      <div className="px-12">
                        {show_search_result ? (
                          <h5 className="py-3">Search Result</h5>
                        ) : context.app_data.selected_section.sector != "1" &&
                          context.app_data.selected_section.sector != "3" ? (
                          <h5 className="py-3">Near by Resturant</h5>
                        ) : (
                          <h5 className="py-3">Near by Shop</h5>
                        )}
                      </div>
                    )}

                    {vendor_data?.map((v_ele: any, v_index: any) => (
                      <VisibilitySensor
                        // partialVisibility
                        partialVisibility={"top"}
                        // offset={{top:10}}
                        minTopValue={1}
                        active={true}
                        onChange={(isVisible: any) => {
                          
                          if (isVisible) {
                            
                            handleOnView("available", v_index, isVisible);
                          }
                        }}
                      >
                        {context.app_data.selected_section.sector != "1" &&
                        context.app_data.selected_section.sector != "3" ? (
                          <VendorCardComponent
                            v_ele={v_ele}
                            type={"available"}
                            v_index={v_index}
                          />
                        ) : (
                          <div className="mb-2 p-2 box-2">
                            <div className="d-flex pt-2 mb-2">
                              <div>
                                {v_ele.hasOwnProperty("banner_image") ? (
                                  <img
                                    src={AppHelper.imageSrc(
                                      context.app_data.image_base_url,
                                      v_ele.banner_image,
                                      {
                                        format: "webp",
                                        width: 320,
                                      }
                                    )}
                                    // className="res-img"
                                    className={
                                      v_ele.selected_outlet.available
                                        ? "res-img"
                                        : "res-img-gray"
                                    }
                                  />
                                ) : (
                                  <img
                                    src={
                                      AppConfig.CDN_Image_url +
                                      "11d7bcaa7cc795223d03cba91e57d936.jpg"
                                    }
                                    className={
                                      v_ele.selected_outlet.available
                                        ? "res-img"
                                        : "res-img-gray"
                                    }
                                  />
                                )}
                              </div>
                              <div className="ps-2">
                                <h6 className="mb-1">{v_ele.brand_name}</h6>
                                <p className="mt-1 celias-font">
                                  {v_ele.selected_outlet.name} /{" "}
                                  {v_ele.selected_outlet.distance_kms} KM /{" "}
                                  {Math.floor(
                                    v_ele.selected_outlet.etd_seconds / 60
                                  )}{" "}
                                  mins
                                </p>
                              </div>
                              <div className="ms-auto cursor ">
                                <Link to={get_to_path1(v_ele)}>
                                  <div
                                    className="px-2"
                                    onClick={() => {
                                      let send_data = {
                                        from: "open_click",
                                        keyword: search_data,
                                        brand_id: v_ele.brand_id,
                                        outlet_id:
                                          v_ele.selected_outlet.outlet_id,
                                      };
                                      set_searched(send_data);
                                      context.SetAppData((prevValue) => {
                                        prevValue.selected_vendor = v_ele;
                                        prevValue.selected_vendor_outlet =
                                          v_ele.selected_outlet;
                                        return { ...prevValue };
                                      });
                                    }}
                                  >
                                    {/* open */}
                                    <img
                                      src={
                                        AppConfig.CDN_Media_url +
                                        "74db726f2280f86ee4dc651af07e2c3c.svg"
                                      }
                                      className="w-20px"
                                    />
                                  </div>
                                </Link>
                              </div>
                            </div>

                            {v_ele.outlet_product_is_loading ? (
                              <div className="restaurant-list search-alice row px-0">
                                <AliceCarousel
                                  mouseTracking
                                  // touchMoveDefaultEvents={true}
                                  paddingRight={
                                    context.app_data.selected_section.sector !=
                                      "1" &&
                                    context.app_data.selected_section.sector !=
                                      "3"
                                      ? 30
                                      : 20
                                  }
                                  disableDotsControls
                                  responsive={
                                    context.app_data.selected_section.sector !=
                                      "1" &&
                                    context.app_data.selected_section.sector !=
                                      "3"
                                      ? foodresponsive
                                      : productresponsive
                                  }
                                  disableButtonsControls
                                  controlsStrategy="alternate"
                                  preservePosition={true}
                                >
                                  {[1, 2, 3, 4, 5, 6].map((data, index) => (
                                    <div
                                      className="restaurant-list__item mt-2"
                                      key={index}
                                      onDragStart={handleDragStart}
                                    >
                                      <div className="cursor">
                                        <Skeleton height={100} width="100%" />
                                      </div>
                                    </div>
                                  ))}
                                </AliceCarousel>
                              </div>
                            ) : (
                              v_ele?.outlet_product?.length > 0 && (
                                <div className="carousel-products-2">
                                  {v_ele?.outlet_product?.length > 2 ? (
                                    <div className="restaurant-list search-alice row px-0">
                                      <AliceCarousel
                                        mouseTracking
                                        // touchMoveDefaultEvents={true}
                                        paddingRight={
                                          context.app_data.selected_section
                                            .sector != "1" &&
                                          context.app_data.selected_section
                                            .sector != "3"
                                            ? 30
                                            : 20
                                        }
                                        disableDotsControls
                                        responsive={
                                          context.app_data.selected_section
                                            .sector != "1" &&
                                          context.app_data.selected_section
                                            .sector != "3"
                                            ? foodresponsive
                                            : productresponsive
                                        }
                                        disableButtonsControls
                                        controlsStrategy="alternate"
                                        preservePosition={true}
                                      >
                                        {v_ele?.outlet_product?.map(
                                          (data: any, index: any) => (
                                            <GroceryCardComponent
                                              for="grocery"
                                              index={index}
                                              fullWidth={true}
                                              data={data}
                                              view_type="slide"
                                              v_ele={v_ele}
                                              context={context}
                                              get_outlet_available={get_outlet_available()}
                                              from="search_page"
                                              navigate={() => {
                                                
                                                let send_data = {
                                                  from: "add_click",
                                                  keyword: search_data,
                                                  brand_id: v_ele.brand_id,
                                                  outlet_id:
                                                    v_ele.selected_outlet
                                                      .outlet_id,
                                                  type: data.type,
                                                };
                                                if (data.type == "group") {
                                                  send_data.product_id =
                                                    data.product_id;
                                                }
                                                if (data.type != "group") {
                                                  send_data.variant_id =
                                                    data.variant_id;
                                                }
                                                context.SetAppData(
                                                  (prevValue) => {
                                                    prevValue.selected_vendor =
                                                      v_ele;
                                                    prevValue.selected_vendor_outlet =
                                                      v_ele.selected_outlet;
                                                    if (data.type == "group") {
                                                      prevValue.searched_prod_id =
                                                        data.product_id;
                                                    } else {
                                                      prevValue.searched_prod_id =
                                                        data.variant_id;
                                                    }

                                                    return { ...prevValue };
                                                  }
                                                );
                                                set_searched(send_data);
                                                navigate(
                                                  get_to_path(data, v_ele)
                                                );
                                              }}
                                              open_quick_view={() => {
                                                SetQuickView({
                                                  open: true,
                                                  value: data,
                                                });
                                              }}
                                            />
                                          )
                                        )}
                                      </AliceCarousel>
                                    </div>
                                  ) : (
                                    <div className="row">
                                      {v_ele?.outlet_product?.map(
                                        (data: any, index: any) => (
                                          <GroceryCardComponent
                                            for="grocery"
                                            index={index}
                                            data={data}
                                            view_type="slide"
                                            v_ele={v_ele}
                                            context={context}
                                            get_outlet_available={get_outlet_available()}
                                            from="search_page"
                                            navigate={() => {
                                              
                                              let send_data = {
                                                from: "add_click",
                                                keyword: search_data,
                                                brand_id: v_ele.brand_id,
                                                outlet_id:
                                                  v_ele.selected_outlet
                                                    .outlet_id,
                                                type: data.type,
                                              };
                                              if (data.type == "group") {
                                                send_data.product_id =
                                                  data.product_id;
                                              }
                                              if (data.type != "group") {
                                                send_data.variant_id =
                                                  data.variant_id;
                                              }
                                              context.SetAppData(
                                                (prevValue) => {
                                                  prevValue.selected_vendor =
                                                    v_ele;
                                                  prevValue.selected_vendor_outlet =
                                                    v_ele.selected_outlet;
                                                  if (data.type == "group") {
                                                    prevValue.searched_prod_id =
                                                      data.product_id;
                                                  } else {
                                                    prevValue.searched_prod_id =
                                                      data.variant_id;
                                                  }

                                                  return { ...prevValue };
                                                }
                                              );
                                              set_searched(send_data);
                                              navigate(
                                                get_to_path(data, v_ele)
                                              );
                                            }}
                                            open_quick_view={() => {
                                              SetQuickView({
                                                open: true,
                                                value: data,
                                              });
                                            }}
                                          />
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </VisibilitySensor>
                    ))}
                    {unavailble_vendor_data?.map((v_ele: any, v_index: any) => (
                      <VisibilitySensor
                        // partialVisibility
                        partialVisibility={"top"}
                        // offset={{top:10}}
                        minTopValue={1}
                        active={true}
                        onChange={(isVisible: any) => {
                          
                          if (isVisible) {
                            
                            handleOnView("unavailable", v_index, isVisible);
                          }
                        }}
                      >
                        {context.app_data.selected_section.sector != "1" &&
                        context.app_data.selected_section.sector != "3" ? (
                          <VendorCardComponent
                            v_ele={v_ele}
                            v_index={v_index}
                            type={"unavailable"}
                          />
                        ) : (
                          <div className="mb-2 p-2 box-2">
                            {/* header */}
                            <div className="d-flex pt-2 mb-2">
                              <div>
                                {v_ele.hasOwnProperty("banner_image") ? (
                                  <img
                                    src={AppHelper.imageSrc(
                                      context.app_data.image_base_url,
                                      v_ele.banner_image,
                                      {
                                        format: "webp",
                                        width: 320,
                                      }
                                    )}
                                    // className="res-img"
                                    className={
                                      v_ele.selected_outlet.available
                                        ? "res-img"
                                        : "res-img-gray"
                                    }
                                  />
                                ) : (
                                  <img
                                    src={
                                      AppConfig.CDN_Image_url +
                                      "11d7bcaa7cc795223d03cba91e57d936.jpg"
                                    }
                                    className={
                                      v_ele.selected_outlet.available
                                        ? "res-img"
                                        : "res-img-gray"
                                    }
                                  />
                                )}
                              </div>
                              <div className="ps-2">
                                <h6 className="mb-1">{v_ele.brand_name}</h6>
                                <p className="mt-1 celias-font">
                                  {v_ele.selected_outlet.name} /{" "}
                                  {v_ele.selected_outlet.distance_kms} KM /{" "}
                                  {Math.floor(
                                    v_ele.selected_outlet.etd_seconds / 60
                                  )}{" "}
                                  mins
                                </p>
                              </div>
                              <div className="ms-auto cursor ">
                                <Link to={get_to_path1(v_ele)}>
                                  <div
                                    className="px-2"
                                    onClick={() => {
                                      let send_data = {
                                        from: "open_click",
                                        keyword: search_data,
                                        brand_id: v_ele.brand_id,
                                        outlet_id:
                                          v_ele.selected_outlet.outlet_id,
                                      };
                                      set_searched(send_data);
                                      context.SetAppData((prevValue) => {
                                        prevValue.selected_vendor = v_ele;
                                        prevValue.selected_vendor_outlet =
                                          v_ele.selected_outlet;
                                        return { ...prevValue };
                                      });
                                    }}
                                  >
                                    {/* open */}
                                    <img
                                      src={
                                        AppConfig.CDN_Media_url +
                                        "74db726f2280f86ee4dc651af07e2c3c.svg"
                                      }
                                      className="w-20px"
                                    />
                                  </div>
                                </Link>
                              </div>
                            </div>

                            {v_ele.outlet_product_is_loading ? (
                              <div className="restaurant-list search-alice row px-0">
                                <AliceCarousel
                                  mouseTracking
                                  // touchMoveDefaultEvents={true}
                                  paddingRight={
                                    context.app_data.selected_section.sector !=
                                      "1" &&
                                    context.app_data.selected_section.sector !=
                                      "3"
                                      ? 30
                                      : 20
                                  }
                                  disableDotsControls
                                  responsive={
                                    context.app_data.selected_section.sector !=
                                      "1" &&
                                    context.app_data.selected_section.sector !=
                                      "3"
                                      ? foodresponsive
                                      : productresponsive
                                  }
                                  disableButtonsControls
                                  controlsStrategy="alternate"
                                  preservePosition={true}
                                >
                                  {[1, 2, 3, 4, 5, 6].map((data, index) => (
                                    <div
                                      className="restaurant-list__item mt-2"
                                      key={index}
                                      onDragStart={handleDragStart}
                                    >
                                      <div className="cursor">
                                        <Skeleton height={100} width="100%" />
                                      </div>
                                    </div>
                                  ))}
                                </AliceCarousel>
                              </div>
                            ) : (
                              v_ele?.outlet_product?.length > 0 && (
                                <div className="carousel-products-2">
                                  {v_ele?.outlet_product?.length > 2 ? (
                                    <div className="restaurant-list search-alice row px-0">
                                      <AliceCarousel
                                        mouseTracking
                                        // touchMoveDefaultEvents={true}
                                        paddingRight={
                                          context.app_data.selected_section
                                            .sector != "1" &&
                                          context.app_data.selected_section
                                            .sector != "3"
                                            ? 30
                                            : 20
                                        }
                                        disableDotsControls
                                        responsive={
                                          context.app_data.selected_section
                                            .sector != "1" &&
                                          context.app_data.selected_section
                                            .sector != "3"
                                            ? foodresponsive
                                            : productresponsive
                                        }
                                        disableButtonsControls
                                        controlsStrategy="alternate"
                                        preservePosition={true}
                                      >
                                        {v_ele?.outlet_product?.map(
                                          (data: any, index: any) => (
                                            <GroceryCardComponent
                                              for="grocery"
                                              index={index}
                                              fullWidth={true}
                                              data={data}
                                              view_type="slide"
                                              v_ele={v_ele}
                                              
                                              context={context}
                                              get_outlet_available={get_outlet_available()}
                                              from="search_page"
                                              navigate={() => {
                                                
                                                let send_data = {
                                                  from: "add_click",
                                                  keyword: search_data,
                                                  brand_id: v_ele.brand_id,
                                                  outlet_id:
                                                    v_ele.selected_outlet
                                                      .outlet_id,
                                                  type: data.type,
                                                };
                                                if (data.type == "group") {
                                                  send_data.product_id =
                                                    data.product_id;
                                                }
                                                if (data.type != "group") {
                                                  send_data.variant_id =
                                                    data.variant_id;
                                                }
                                                context.SetAppData(
                                                  (prevValue) => {
                                                    prevValue.selected_vendor =
                                                      v_ele;
                                                    prevValue.selected_vendor_outlet =
                                                      v_ele.selected_outlet;
                                                    if (data.type == "group") {
                                                      prevValue.searched_prod_id =
                                                        data.product_id;
                                                    } else {
                                                      prevValue.searched_prod_id =
                                                        data.variant_id;
                                                    }

                                                    return { ...prevValue };
                                                  }
                                                );
                                                set_searched(send_data);
                                                navigate(
                                                  get_to_path(data, v_ele)
                                                );
                                              }}
                                              open_quick_view={() => {
                                                SetQuickView({
                                                  open: true,
                                                  value: data,
                                                });
                                              }}
                                            />
                                          )
                                        )}
                                      </AliceCarousel>
                                    </div>
                                  ) : (
                                    <div className="row">
                                      {v_ele?.outlet_product?.map(
                                        (data: any, index: any) => (
                                          <GroceryCardComponent
                                            for="grocery"
                                            index={index}
                                            data={data}
                                            view_type="slide"
                                            v_ele={v_ele}
                                            context={context}
                                            get_outlet_available={get_outlet_available()}
                                            from="search_page"
                                            navigate={() => {
                                              
                                              let send_data = {
                                                from: "add_click",
                                                keyword: search_data,
                                                brand_id: v_ele.brand_id,
                                                outlet_id:
                                                  v_ele.selected_outlet
                                                    .outlet_id,
                                                type: data.type,
                                              };
                                              if (data.type == "group") {
                                                send_data.product_id =
                                                  data.product_id;
                                              }
                                              if (data.type != "group") {
                                                send_data.variant_id =
                                                  data.variant_id;
                                              }
                                              context.SetAppData(
                                                (prevValue) => {
                                                  prevValue.selected_vendor =
                                                    v_ele;
                                                  prevValue.selected_vendor_outlet =
                                                    v_ele.selected_outlet;
                                                  if (data.type == "group") {
                                                    prevValue.searched_prod_id =
                                                      data.product_id;
                                                  } else {
                                                    prevValue.searched_prod_id =
                                                      data.variant_id;
                                                  }

                                                  return { ...prevValue };
                                                }
                                              );
                                              set_searched(send_data);
                                              navigate(
                                                get_to_path(data, v_ele)
                                              );
                                            }}
                                            open_quick_view={() => {
                                              SetQuickView({
                                                open: true,
                                                value: data,
                                              });
                                            }}
                                          />
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                            <div
                              className={
                                v_ele?.outlet_product?.length > 0
                                  ? "unavailable-detail p-2"
                                  : "unavailable-detail pt-2"
                              }
                            >
                              <p className="v-small">
                                {get_unavailable_details(v_ele)}
                              </p>
                            </div>
                          </div>
                        )}
                      </VisibilitySensor>
                    ))}
                  </div>
                ) : (
                  <div className="card p-3 mt-5 text-center">
                    <p>No result</p>
                  </div>
                )}
              </div>
            </div>
          )
        ) : null}

        {/* ) : null} */}

        {/* Trending near you */}
        {/* <div className="mt-3">
          <h6>Trending near you</h6>

          <div className="restaurant-list search-alice row px-0">
            <AliceCarousel
              mouseTracking
              touchMoveDefaultEvents={false}
              paddingRight={20}
              disableDotsControls
              responsive={foodresponsive}
              disableButtonsControls
              controlsStrategy="alternate"
            >
              {restaurant.map((data, index) => (
                <div
                  className="restaurant-list__item mt-2"
                  key={index}
                  onDragStart={handleDragStart}
                >
                  <div className="restaurant-list__item_card cursor">
                    <Link to="/restaurant/restaurant_shop">
                      <div className="container">
                        <img className="restaurant-image" src={data.img} />
                        {data.offer != null ? (
                          <div className="top-right">
                            <p>
                              <MaterialCommunityIcons
                                name="brightness-percent"
                                size={15}
                                color="#00ac0b"
                              />
                              <b className="pl-02">{data.offer}% OFF</b>
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="img_over_content_foods">
                        <div>
                          <div className="restaurant-name">
                            <b>{data.name}</b>
                          </div>
                          <div className="restaurant-info flex align-center">
                            <MaterialIcons
                              name="star"
                              size={12}
                              color="white"
                            />
                            <p className="pl-02">{data.rate}</p>
                            <div className="pl-02 pb-1">
                              <Entypo
                                name="dot-single"
                                size={9}
                                color="white"
                              />
                            </div>
                            <p className="pl-02">{data.deliveryTime}</p>
                            {data.delivery != "" ? (
                              <div className="ms-auto">
                                <img
                                  src={require("../../../assets/icon/free-shipping.png")}
                                  className="free-shipping-icon"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </AliceCarousel>
          </div>
        </div> */}

        {/* Search Results */}
        {/* <div className="mt-3">
          <h6 className="mb-1 pt-2">Search Results</h6>

          <div className="row">
            {main.map((data, index) => (
              <div className="col-12 col-md-6 col-lg-4 px-2" key={index}>
                <Link to="/restaruant/restaurant_shop">
                  <div className="row pt-3 cursor">
                    <div className="col-3 px-0">
                      <img src={data.img} className="search-resta-img" />
                    </div>
                    <div className="col-9 pr-0 pt-1">
                      <h6 className="oneline">{data.name}</h6>
                      <p className="oneline">{data.type}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div> */}

        {/* Search Results Product */}
        {/* <div className="mt-3">
          <h6 className="mb-1 pt-2">Search Results</h6>
          {shop.menu.map((menu, menuIndex) => (
            <div className="row " key={menuIndex}>
              {menu.foodList.map((data, index) => (
                <div className="col-md-6 px-2" key={index}>
                  <div className="row align-center border-bottom pb-3 pt-3 w-100">
                    <div
                      className="col-8 p-0  cursor"
                      onClick={() => {
                        SetQuickView({ open: true, value: data });
                      }}
                    >
                      <div>
                        <div className="d-flex align-items-center w-100">
                          {data.type == "Veg" ? (
                            <img
                              src={require("../../../assets/icon/veg.png")}
                              className="type-img"
                            />
                          ) : (
                            <img
                              src={require("../../../assets/icon/nonveg.png")}
                              className="type-img"
                            />
                          )}
                          <div className="d-flex align-items-center ms-auto">
                            <FontAwesome
                              name="star"
                              size={13}
                              color="#00ac0b"
                            />
                            <p className="px-1">
                              <b className="text-green">Best Seller</b>
                            </p>
                          </div>
                        </div>
                        <h6 className="pt-1 oneline">{data.name}</h6>
                        <p className="marg">{data.description}</p>
                        <p className="pt-1 ">
                          <b className="text-dark">₹{data.price}</b>
                        </p>
                      </div>
                    </div>
                    <div className="col-4 p-0">
                      <div>
                        {data.img_path != "" ? (
                          <img src={data.img_path} className="near-img" />
                        ) : null}

                        {data.addbtn ? (
                          <div
                            className={
                              data.img_path !== "" ? "bottom-offer" : ""
                            }
                          >
                            <div className="flex offer center p-2">
                              <div className="cursor pr-1 w-25 center">
                                <Entypo
                                  name="minus"
                                  size={18}
                                  color="#00ac0b"
                                />
                              </div>
                              <div className="w-50 center">
                                <p className="smooth">
                                  <b>{data.addcount}</b>
                                </p>
                              </div>
                              <div className="cursor pl-1 w-25">
                                <Entypo name="plus" size={18} color="#00ac0b" />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {data.variants ? (
                              <div
                                className={
                                  data.img_path !== "" ? "bottom-offer" : ""
                                }
                                onClick={(event) => {
                                  if (width < 720) {
                                    toggleDrawer("bottom", true, event);
                                  } else {
                                    toggleDrawer("right", true, event);
                                  }
                                }}
                              >
                                <div className="offer">
                                  <p className="cursor p-2">
                                    <b>Add</b>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div
                                className={
                                  data.img_path !== "" ? "bottom-offer" : ""
                                }
                              >
                                <div className="offer">
                                  <p className="cursor p-2">
                                    <b>Add</b>
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div> */}
      </div>

      {/* {view_cart ? (
          <div className="w-100 bg-darkgreen md-none p-2 py-3">
              <div className="row">
                  <div className="col-6">
                      <h6 className="text-white mb-1">
                          <b>₹150</b>
                      </h6>
                      <p className="text-white">Total Price Amount</p>
                  </div>
                  <div className="col-6 align-center justify-end flex cursor">
                      <Link
                          to="/cart"
                          onClick={() => {
                              
                          }}
                      >
                          <div className="flex ">
                              <Feather name="shopping-cart" size={16} color="white" />
                              <p className="pl-1">
                                  <b className="text-white">View Cart</b>
                              </p>
                          </div>
                      </Link>
                  </div>
              </div>
          </div>
      ) : (
          ""
      )} */}

      <div>
        {["right", "bottom"].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* <Button onClick={toggleDrawer(anchor, true)}>
                {anchor}
            </Button> */}
            <Drawer
              className="shop"
              anchor={anchor}
              open={state[anchor]}
              // onClose={toggleDrawer(anchor, false)}
              // onOpen={toggleDrawer(anchor, true)}
              onClose={(event) => {
                toggleDrawer(anchor, false, event);
              }}
              onOpen={(event: any) => {
                toggleDrawer(anchor, true, event);
              }}
            >
              <div
                // className={
                //     (styles.list,
                //     {
                //         [styles.fullList]: anchor === "top" || anchor === "bottom",
                //     })
                // }
                role="presentation"
                // onClose={toggleDrawer(anchor, false)}
                // onOpen={toggleDrawer(anchor, true)}
              >
                <div className="p-0 mb-4">
                  <div className="p-2 ">
                    <div className="row">
                      <div className="col-11 p-0">
                        <h5>Pizza</h5>
                        <p className="marg mt-1">
                          pizza, dish of Italian origin consisting of a
                          flattened disk of bread dough topped with some
                          combination of olive oil, oregano, tomato, olives,
                          mozzarella or other cheese, and many other
                          ingredients.
                        </p>
                      </div>
                      <div
                        className="col-1 pr-0 text-right cursor"
                        onClick={(event) => {
                          toggleDrawer(anchor, false, event);
                        }}
                      >
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "b224728e6ca0021968c4481f66bdd226.svg"
                          }
                          className="w-20"
                        />
                        {/* <Ionicons name="close" size={20} color="gray" /> */}
                      </div>
                    </div>
                  </div>
                  <div className="p-3 mb-2 border-bottom">
                    <h6>
                      <b>Size</b>
                    </h6>
                    <p>
                      <small>Please Select One Option</small>
                    </p>
                    {variants_size?.map((size, sizeIndex) => (
                      <div className="mt-3" key={sizeIndex}>
                        <label
                          className="row pb-2 p-0 cursor"
                          onClick={() => {
                            SetSize(true);
                          }}
                        >
                          <div className="col-8">
                            <p>
                              <b>{size.item}</b>
                            </p>
                          </div>
                          <div className="col-4 text-right flex align-center justify-end pr-0">
                            <p className="text-dark">₹{size.price}</p>
                            <input type="radio" name="size" className="ml-2" />
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>

                  {size == true ? (
                    <div className="mb-5">
                      {addons?.map((data, index) => (
                        <div className="py-3 border-bottom" key={index}>
                          <h6>
                            <b>{data.title}</b>
                          </h6>
                          <p>
                            <small>Choose Maximum 2 options</small>
                          </p>
                          {data?.child?.map((childData, childIndex) => (
                            <div className="mt-2" key={childIndex}>
                              <div className="row p-0">
                                <div className="col-8">
                                  <p className="">
                                    {childData.name}
                                    <small className="text-dark pl-1">
                                      ₹{childData.price}
                                    </small>
                                  </p>
                                </div>
                                <div className="col-4 text-right pr-0">
                                  {childData.addbtn ? (
                                    <div className="flex offer p-1 center ">
                                      <div
                                        className="cursor pr-1 w-25 center"
                                        // onClick={() => {
                                        //     addonminus(index, childIndex);
                                        // }}
                                      >
                                        <img
                                          src={
                                            AppConfig.CDN_Media_url +
                                            "01097dff5e78b2876a5fb8cef71c306f.svg"
                                          }
                                          className="w-18px"
                                        />
                                        {/* <AntDesign
                                          name="minus"
                                          size={18}
                                          color="#00ac0b"
                                        /> */}
                                      </div>
                                      <div className="w-50 center">
                                        <p className="w-100">
                                          <b>{childData.addcount}</b>
                                        </p>
                                      </div>
                                      <div
                                        className="cursor pl-1 center w-25"
                                        // onClick={() => {
                                        //     addonadd(index, childIndex);
                                        // }}
                                      >
                                        <img
                                          src={
                                            AppConfig.CDN_Media_url +
                                            "839a7b08c1bd6f5371ac38c8ed611b69.svg"
                                          }
                                          className="w-18px"
                                        />
                                        {/* <AntDesign
                                          name="plus"
                                          size={14}
                                          color="#00ac0b"
                                        /> */}
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <div
                                        // type="button"
                                        className="offer p-2 text-center cursor"
                                        // onClick={() => {
                                        //     addoncart(index, childIndex);
                                        // }}
                                      >
                                        <p className="text-green">Add</p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="bottom-fixed w-50 p-0">
                    <div className="row center p-0">
                      <div className="col-6 p-2 pl-3">
                        <h6>₹300</h6>
                        <p>
                          <small>Total Product Price</small>
                        </p>
                      </div>
                      <div className="col-6 bg-green p-3 text-center">
                        <div className="cursor">
                          <Link to="/restaurant/restaurant_shop">
                            <p>
                              <b className="text-white">Add Item</b>
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>
          </React.Fragment>
        ))}
      </div>

      {/* Product Detail */}
      <Dialog
        open={quick_view.open}
        onClose={() => {
          SetQuickView({ open: false, value: "" });
        }}
        classes={{ container: classes.root, paper: classes.paper }}
      >
        <div className="bg-fff px-2 py-3 radius-top">
          <div className="center">
            <hr className="hr-drawer" />
          </div>
          <div className="p-2">
            <h6>{quick_view.value.name}</h6>
            <div className="row align-items-center">
              <div className="col-4">
                {/* <img src={"https://cdn.1superapp.com/images/320/" + quick_view.value.images[0].url} className="near-img" /> */}
              </div>
              <div className="col-8">
                <p>{quick_view.value.description}</p>
                <p className="pt-1">
                  <b className="text-dark">
                    ₹{quick_view.value.min_price} - ₹
                    {quick_view.value.max_price}
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* filter */}
      <div className="sort_filter w-100 center">
        <Modal
          open={filter.open}
          onClose={() => {
            SetFilter({ open: false, value: "" });
          }}
          // classes={{ container: classes.root, paper: classes.paper }}
        >
          <div className="md-w-50 filter_modal">
            {/* header */}
            <div className="sticky card p-3 shadow-bottom">
              <div className="row align-center">
                <div className="col-6 p-0">
                  <h6 className="mb-0">Filters</h6>
                </div>
                <div
                  className="col-6 text-end cursor"
                  onClick={() => {
                    clear_all_filter();
                  }}
                >
                  <p className="text-green mb-0 celias-font fw-bold capitalize">
                    Clear all
                  </p>
                </div>
              </div>
            </div>
            {/* body */}
            <div className="p-0">
              <div>
                <div className="row">
                  <>
                    {/* right side */}
                    <div className="col-4 bg-halfwhite h100 p-0">
                      {specifications_data.map(
                        (spec_ele: any, spec_index: any) =>
                          !spec_ele.hasOwnProperty("id") ||
                          spec_ele.id == "115" ? (
                            spec_ele.d != "p" ? (
                              <div
                                className="border-bottom"
                                key={spec_index}
                                onClick={() => {
                                  
                                  SetSpecificationsData((prevalue) => {
                                    prevalue.map((ele: any) => {
                                      if (ele.hasOwnProperty("slno")) {
                                        if (ele.slno == spec_ele.slno) {
                                          ele.open = true;
                                        } else {
                                          ele.open = false;
                                        }
                                      } else {
                                        if (ele.id == spec_ele.id) {
                                          ele.open = true;
                                        } else {
                                          ele.open = false;
                                        }
                                      }
                                    });
                                    return [...prevalue];
                                  });
                                  SetFilterSearch("");
                                }}
                              >
                                <div className="cursor">
                                  <p
                                    className={
                                      spec_ele.open
                                        ? "activeTitle  text-dark position-relative celias-font p-2 fs-7"
                                        : "title position-relative celias-font p-2 fs-7 text-gray"
                                    }
                                  >
                                    {spec_ele.name}
                                    {get_is_checked(spec_ele) ? (
                                      <span className="position-absolute m-2 translate-middle p-1 bg-green border-light rounded-circle">
                                        {/* <span className="visually-hidden">
                                        New alerts
                                      </span> */}
                                      </span>
                                    ) : null}
                                  </p>
                                </div>
                              </div>
                            ) : null
                          ) : null
                      )}
                    </div>

                    {/* left side */}
                    <div className="col-8 pt-2 bg-fff">
                      {specifications_data.map(
                        (spec_ele: any, spec_index: any) =>
                          spec_ele.open ? (
                            <div className="">
                              {/* <div className=" my-2">
                                <h6 className="mb-2">{spec_ele.name}</h6>
                              </div> */}
                              {spec_ele.d == "c" ? (
                                <div className="filter-div">
                                  <div className="py-3 d-flex align-items-center w-100">
                                    <input
                                      className="search-effect w-80"
                                      type="text"
                                      placeholder="Search"
                                      value={filter_search}
                                      onChange={(e) => {
                                        SetFilterSearch(e.target.value);
                                        if (!e.target.value) {
                                          SetFilteredNodes(spec_ele.options);
                                          is_all_selected_check();
                                          return;
                                        }
                                        
                                        SetFilteredNodes(
                                          spec_ele.options.reduce(
                                            filterNodes(e.target.value),
                                            []
                                          )
                                        );
                                        is_all_selected_check();
                                        
                                      }}
                                    />
                                    {/* <span className="w-20per search-close center">
                                      <Ionicons
                                        name="close-outline"
                                        size={20}
                                        color="#555"
                                      />
                                    </span> */}
                                    {filter_search != "" ? (
                                      // <div
                                      //   className="cursor ps-2 pe-2  center"
                                      //   onClick={() => {
                                      //     SetFilterSearch("");
                                      //   }}
                                      // >
                                      //   <Ionicons
                                      //     name="close"
                                      //     size={18}
                                      //     color="#777"
                                      //   />
                                      // </div>
                                      <span
                                        className="w-20per search-close center"
                                        onClick={() => {
                                          SetFilterSearch("");
                                          SetFilteredNodes(spec_ele.options);
                                          is_all_selected_check();
                                        }}
                                      >
                                        <img
                                          src={
                                            AppConfig.CDN_Media_url +
                                            "ef1835512e36a19e58e4c1515d4e51f6"
                                          }
                                          className="w-20px"
                                        />
                                        {/* <Ionicons
                                          name="close-outline"
                                          size={20}
                                          color="#555"
                                        /> */}
                                      </span>
                                    ) : null}
                                  </div>
                                  {/* select all */}
                                  <div className="all-select py-3 bg-fff">
                                    <div className="form-check d-flex align-items-center cursor mb-0">
                                      <label
                                        className="  form-check-label mb-0"
                                        id="selectAllFilter"
                                        value=""
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input p-0"
                                          id="selectAllFilter"
                                          checked={is_all_selected}
                                          onChange={(e) => {
                                           
                                            SetIsAllSelected(e.target.checked);
                                            if (e.target.checked) {
                                              // make_select_all(spec_ele.options);
                                              SetFilteredNodes(
                                                (prevalue: any) => {
                                                  make_select_all(prevalue);
                                                  return [...prevalue];
                                                }
                                              );
                                            } else {
                                              setTreeChecked([]);
                                            }
                                          }}
                                        />

                                        <span className="px-3 fs-7 capitalize text-dark celias-font">
                                          Select all
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="scrollY">
                                    <CheckboxTree
                                      // nodes={spec_ele.options}
                                      nodes={filtered_nodes}
                                      checked={tree_checked}
                                      expanded={tree_expanded}
                                      onCheck={(checked) => {
                                       
                                        setTreeChecked(checked);
                                      }}
                                      onExpand={(expanded) => {
                                       
                                        setTreeExpanded(expanded);
                                      }}
                                      showExpandAll={true}
                                      icons={{
                                        check: (
                                          // <i className="fas fa-check-square" />
                                          <span className="text-green">✓</span>
                                        ),
                                        uncheck: (
                                          <span className="text-light-gray">
                                            ✓
                                          </span>
                                          // <span className="fas fa-square" />
                                        ),
                                        halfCheck: (
                                          <span className="fas fa-minus-square" />
                                        ),
                                        expandClose: (
                                          <span className="fas fa-plus" />
                                        ),
                                        expandOpen: (
                                          <span className="fas fa-minus" />
                                        ),
                                        expandAll: null,
                                        collapseAll: null,
                                        parentClose: null,
                                        parentOpen: null,
                                        leaf: null,
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : null}
                              {spec_ele.d == "s" || spec_ele.d == "b" ? (
                                <div className="py-3">
                                  <div className="w-100">
                                    {/* <input
                                      type="text"
                                      className="form-control search-bar food_search p-0"
                                      value={filter_search}
                                      onChange={(e) => {
                                        SetFilterSearch(e.target.value);
                                       
                                      }}
                                    /> */}
                                    <div className="pb-2 filter-div ">
                                      <div className="d-flex align-items-center w-100">
                                        <input
                                          className="search-effect w-80"
                                          type="text"
                                          placeholder="Search"
                                          value={filter_search}
                                          onChange={(e) => {
                                            SetFilterSearch(e.target.value);
                                           
                                          }}
                                        />
                                        {/* <span className="w-20per search-close center">
                                          <Ionicons
                                            name="close-outline"
                                            size={20}
                                            color="#555"
                                          />
                                        </span> */}
                                        {filter_search != "" ? (
                                          <span
                                            className="w-20per search-close center"
                                            onClick={() => {
                                              SetFilterSearch("");
                                            }}
                                          >
                                            <img
                                              src={
                                                AppConfig.CDN_Media_url +
                                                "ef1835512e36a19e58e4c1515d4e51f6"
                                              }
                                              className="w-20px"
                                            />
                                            {/* <Ionicons
                                              name="close-outline"
                                              size={20}
                                              color="#555"
                                            /> */}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>

                                    {/* {filter_search != "" ? (
                                      <div
                                        className="cursor ps-2 pe-2  center"
                                        onClick={() => {
                                          SetFilterSearch("");
                                        }}
                                      >
                                        <Ionicons
                                          name="close"
                                          size={18}
                                          color="#777"
                                        />
                                      </div>
                                    ) : null} */}
                                  </div>
                                  <div className="mt-3 filter-common">
                                    {spec_ele.options
                                      .filter((value) => {
                                        const searchStr =
                                          filter_search.toLowerCase();
                                        const nameMatches = value.name
                                          .toLowerCase()
                                          .includes(searchStr);
                                        return nameMatches;
                                      })
                                      .map((opt_ele: any, opt_index: any) => (
                                        <div className="row">
                                          {spec_ele.type == "checkbox" ? (
                                            <>
                                              <label className="cursor">
                                                <input
                                                  type="checkbox"
                                                  aria-disabled
                                                  name={opt_ele.name}
                                                  className="mr-2"
                                                  checked={opt_ele.checked}
                                                  disabled={opt_ele.disabled}
                                                  autocomplete="off"
                                                  onChange={(e) => {
                                                   
                                                    SetSpecificationsData(
                                                      (prevalue) => {
                                                        prevalue[
                                                          spec_index
                                                        ].options[
                                                          opt_index
                                                        ].checked =
                                                          e.target.checked;
                                                        return [...prevalue];
                                                      }
                                                    );
                                                  }}
                                                />
                                                <span className="celias-font ps-3">
                                                  {opt_ele.name}
                                                </span>
                                              </label>
                                            </>
                                          ) : null}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              ) : null}
                              {spec_ele.d == "p" ? (
                                <div className="filter-price pt-3">
                                  {/* <input
                                    type="range"
                                    min="10"
                                    max="10000"
                                    onChange={(e) => {
                                      
                                      SetSpecificationsData((prevalue) => {
                                        prevalue[spec_index].value =
                                          e.target.value;
                                        return [...prevalue];
                                      });
                                    }}
                                  /> */}
                                  <div className="pb-4 px-2 w-100">
                                    <p className="v-small text-darkgray pb-1">
                                      Selected price range
                                    </p>
                                    <p className="fs-7 text-dark celias-font fw-bold">
                                      ₹{spec_ele.value[0]} - ₹
                                      {spec_ele.value[1]}
                                    </p>
                                  </div>
                                  <div className="center pb-1 pt-3">
                                    <Slider
                                      // style={{ width: 80% }}
                                      getAriaLabel={() => "Temperature range"}
                                      value={spec_ele.value}
                                      // value={[10,10000]}
                                      onChange={(e, value) => {
                                        
                                        
                                        SetSpecificationsData((prevalue) => {
                                          
                                          prevalue[spec_index].value = value;
                                          return [...prevalue];
                                        });
                                      }}
                                      min={0}
                                      step={50}
                                      max={10000}
                                      valueLabelDisplay="auto"
                                      // getAriaValueText={valuetext}
                                    />
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          ) : null
                      )}
                    </div>
                  </>
                </div>
              </div>
              {/* footer */}
              {/* <div className="bottom-fixed w-100 shadow px-2 py-3">
                <div className="row align-items-center w-100">
                  <div className="col-6">
                    <button className="btn text-black ">Reset all</button>
                  </div>
                  <div className="col-6 text-end">
                    <Link to={menu_type}>
                      <button className="btn btn-primary">
                        Show 4 results
                      </button>
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
            {/* footer */}
            <div className="bottom-fix row align-items-center w-100 shadow radius-none">
              {/* <div className="col-6">
                <button className="btn-outline-green fw-bold btn w-100">
                  Clear All
                </button>
              </div> */}
              <button
                className="col-6 btn btn-text bg-white cursor border-right py-3 text-gray"
                onClick={(event) => {
                  SetFilter({ open: false, value: "" });
                }}
              >
                Close
              </button>
              <button
                className="col-6 bg-white text-green fw-bold btn btn-text py-3 "
                onClick={() => {
                  SetFilter({ open: false, value: "" });
                  let send_data = {
                    search_data: search_data,
                    specifications_data: specifications_data,
                  };
                  SetShowSearchResult(true);
                  //
                  // SetSpecificationsData((prevalue: any) => {
                  //   SetUrlParams((prevalue1: any) => {
                  //     let sample_filter_value = [];
                  //     let filter_prevalue = prevalue;
                  //     filter_prevalue.map(
                  //       (fp_ele: any, fp_ele_index: any) => {
                  //         // if (fp_ele_index == ele_index) {
                  //         let push_value = {};
                  //         let value_data = [];
                  //         fp_ele.options.map((opt: any) => {
                  //           if (opt.checked) {
                  //             value_data.push(opt.id);
                  //           }
                  //         });
                  //         if (value_data.length > 0) {
                  //           push_value.id = fp_ele.id;
                  //           push_value.value = value_data;
                  //           sample_filter_value.push(push_value);
                  //         }
                  //         // }
                  //       }
                  //     );
                 
                  //     prevalue1["from"] = url_params.get("from");
                  //     if (sample_filter_value.length > 0) {
                  //       prevalue1["filter"] = encodeURI(
                  //         JSON.stringify(sample_filter_value)
                  //       );
                  //       send_data.sample_filter_value = sample_filter_value;
                  //     } else {
                  //       prevalue1.delete("filter");
                  //     }
                  //     get_vendors(send_data);

                  //     return { ...prevalue1 };
                  //   });
                  //   return [...prevalue];
                  // });
                  //

                  // new
                  SetSpecificationsData((prevalue: any) => {
                    SetUrlParams(
                      (prevalue1: any) => {
                        let sample_filter_value: any = [];
                        let filter_prevalue = prevalue;
                        
                        

                        filter_prevalue.map(
                          (fp_ele: any, fp_ele_index: any) => {
                            let push_value = {};
                            let value_data = [];
                            if (fp_ele.d == "c") {
                              // setChecked((c_pre)=>{
                              //   c_pre.map((c_ele: any) => {
                              //     value_data.push(c_ele);
                              //   });
                              //   if (value_data.length > 0) {
                              //     push_value.id = fp_ele.slno;
                              //     push_value.d = fp_ele.d;
                              //     push_value.value = value_data;
                              //     sample_filter_value.push(push_value);
                              //   }
                              //   return [...c_pre];
                              // })
                              if (tree_checked.length > 0) {
                                push_value.id = fp_ele.slno;
                                push_value.d = fp_ele.d;
                                push_value.value = tree_checked;
                                sample_filter_value.push(push_value);
                              }
                            }
                            if (fp_ele.d == "b" || fp_ele.d == "s") {
                              fp_ele.options.map((opt: any) => {
                                if (opt.checked) {
                                  value_data.push(opt.id);
                                }
                              });
                              if (value_data.length > 0) {
                                push_value.id = fp_ele.id;
                                push_value.d = fp_ele.d;
                                push_value.value = value_data;
                                sample_filter_value.push(push_value);
                              }
                            }
                            if (fp_ele.d == "p") {
                              push_value.id = fp_ele.slno;
                              push_value.d = fp_ele.d;
                              if (fp_ele.hasOwnProperty("value")) {
                                if (fp_ele.value[0] > 0) {
                                  push_value.min = fp_ele.value[0];
                                }
                                if (fp_ele.value[1] < 10000) {
                                  push_value.max = fp_ele.value[1];
                                }
                                if (
                                  push_value.hasOwnProperty("min") ||
                                  push_value.hasOwnProperty("max")
                                ) {
                                  sample_filter_value.push(push_value);
                                }
                              }
                            }
                          }
                        );
                        if (url_params.get("q") != null) {
                          prevalue1["q"] = url_params.get("q");
                        }

                        (
                          "sample_filter_value :",
                          sample_filter_value
                        );
                        // prevalue1["from"] = url_params.get("from");
                        if (sample_filter_value.length > 0) {
                          prevalue1["filter"] = encodeURI(
                            JSON.stringify(sample_filter_value)
                          );
                          send_data.sample_filter_value = sample_filter_value;
                          SetIsFilterApplied(true);
                        } else {
                          prevalue1.delete("filter");
                          SetIsFilterApplied(false);
                        }
                        get_vendors(send_data);
                        // get_unavailble_vendors(send_data);
                        return { ...prevalue1 };
                      },
                      { replace: true }
                    );
                    return [...prevalue];
                  });
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
