import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, Navigate, useNavigate } from "react-router-dom";
import DataContext from "../../../utils/Context/DataContext";
import { api } from "../../../utils/Api";

import Skeleton from "react-loading-skeleton";
import { AppConfig } from "../../../config";
import { makeStyles } from "@material-ui/core/styles";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import CouponViewModal from "./CouponViewModal";
import { Dialog } from "@material-ui/core";

export default function CouponModal(props: any) {
  const context = useContext(DataContext);
  var importedScript = document.createElement("script");

  let myModal: any;
  const [apply_coupon, SetApplyCoupon] = useState({
    is_open: false,
  });
  const [more_is_open, SetMoreIsOpen] = useState(false);

  useEffect(() => {
    let myModal1: any = document.getElementById("CouponModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      
      importedScript.remove();
      myModal = "";
      props.close();
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      myModal = new bootstrap.Modal(document.getElementById("CouponModal"), {
        backdrop: false,
      });
      myModal.show();
    }
  }, [props.open]);

  return (
    <div
      className="modal fade"
      id="CouponModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-fullscreen bg-body-color"
        data-backdrop="false"
      >
        <div className="modal-content">
          {/* header */}
          <div className="modal-header address-map-header px-2 border-none">
            {/* title */}
            <div className="px-2 cursor w-10per" data-bs-dismiss="modal">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "30549c56761b976b23418471a0f0037a.svg"
                }
                className="w-14px"
              />
            </div>
            <div className="ps-1 pe-0 w-80 text-center">
              <p className="loc-head">Enter Coupon Code</p>
            </div>
          </div>

          {/* modal body */}
          <div className="modal-body p-3 bg-body-color custom-container">
            <div className="map-top-div mt-3">
              <div className="d-flex mb-3">
                <div className="w-80 address-search-bar d-flex align-items-center py-0 pe-0 border-right-none">
                  <div className="ps-2 py-2">
                    <input
                      type="text"
                      className="let_space_03 custom-input-text"
                      placeholder="Enter coupon code"
                    />
                  </div>
                </div>
                <div className="w-20per center bg-primary pop-font border-radius-right text-white cursor fs-12">
                  Apply
                </div>
              </div>

              <div className="loc-add fs-12 mb-3 px-1">Available Coupon</div>

              <div className="card radius-10 shadow">
                <div className="w-100 d-flex">
                  <div className="w-16per h-auto bg-primary p-2 center border-left-radius10">
                    <div className="offerpercentage">23%OFF</div>
                  </div>
                  <div className="w-84per p-2">
                    <div className="d-flex align-items-center mb-1">
                      <div className="loc-head uppercase ">SUPERcoupon</div>
                      <div
                        className="ms-auto text-primary let_space_03 fs-12 pop-font fw-bold cursor"
                        onClick={() => {
                          SetApplyCoupon({ is_open: true });
                        }}
                      >
                        Apply
                      </div>
                    </div>
                    <div className="pop-font text-green fs-12px w-100 border-bottom-dot pb-2">
                      Save ₹130 on this order!
                    </div>

                    <div className="pop-font text-gray fs-11px pt-2 let_space_03">
                      Use code SUPERCOUPON & get 23% off on orders above ₹400.
                      Maximum discount ₹150.
                    </div>
                    <div
                      className="pop-font fs-12 text-black-color pt-2 cursor"
                      onClick={() => {
                        SetMoreIsOpen(!more_is_open);
                      }}
                    >
                      + More
                    </div>
                  </div>
                </div>
              </div>

              {more_is_open ? (
                <div className="">
                  <div className="loc-add fs-12 my-3 px-1">
                    Terms and Conditions apply
                  </div>
                  <ul>
                    <div className="d-flex celias-font fs-10px text-gray mb-2 let-space_03">
                      <div className="me-2">
                        <i
                          className="fs-7px fa fa-circle"
                          aria-hidden="true"
                        ></i>
                      </div>
                      Offer vaild only on Fridays
                    </div>
                    <div className="d-flex celias-font fs-11px text-gray mb-2 let-space_05">
                      <div className="me-2">
                        <i
                          className="fs-7px fa fa-circle"
                          aria-hidden="true"
                        ></i>
                      </div>
                      Applicable once per user per month
                    </div>
                    <div className="d-flex celias-font fs-11px text-gray mb-2 let-space_05">
                      <div className="me-2">
                        <i
                          className="fs-7px fa fa-circle"
                          aria-hidden="true"
                        ></i>
                      </div>
                      Vaild only Paytm Platinum Debit Cards
                    </div>
                    <div className="d-flex celias-font fs-11px text-gray mb-2 let-space_05">
                      <div className="me-2">
                        <i
                          className="fs-7px fa fa-circle"
                          aria-hidden="true"
                        ></i>
                      </div>
                      Not vaild on Paytm Rupay Platinum Debit Card
                    </div>
                    <div className="d-flex celias-font fs-11px text-gray mb-2 let-space_05">
                      <div className="me-2">
                        <i
                          className="fs-7px fa fa-circle"
                          aria-hidden="true"
                        ></i>
                      </div>
                      Other T&Cs may apply
                    </div>
                    <div className="d-flex celias-font fs-11px text-gray mb-2 let-space_05">
                      <div className="me-2">
                        <i
                          className="fs-7px fa fa-circle"
                          aria-hidden="true"
                        ></i>
                      </div>
                      Offer vaild till Aug 30,2024 11:59 PM
                    </div>
                  </ul>
                </div>
              ) : null}

            </div>
          </div>
        </div>
      </div>

      {/* {apply_coupon.is_open ? (
        <div className="p-3">
          <div className="card radius-20">
            <div className="address-text">
              <span className="uppercase">‘SuperCoupon’</span> applied
            </div>
          </div>
        </div>
      ) : null} */}

      {/* <CouponViewModal
        open={apply_coupon.is_open}
        close={(data: any) => {
          
          // props.close();
          SetApplyCoupon({
            is_open: false,
          });
        }}
      /> */}

      <Dialog
        open={apply_coupon.is_open}
        // TransitionComponent={Transition}
        // keepMounted
        onClose={() => {
          SetApplyCoupon({ is_open: false });
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="p-3 text-center">
          <img
            src={
              AppConfig.CDN_Media_url + "dc54c33d00c770f19a7cb0c8a07d8b79.svg"
            }
            className="w-50px"
          />
          <div className="address-sub-text text-dark mt-2">
            <span className="uppercase let_space_03">‘SuperCoupon’</span>{" "}
            applied
          </div>
          <div className="mt-3 mb-2 coupon-view-head">
            You saved ₹100.00 instantly + got ₹25.00 cashback
          </div>
          <div className="mt-1 address-sub-text fs-10px uppercase">
            with this coupon code
          </div>
          <div className="mt-4 text-primary fs-10px let_space_05 pop-font">
            Awesome, thank you!
          </div>
        </div>
      </Dialog>
    </div>
  );
}
